import { gql, MutationHookOptions, useMutation } from '@apollo/client';

type SubmitPaymentApprovalRequestInput = {
    memberId: string;
    amount: number;
    currency: string;
    programs: ApprovalProgram[];
    notes?: string;
    attachment: string;
};

export const SUBMIT_PAYMENT_APPROVAL_REQUEST = gql`
    mutation SubmitPayment($input: SubmitPaymentApprovalRequestInput!) {
        submitPaymentApprovalRequest(input: $input) {
            _id
        }
    }
`;

type TData = { submitPaymentApprovalRequest: unknown };
type TVariables = { input: SubmitPaymentApprovalRequestInput };

export const useSubmitPaymentApprovalRequest = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, { input: SubmitPaymentApprovalRequestInput }>(SUBMIT_PAYMENT_APPROVAL_REQUEST, options);
};
