/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import './theme/global.css';
/* Plugins */
import './plugins/amplify';
import './plugins/dayjs';

import { ApolloProvider } from '@apollo/client';
import { IonApp, IonPage, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthProvider, useAuthContext, UserProvider } from './features/Auth';
import { Dashboard, Home, Logout, Payment } from './pages';
import { client } from './services/apollo';
import Leagues from './pages/Leagues';
import AccountLogin from './pages/AccountLogin';
import OngoingCompetition from './pages/OngoingCompetition';
import AccountSignUp from './pages/AccountSignUp';
import Watch from './pages/Watch';
import Videos from './pages/Videos';
import Menu from './components/Menu';
import DoTraining from './pages/DoTraining';
import TrainingCatalogue from './pages/TrainingCatalogue';
import VideoDetail from './pages/Video';

const AuthorisedApp: React.FC = () => {
    const { isAnonymous } = useAuthContext();

    if (isAnonymous) {
        return (
            <IonApp>
                <Dashboard />
            </IonApp>
        );
    }

    return (
        <IonApp>
            <IonReactRouter>
                <Menu id="app" />
                <IonPage id="app">
                    <IonRouterOutlet>
                        <Route path="/" component={Home} exact />
                        <Route path="/watch" component={Watch} exact />
                        <Route path="/videos/:competitionId" component={Videos} />
                        <Route path="/video/:videoId" component={VideoDetail} />
                        <Route path="/training-catalogue" component={TrainingCatalogue} exact />
                        {/* <Route path="/tabs" component={MainTabs} /> */}
                        {/* <Route path="/setup" component={Setup} /> */}
                        <Route path="/payment" component={Payment} />
                        <Route path="/dashboard" component={Dashboard} />
                        <Route path="/do-training" component={DoTraining} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/leagues" component={Leagues} />
                        <Route path="/ongoing-competitions" component={OngoingCompetition} />
                        <Redirect to="/" />
                    </IonRouterOutlet>
                </IonPage>
            </IonReactRouter>
        </IonApp>
    );
};

const UnauthorisedApp = () => (
    <IonApp>
        <IonReactRouter>
            <Menu id="app" />
            <IonPage id="app">
                <IonRouterOutlet>
                    <Route path="/" component={Home} exact />
                    <Route path="/watch" component={Watch} exact />
                    <Route path="/videos/:competitionId" component={Videos} />
                    <Route path="/video/:videoId" component={VideoDetail} />
                    {/* <Route path="/signup" component={Signup} exact /> */}
                    {/* <Route path="/login" component={Login} exact /> */}
                    <Route path="/leagues" component={Leagues} />
                    <Route path="/ongoing-competitions" component={OngoingCompetition} />
                    <Route path="/login" component={AccountLogin} />
                    <Route path="/sign-up" component={AccountSignUp} />
                    <Redirect to="/" />
                </IonRouterOutlet>
            </IonPage>
        </IonReactRouter>
    </IonApp>
);

const App = () => (
    <ApolloProvider client={client}>
        <AuthProvider fallback={<UnauthorisedApp />}>
            <UserProvider>
                <AuthorisedApp />
            </UserProvider>
        </AuthProvider>
    </ApolloProvider>
);

export default App;
