/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonContent, IonIcon, IonPage, IonText } from '@ionic/react';
import { qrCodeOutline } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';

import Header from '../components/Header';
import SpaceHorizontal from '../components/SpaceHorizontal';
import Title from '../components/Title';
import { AccountGuard } from '../features/Auth';
import { MemberDetails, MemberOverview, MemberRelatedProfiles } from '../features/Member';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { useModal, MemberAttendanceModal, MemberQRCodeModal, MemberShareModal } from '../modals';

const Dashboard: React.FC = () => {
    const user = useCurrentUser();
    const history = useHistory();
    const { toggle } = useModal();
    return (
        <IonPage>
            <MemberAttendanceModal />
            <MemberShareModal />
            <MemberQRCodeModal />

            <Header />
            <IonContent>
                <SpaceHorizontal>
                    <Title title="Welcome Back">
                        <IconToolbar color="light" icon={qrCodeOutline} onClick={() => toggle('showMemberQRCode')} />
                    </Title>
                </SpaceHorizontal>

                <Container>
                    <MemberOverview />
                    <MemberDetails />
                    <AccountGuard>
                        <MemberRelatedProfiles data={user.profiles} />
                    </AccountGuard>
                    <div
                        css={css`
                            text-align: center;
                        `}
                    >
                        <Button onClick={() => history.push('/logout')}>
                            <IonText>Sign out</IonText>
                        </Button>
                    </div>
                </Container>
            </IonContent>
        </IonPage>
    );
};

export default Dashboard;

const Container = styled.div`
    height: calc(100% - var(--content-header-height));
    grid-template-rows: auto 1fr auto;
    min-width: 0;
`;

const IconToolbar = styled(IonIcon)`
    position: absolute;
    top: 5px;
    right: 5px;
    --background: var(--ion-background-color);
    transform: scale(1.5);
`;

const Button = styled.button`
    width: 135px;
    height: 39px;
    border: 1px solid var(--ion-header-background);
    border-radius: 4px;
    background: var(--ion-background-color);
    margin-bottom: 25px;
    margin-top: 15px;

    ion-text {
        color: var(--ion-header-background);
        font-size: 14px;
        font-weight: bold;
    }
`;
