/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonAvatar, IonButton, IonCard, IonCardContent, IonText, useIonToast } from '@ionic/react';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';

import { useAvatar } from '../../hooks/useAvatar';
import { AccountGuard } from '../Auth';
import { useModal } from '../../modals';

import useMember from './useMember';

const MemberOverview: React.FC = () => {
    const [present, dismiss] = useIonToast();
    const {
        profile: { loyaltyPoint },
    } = useMember();
    const { toggle } = useModal();
    const {
        profile: { name, picture, createdAt, tags, remainderAmount },
    } = useMember();

    const { src, alt } = useAvatar(picture);

    useEffect(() => {
        const { pathname } = window.location;
        if (!!remainderAmount && pathname === '/dashboard') {
            present({
                message: 'Missing payment! Please contact with administrator for your debt',
                color: 'primary',
                buttons: [{ text: 'Hide', role: 'cancel' }],
            });
        } else dismiss();
    }, [remainderAmount, window.location.pathname]);

    return (
        <Container>
            <IonCardContent
                css={css`
                    padding-bottom: 0;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <Avatar>
                        <img {...{ src, alt }} />
                    </Avatar>

                    <div
                        css={css`
                            margin-left: 15px;
                            margin-right: auto;
                            line-height: 1.6;
                        `}
                    >
                        <div
                            css={css`
                                font-size: 20px;
                                font-weight: 500;
                                color: var(--ion-title-color);
                                font-family: Montserrat-Bold;
                            `}
                        >
                            {name}
                        </div>

                        <div
                            css={css`
                                font-size: 12px;
                                display: grid;
                                grid-template-columns: auto 1fr;
                                grid-column-gap: 0.5rem;
                                align-items: center;
                                color: var(--ion-text-color);
                            `}
                        >
                            {tags.map((tag) => (
                                <IonText key={tag}>{tag}</IonText>
                            ))}
                            <IonText
                                css={css`
                                    border-left: 1px solid var(--ion-text-color);
                                    padding-left: 5px;
                                `}
                            >
                                Join in {dayjs(createdAt).year()}
                            </IonText>
                        </div>
                        <IonText
                            css={css`
                                color: var(--ion-yellow-color);
                                font-size: 12px;
                            `}
                        >
                            Loyalty Points:&nbsp;{loyaltyPoint || 0}
                        </IonText>
                    </div>
                    <div>
                        <AccountGuard>
                            <IonButton
                                css={css`
                                    font-size: 12px;
                                    padding: 0px 0;
                                    margin: 8px 0;
                                `}
                                routerLink="/training-catalogue"
                                expand="block"
                                color="tertiary"
                            >
                                Training
                            </IonButton>
                            <IonButton
                                css={css`
                                    font-size: 12px;
                                    padding: 0px 0;
                                    margin: 8px 0;
                                    --background: var(--ion-header-background);
                                `}
                                expand="block"
                                routerLink="/payment"
                            >
                                Buy More
                            </IonButton>
                        </AccountGuard>
                    </div>
                </div>
                <div
                    css={css`
                        text-align: center;
                        margin-top: 20px;
                        font-size: 12px;
                        font-family: Montserrat;
                        font-weight: bold;
                    `}
                >
                    <CustomButton onClick={() => toggle('showMemberAttendances')}>View attendance</CustomButton>
                </div>
            </IonCardContent>
        </Container>
    );
};

export default MemberOverview;

const Container = styled(IonCard)`
    color: var(--ion-text-color);
    margin: 0;
    height: fit-content;
`;
export const Avatar = styled(IonAvatar)`
    width: 80px;
    height: 80px;
    border: none !important;
`;
const CustomButton = styled.button`
    width: 145px;
    height: 35px;
    background: var(--ion-yellow-color) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: var(--ion-white-text-button);
`;
