import { IonContent, IonPage } from '@ionic/react';
import React from 'react';

import { Spacer } from '../components';
import Ads from '../components/Ads';
import Header from '../components/Header';
import Slider from '../components/Slider';
import LiveWatch from '../features/Watch/LiveWatch';
import OlderWatch from '../features/Watch/OlderWatch';

const Watch: React.FC = () => {
    return (
        <IonPage>
            <Header />

            <IonContent>
                <Spacer />
                <Slider />
                <LiveWatch />
                <OlderWatch />
                <Ads />
            </IonContent>
        </IonPage>
    );
};

export default Watch;
