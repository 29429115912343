/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';
import React from 'react';

import { useCalendar } from '../../hooks/useCalendar';
import { useMemberProfileRatings } from '../../hooks/useMemberProfileRatings';

import { useMember } from '.';

const Rating: React.FC<{ label: string; value?: React.ReactText }> = ({ label, value }) => {
    return (
        <div
            css={css`
                font-size: smaller;
                color: var(--ion-text-color);
            `}
        >
            <div
                css={css`
                    font-weight: 500;
                    font-size: 9px;
                    color: var(--ion-text-color);
                    text-transform: uppercase;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                `}
            >
                {label}
            </div>
            <div
                css={css`
                    font-weight: 600;
                    font-size: 32px;
                    color: var(--ion-title-color);
                    font-family: Montserrat-Bold;
                `}
            >
                {value || '-'}
            </div>
        </div>
    );
};

const MemberRatings: React.FC = () => {
    const {
        profile: { _id: id },
    } = useMember();
    const { date } = useCalendar();
    const { data, loading } = useMemberProfileRatings(id, date.year(), date.month());

    if (!data || loading) return <IonSpinner />;

    const { passing, shooting, dribbling, gameAwareness } = data.member.ratings || {};

    return (
        <Container>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <Rating label="Passing" value={passing} />
                    </IonCol>
                    <IonCol>
                        <Rating label="Shooting" value={shooting} />
                    </IonCol>
                    <IonCol>
                        <Rating label="Dribbling" value={dribbling} />
                    </IonCol>
                    <IonCol>
                        <Rating label="Game Awareness" value={gameAwareness} />
                    </IonCol>
                </IonRow>
            </IonGrid>
        </Container>
    );
};

export default MemberRatings;

const Container = styled.div`
    display: flex;
`;
