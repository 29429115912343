/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonList, IonListHeader, IonRippleEffect, IonText } from '@ionic/react';
import React from 'react';

import Title from '../../components/Title';
import { useAvatar } from '../../hooks/useAvatar';

import { Avatar } from './MemberOverview';
import useMember from './useMember';

const MemberItem: React.FC<{
    active?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    profile: MemberProfile;
}> = ({ active, onClick, profile: { name, picture } }) => {
    const { src, alt } = useAvatar(picture);

    return (
        <Item className="ion-activatable" {...{ active, onClick }}>
            <Avatar>
                <img {...{ src, alt }} />
            </Avatar>
            <IonText
                css={css`
                    color: var(--ion-title-color);
                    font-size: 14px;
                    margin-top: 5px;
                    font-family: Montserrat;
                `}
            >
                <span>{name}</span>
            </IonText>
            <IonRippleEffect />
        </Item>
    );
};

const MemberRelatedProfiles: React.FC<{ data: MemberProfile[] }> = ({ data }) => {
    const { profileId, setProfileId } = useMember();

    if (!data.length) return null;

    return (
        <IonList
            css={css`
                background: var(--ion-background-color);
            `}
        >
            <IonListHeader>
                <Title title="Players" />
            </IonListHeader>
            <List>
                {data.map((profile) => (
                    <MemberItem
                        key={profile._id}
                        active={profile._id === profileId}
                        onClick={() => setProfileId(profile._id)}
                        {...{ profile }}
                    />
                ))}
            </List>
        </IonList>
    );
};

export default MemberRelatedProfiles;

const List = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    overflow-x: auto;
`;

const Item = styled.div<{ active?: boolean }>`
    padding: 0.25rem;
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: 0.25rem;
    justify-items: center;

    ion-text {
        font-size: small;
        text-align: center;
    }

    ion-avatar {
        border: ${({ active }) => active && `2px solid var(--ion-color-primary)`};
    }
`;
