import styled from '@emotion/styled';
import { IonContent, IonPage } from '@ionic/react';
import { Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import * as Yup from 'yup';

import Step from '../components/Step';
import { SetupAccount, SetupProfiles, SetupSuccess } from '../features/Setup';
import { useSetupUserMutation } from '../hooks/useSetupUserMutation';

const Setup = () => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [setupUser] = useSetupUserMutation();

    return (
        <IonPage>
            <IonContent>
                <Container>
                    {currentStep !== 3 && (
                        <div style={{ textAlign: 'center' }}>
                            <h1>Registering Your Account</h1>
                            <p>Gaining access to our classes & events</p>
                        </div>
                    )}

                    <Formik
                        validationSchema={Schema}
                        initialValues={initialValues}
                        onSubmit={async (values) => {
                            await setupUser({ variables: { input: values } });
                            setCurrentStep((step) => step + 1);
                        }}
                    >
                        {({ submitForm, setFieldValue, isValid, isSubmitting }) => {
                            const switchSteps = () => {
                                switch (currentStep) {
                                    case 1:
                                        return (
                                            <Step i={1}>
                                                <SetupAccount
                                                    isBlocked={!isValid}
                                                    handleSubmit={() => {
                                                        setFieldValue('members', [initialMemberValues]);
                                                        setCurrentStep((step) => step + 1);
                                                    }}
                                                />
                                            </Step>
                                        );
                                    case 2:
                                        return (
                                            <Step i={2}>
                                                <SetupProfiles
                                                    isLoading={isSubmitting}
                                                    isBlocked={!isValid}
                                                    handleSubmit={() => submitForm()}
                                                />
                                            </Step>
                                        );
                                    case 3:
                                        return (
                                            <Step i={3}>
                                                <SetupSuccess />
                                            </Step>
                                        );
                                    default:
                                        return null;
                                }
                            };
                            return <AnimatePresence>{switchSteps()}</AnimatePresence>;
                        }}
                    </Formik>
                </Container>
            </IonContent>
        </IonPage>
    );
};

export default Setup;

export type FormValues = Yup.InferType<typeof Schema>;

export const initialValues = {
    firstName: '',
    lastName: '',
    // email: "",
    phoneNumber: '+',
    members: [],
};

export const initialMemberValues = {
    name: '',
    gender: '',
    birthdate: '',
    experience: '',
    jerseySize: '',
};

export const MemberFormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    gender: Yup.string().required('Required'),
    birthdate: Yup.string().required('Required'),
    experience: Yup.string().required('Required'),
    jerseySize: Yup.string().required('Required'),
});

export const Schema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    // email: Yup.string()
    //   .email("Invalid email")
    //   .required("Required"),
    phoneNumber: Yup.string()
        .matches(/^\d{3,15}/, 'Invalid phone number')
        .required('Required'),
    members: Yup.array().of(MemberFormSchema),
});

const Container = styled.div`
    position: relative;
`;
