import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useField } from 'formik';
import React from 'react';

type Props = {
    fieldName?: string;
};

export const JerseySizePicker: React.FC<Props> = ({ fieldName = 'jerseySize' }) => {
    const [{ value }, , { setValue }] = useField(fieldName);
    return (
        <IonItem>
            <IonLabel position="floating">Jersey Size</IonLabel>
            <IonSelect
                value={value}
                onIonChange={(e) => setValue(e.detail.value)}
                placeholder="Select"
                interfaceOptions={{
                    message: `The jersey is a compulsory uniform all trainees need to purchase`,
                }}
            >
                <IonSelectOption>XXS</IonSelectOption>
                <IonSelectOption>XS</IonSelectOption>
                <IonSelectOption>S</IonSelectOption>
                <IonSelectOption>M</IonSelectOption>
                <IonSelectOption>L</IonSelectOption>
                <IonSelectOption>XL</IonSelectOption>
                <IonSelectOption>XXL</IonSelectOption>
            </IonSelect>
        </IonItem>
    );
};

export default JerseySizePicker;
