import styled from '@emotion/styled';
import { IonButton, IonDatetime, IonInput, IonItem } from '@ionic/react';
import React from 'react';

export const FormGroup = styled.form`
    background-color: var(--ion-content-dark-2);
    border-radius: 8px;
    border: 1px solid var(--ion-border);
    padding: 1rem 1.5rem 2rem;
    color: var(--ion-white-text-button);
    width: 90vw;

    h5 {
        font-family: 'Montserrat-Bold';
        font-size: 16px;
    }
    small {
        color: var(--ion-text-color);
    }
    ion-col > ion-input {
        margin-top: 0;
        margin-bottom: 0;
    }
    ion-col {
        padding-top: 0;
        padding-bottom: 0;
    }
`;

export const FormInput = styled(IonInput)`
    border: 1px solid var(--ion-border-input);
    border-radius: 4px;
    font-size: 14px;
    margin: 1rem 0;
    --padding-start: 10px;
    input {
        color: red;
        --border-radius: 4px;
    }
`;

export const PageFooter = styled.div`
    font-size: 14px;
    display: flex;
    justify-content: center;
    color: var(--ion-text-muted-color-3);
    ion-text {
        margin-left: 5px;
        color: var(--ion-yellow-color);
        text-decoration: underline;
        font-family: 'Montserrat-Bold';
    }
`;

export const SubmitButton = styled(IonButton)`
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    text-transform: none;

    &::part(native) {
        --border-radius: 4px;
    }
`;

export const PhoneFormControl = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--ion-border-input);
    border-radius: 4px;
    margin: 1rem 0;

    p {
        margin: 0 10px;
    }
    ion-input {
        border: none;
        margin: 0;
    }
`;

export const RadioFormControl = styled(IonItem)`
    --border-color: transparent;
    --background: var(--ion-content-dark-2);
    --padding-start: 0;
    --padding-end: 0;
    overflow: visible;
    font-size: 14px;
    color: var(--ion-text-input-color);

    ion-radio {
        margin: 0 3px;
        width: 15px;
        height: 15px;
        min-width: 15px;
    }
`;

export const DatetimeFormControl = styled(IonDatetime)`
    margin-top: 0;
    margin-bottom: 0;
    border: 1px solid var(--ion-border-input);
    border-radius: 7px;
    font-size: 14px;
    font-family: var(--ion-font-family);
    --padding-top: 10px;
    --padding-bottom: 9px;
    --padding-start: 9px;
`;
