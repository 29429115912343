/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonButton, IonCol, IonRow, IonText } from '@ionic/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';

interface StatisticGameCardProps {
    isUpcoming: boolean;
    match: MatchResult;
}

const StatisticGameCard: React.FunctionComponent<StatisticGameCardProps> = (props) => {
    const { isUpcoming, match } = props;
    const [isShowDetail, setIsShowDetail] = useState<boolean>(false);

    const handleClickDetail = (url: string) => {
        window.open(`${process.env.REACT_APP_DOMAIN_PREFIX}/${url}`);
    };
    const handleClickHighlight = (url: string) => {
        window.open(url);
    };

    const getDateTime = (matchDate: string) => {
        return dayjs(matchDate).calendar(dayjs(), {
            sameDay: '[TODAY]',
            nextDay: '[TOMORROW]',
            nextWeek: 'YYYY-MM-DD',
            sameElse: 'YYYY-MM-DD',
        });
    };

    return (
        <GameContainer>
            {isUpcoming && (
                <UpcomingGameHeader>
                    <div>
                        <IonText>{match.match_time}</IonText>
                        <small>{getDateTime(match.match_date)}</small>
                    </div>
                    <span>Hot</span>
                </UpcomingGameHeader>
            )}
            <div
                css={css`
                    padding: 30px 20px 0 20px;
                    align-items: center;
                `}
            >
                {match?.match_result.home.flag ? (
                    <TeamLogo src={`${process.env.REACT_APP_DOMAIN_PREFIX}/${match?.match_result.home.flag}`} />
                ) : (
                    <div css={css(`width: 60px; height: 60px;`)} />
                )}
                <MatchResultBlock>
                    {isUpcoming ? 'VS' : `${match?.match_result.home.goal} : ${match?.match_result.away.goal}`}
                </MatchResultBlock>
                {match?.match_result.away.flag ? (
                    <TeamLogo src={`${process.env.REACT_APP_DOMAIN_PREFIX}/${match?.match_result.away.flag}`} />
                ) : (
                    <div css={css(`width: 60px; height: 60px;`)} />
                )}
            </div>
            <div
                css={css`
                    padding: 20px;
                    display: flex;
                    justify-content: space-between;
                    div {
                        max-width: 47%;
                    }
                `}
            >
                <div>
                    <IonText>
                        <b css={css(`font-size: 12px`)}>{match?.match_result.home.name}</b>
                    </IonText>
                </div>
                <div
                    css={css`
                        display: block !important;
                        text-align: right;
                    `}
                >
                    <IonText>
                        <b css={css(`font-size: 12px`)}>{match?.match_result.away.name}</b>
                    </IonText>
                </div>
            </div>
            {!isUpcoming && match.match_highlight_url && (
                <HighlightLink onClick={() => handleClickHighlight(match.match_highlight_url)}>
                    Watch Highlight
                </HighlightLink>
            )}
            {!isUpcoming && isShowDetail && (
                <StatisticsContainer>
                    <StatisticsCardCol>
                        <small>Time</small>
                        <h6>{`${match.match_time} ${match.match_date}`}</h6>

                        <PlayerName onClick={() => handleClickDetail(match.post_name)}>Detail Match</PlayerName>
                    </StatisticsCardCol>
                </StatisticsContainer>
            )}
            {!isUpcoming && (
                <StatisticsRow fill="clear" color="light" onClick={() => setIsShowDetail(!isShowDetail)}>
                    <IonText>Statistics</IonText>
                </StatisticsRow>
            )}
        </GameContainer>
    );
};

export default StatisticGameCard;

const GameContainer = styled.div`
    background: var(--ion-content-dark-2);
    margin: 20px 0;
    border-radius: 10px;
    border: 1px solid var(--ion-border);
    color: white;
    div {
        display: flex;
        justify-content: space-between;
    }
    small {
        margin-top: 3px;
        color: var(--ion-text-muted-color-2);
        font-size: 12px;
    }
`;

const UpcomingGameHeader = styled.div`
    padding: 30px 20px 0;
    div {
        display: flex;
        flex-direction: column;
    }
    ion-text {
        font-family: 'Montserrat-Bold';
        font-size: 30px;
        color: white;
    }
    span {
        border-radius: 10px;
        background-color: var(--ion-active-status-color);
        padding: 9px 15px;
        height: fit-content;
        align-items: center;
        text-transform: uppercase;
        font-size: 11px;
        font-family: 'Montserrat-Bold';
    }
`;

const StatisticsRow = styled(IonButton)`
    ion-text {
        color: var(--ion-yellow-color);
        text-transform: none;
        font-size: 14px;
        font-weight: bold;
    }
    height: 50px;
    margin: 0;
    border-top: 1px solid var(--table-row-hover);
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 5px solid var(--ion-yellow-color);
    background-color: var(--ion-content-dark-3);
`;

const StatisticsContainer = styled(IonRow)`
    border-top: 1px solid var(--table-row-hover);
    padding: 20px 30px;
`;

const StatisticsCardCol = styled(IonCol)`
    display: flex;
    flex-direction: column;
    align-items: center;
    ion-text {
        font-family: 'Montserrat-Bold';
        font-size: 40px;
        height: 80px;
        padding-top: 15px;
    }
`;

const PlayerName = styled.div`
    font-size: 12px;
    color: var(--ion-yellow-color);
    white-space: nowrap;
    cursor: pointer;
`;
const HighlightLink = styled.div`
    font-size: 12px;
    color: var(--ion-yellow-color);
    white-space: nowrap;
    margin-bottom: 15px;
    display: block !important;
    width: 100%;
    text-align: center;
`;

const TeamLogo = styled.img`
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 8px;
`;

const MatchResultBlock = styled(IonText)`
    border: 1px solid var(--ion-border-2);
    width: 70px;
    height: 50px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat-Bold';
`;
