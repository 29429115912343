/** @jsxImportSource @emotion/react */

import { IonCardContent, IonCardHeader, IonCardSubtitle } from '@ionic/react';
import { css } from '@emotion/react';
import React from 'react';

import { SelectMonth } from '../../components';
import { useCalendar } from '../../hooks/useCalendar';
import { useMemberProfileRatings } from '../../hooks/useMemberProfileRatings';

import { SlideItem } from './MemberPerformance';

import { useMember } from '.';

const MemberFeedbacks: React.FC = () => {
    const {
        profile: { _id: id },
    } = useMember();
    const { date } = useCalendar();
    const { data } = useMemberProfileRatings(id, date.year(), date.month());

    const feedback = data?.member.ratings?.feedback;

    return (
        <SlideItem>
            <IonCardHeader
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0.5rem 1rem;
                `}
            >
                <IonCardSubtitle
                    css={css`
                        color: var(--ion-yellow-color);
                        font-size: 16px;
                    `}
                >
                    Coach Feedback
                </IonCardSubtitle>
                <IonCardSubtitle
                    css={css`
                        background: var(--ion-sliver-background-color);
                        border-radius: 8px;
                        padding-right: 6px;
                    `}
                >
                    <SelectMonth />
                </IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
                {feedback ? (
                    <blockquote>&ldquo;{feedback}&rdquo;</blockquote>
                ) : (
                    <em>No coach feedback for the month.</em>
                )}
            </IonCardContent>
        </SlideItem>
    );
};

export default MemberFeedbacks;
