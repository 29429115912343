/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import React, { useEffect, useState } from 'react';

import CounterCard from './CounterCard';

interface UpcomingGameTimerProps {
    deadline: string | undefined;
}

const UpcomingGameTimer: React.FunctionComponent<UpcomingGameTimerProps> = ({ deadline }) => {
    let now = dayjs();
    const [counter, setCounter] = useState<Duration>(dayjs.duration(now.diff(now)));

    useEffect(() => {
        if (deadline) {
            const countdown = setInterval(() => {
                now = dayjs();
                const endTime = dayjs(deadline);
                const diff = endTime.diff(now);
                setCounter(dayjs.duration(diff));
                diff < 0 && clearInterval(countdown);
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [deadline]);

    const counterCardList = [
        {
            title: 'Days',
        },
        {
            title: 'Hours',
        },
        {
            title: 'Minutes',
        },
        {
            title: 'Seconds',
        },
    ];

    const displayTime = (type: string) => {
        let leftTime = 0,
            rightTime = 0;
        switch (type) {
            case 'Seconds':
                leftTime = Math.trunc(counter.seconds() / 10);
                rightTime = counter.seconds() % 10;
                break;
            case 'Minutes':
                leftTime = Math.trunc(counter.minutes() / 10);
                rightTime = counter.minutes() % 10;
                break;
            case 'Hours':
                leftTime = Math.trunc(counter.hours() / 10);
                rightTime = counter.hours() % 10;
                break;
            case 'Days':
                leftTime = Math.trunc(counter.days() / 10);
                rightTime = counter.days() % 10;
                break;
            default:
                break;
        }
        return (
            <div>
                <CounterCard time={leftTime} />
                <CounterCard time={rightTime} />
            </div>
        );
    };

    return (
        <Container>
            {counterCardList.map((card) => (
                <CounterContainer key={card.title}>
                    <span>{card.title}</span>
                    {displayTime(card.title)}
                </CounterContainer>
            ))}
        </Container>
    );
};

export default React.memo(UpcomingGameTimer);

const Container = styled.div`
    margin: 10px auto 30px;
    display: flex;
    justify-content: space-evenly;
    max-width: 320px;
`;

const CounterContainer = styled.div`
    width: 66px;
    text-align: center;
    span {
        font-size: 12px;
        padding-bottom: 10px;
        color: var(--ion-white-text-button);
    }
    div {
        justify-content: space-between;
        display: flex;
        margin-top: 3px;
    }
    div > div {
        justify-content: center;
    }
`;
