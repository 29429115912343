import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';

import defaultAvatar from '../assets/avatar.svg';

export const useAvatar = (imgKey?: string) => {
    const [src, setSrc] = useState(defaultAvatar);

    useEffect(() => {
        if (imgKey) Storage.get(imgKey).then((signedURL) => setSrc(signedURL as string));
    }, [imgKey]);

    if (!imgKey)
        return {
            src: defaultAvatar,
            alt: 'avatar',
        };

    return {
        src,
        alt: imgKey,
    };
};
