import { gql, QueryHookOptions, useQuery } from '@apollo/client';

interface CompetitionVideo extends Video {
    competitionId: string;
}

type TData = { video: CompetitionVideo };
type TVariables = { id: string };

export const useVideo = (options?: QueryHookOptions<TData, TVariables>) => {
    return useQuery<TData, TVariables>(VIDEO, options);
};

export const VIDEO = gql`
    query Video($id: String!) {
        video(id: $id) {
            _id
            userId {
                _id
                name
            }
            videoUrl
            liked
            countLiked
            competitionId
        }
    }
`;
