import styled from '@emotion/styled';
import { motion, Variants } from 'framer-motion';
import React from 'react';

const Step: React.FC<{ i: number }> = ({ children, i }) => (
    <Div
        key={i}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
            x: { type: 'spring', stiffness: 200, damping: 20 },
            opacity: { duration: 0.6 },
        }}
    >
        {children}
    </Div>
);

export default Step;

const variants: Variants = {
    enter: {
        x: 1000,
        opacity: 0,
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: {
        zIndex: 0,
        x: -1000,
        opacity: 0,
    },
};

const Div = styled(motion.div)`
    position: absolute;
    width: 100%;
    height: 100%;

    padding: 1rem;

    ion-button:first-of-type {
        margin-top: 2rem;
    }
`;
