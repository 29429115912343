import create from 'zustand';
import { persist } from 'zustand/middleware';

type CompetitionState = {
    description: string;
    videoDemo: string;
    setDescription: (description: string) => void;
    setVideoDemo: (video: string) => void;
};

export const useCompetitionStore = create<CompetitionState>(
    persist(
        (set) => ({
            description: '',
            videoDemo: '',
            setDescription: (description: string) => set({ description }),
            setVideoDemo: (videoDemo: string) => set({ videoDemo }),
        }),
        {
            name: 'competition-storage',
            getStorage: () => sessionStorage,
        },
    ),
);
