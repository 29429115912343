import Amplify from 'aws-amplify';

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_MEMBER_REGION,
        userPoolId: process.env.REACT_APP_MEMBER_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_MEMBER_USER_POOL_WEB_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_MEMBER_IDENTITY_POOL_ID,
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_MEMBER_S3_BUCKET,
            region: process.env.REACT_APP_MEMBER_REGION,
        },
    },
});
