import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';

export const useS3Object = (key?: string) => {
    const [signedURL, setSignedURL] = useState('');

    useEffect(() => {
        if (key) Storage.get(key).then((signedURL) => setSignedURL(signedURL as string));
    }, [key]);

    return signedURL;
};
