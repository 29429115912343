/** @jsxImportSource @emotion/react */

import React, { useEffect, useRef, useState } from 'react';
import RecordRTC from 'recordrtc';
import { IonButton, IonIcon, IonLoading, IonModal, IonToast } from '@ionic/react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { playCircleOutline, stopCircleOutline } from 'ionicons/icons';
import { Toast } from '@capacitor/core';
import { useHistory } from 'react-router';

import { useMember } from '../Member';
import { useUpdateCatalogue } from '../../hooks/useUpdateCatalogue';
import { useUploadFile } from '../../hooks/useUploadFileMutation';

import {
    Dribbling,
    getVoiceArrayByCatalogue,
    Goalie,
    HookDrill,
    MixedTraining,
    Passing,
    QuickLeftRightDrill,
} from './voices';
import PlayBackVideo from './PlayBackVideo';
import IntroCounterNumber from './IntroCounterNumber';
import TrainingImage from './TrainingImage';
interface TrainingModalProps {
    setShowModal: () => void;
    showModal: boolean;
    currentDifficulty: string;
    catalogueId: string;
    setUploaded: (uploaded: boolean) => void;
    catalogueName: string;
}

const TrainingModal: React.FunctionComponent<TrainingModalProps> = ({
    currentDifficulty,
    setShowModal,
    showModal,
    catalogueId,
    setUploaded,
    catalogueName,
}) => {
    let video: any;
    const [toast, setToast] = useState('');
    const [loading, setLoading] = useState(true);
    const [started, setStarted] = useState(false);
    const [record, setRecord] = useState<any>(undefined);
    const [introCounter, setIntroCounter] = useState(0);
    const [playBackVideoUrl, setPlayBackVideoUrl] = useState('');
    const cam = useRef<any>(null);
    const voiceArray = useRef<string[]>([]);
    const colorVoice = useRef(new Audio(''));
    const loopColor = useRef(undefined);
    const uploadVideo = useRef<File | undefined>(undefined);
    const timeLoop = useRef(0);
    const { profile } = useMember();
    const [filename, setFilename] = useState('');
    const history = useHistory<any>();

    useEffect(() => {
        if (history.action === 'POP') {
            handleCloseModal();
        }
    }, [history.action]);

    const textToSpeech = new SpeechSynthesisUtterance();
    textToSpeech.lang = 'en-US';

    const [useUploadFileRequest] = useUploadFile({
        onCompleted: () => {
            uploadVideo.current = undefined;
            setFilename('');
            setShowModal();
            setUploaded(true);
            useUpdateCatalogueRequest({
                variables: { id: catalogueId, input: { memberId: profile._id, difficulty: currentDifficulty } },
            });
        },
        onError() {
            uploadVideo.current = undefined;
            setFilename('');
            setShowModal();
            Toast.show({ text: 'Failure, please try again!' });
        },
    });

    const [useUpdateCatalogueRequest] = useUpdateCatalogue({
        onCompleted: () => {
            setLoading(false);
            setToast('Uploaded Successfully!');
        },
        onError: (error) => {
            setLoading(false);
            Toast.show({ text: error.message });
        },
    });

    const captureCamera = (callback: (camera: MediaStream) => void) => {
        navigator.mediaDevices
            ?.getUserMedia({ video: true })
            .then((camera) => {
                callback(camera);
            })
            .catch(() => {
                alert('Unable to capture your camera.');
                setShowModal();
            })
            .finally(() => setLoading(false));
    };

    const getCatalogueNameAlias = () => {
        return catalogueName.toLowerCase().replace(/ /g, '-').replace(/\//g, '-');
    };

    useEffect(() => {
        if (showModal) {
            switch (catalogueName) {
                case 'Passing Drill':
                    voiceArray.current = getVoiceArrayByCatalogue(Passing, currentDifficulty);
                    break;
                case 'Goalie Training':
                    voiceArray.current = getVoiceArrayByCatalogue(Goalie, currentDifficulty);
                    break;
                case 'Hook Drill':
                    voiceArray.current = getVoiceArrayByCatalogue(HookDrill, currentDifficulty);
                    break;
                case 'Quick Left/Right Drill':
                    voiceArray.current = getVoiceArrayByCatalogue(QuickLeftRightDrill, currentDifficulty);
                    break;
                case 'Mixed Training':
                    voiceArray.current = getVoiceArrayByCatalogue(MixedTraining, currentDifficulty);
                    break;
                case 'Dribbling':
                default:
                    voiceArray.current = getVoiceArrayByCatalogue(Dribbling, currentDifficulty);
                    break;
            }
            // voiceArray.current = getVoiceArray(currentDifficulty);
            colorVoice.current = new Audio(
                `${
                    process.env.REACT_APP_API_URL
                }/training-voices/${getCatalogueNameAlias()}-${currentDifficulty.toLowerCase()}.mp3`,
            );

            // set timeout for wait camera turn on
            setLoading(true);
            setTimeout(() => {
                video = document.getElementById('record');
                captureCamera((camera: MediaStream) => {
                    video.muted = true;
                    video.volume = 1;
                    video.srcObject = camera;
                    cam.current = camera;
                    const recorder: any = new RecordRTC(cam.current, {
                        type: 'video',
                        mimeType: 'video/mp4',
                    });

                    setRecord(recorder);
                });
            }, 2000);
        }
    }, [showModal]);

    useEffect(() => {
        if (started) {
            colorVoice.current.muted = true;
            setIntroCounter(3);
        } else {
            colorVoice.current.muted = false;
            colorVoice.current.pause();
            colorVoice.current.currentTime = 0;
        }
    }, [started]);

    const startRecord = () => {
        setStarted(true);
        // colorVoice.current.play(); //TODO: disable mp3 player
        textToSpeech.text = '3';
        speechSynthesis.speak(textToSpeech);
        const arrTemp = [...voiceArray.current];
        for (let i = 0; i < arrTemp.length; i++) {
            switch (arrTemp[i]) {
                case 'backhand-pass-green':
                case 'backhand-pass-red':
                case 'backhand-pass-yellow':
                    arrTemp[i] = 'backhand-pass';
                    break;
                case 'forehand-pass-green':
                case 'forehand-pass-red':
                case 'forehand-pass-yellow':
                    arrTemp[i] = 'forehand-pass';
                    break;
                default:
                    break;
            }
        }

        setTimeout(() => {
            record.startRecording();
            record.camera = cam.current;

            colorVoice.current.currentTime = 0;
            colorVoice.current.muted = false;

            //  init render
            timeLoop.current = 0;
            setFilename(voiceArray.current[0]);
            // textToSpeech.text = voiceArray.current[0];
            textToSpeech.text = arrTemp[0];
            !textToSpeech.text.includes('cones') && speechSynthesis.speak(textToSpeech);

            loopColor.current = setInterval(() => {
                timeLoop.current += 2; // number of second for loop
                // number of second for loop
                if (!(timeLoop.current % 2)) {
                    // if (voiceArray.current[timeLoop.current / 2]) {
                    if (arrTemp[timeLoop.current / 2]) {
                        setFilename(voiceArray.current[timeLoop.current / 2]);
                        // textToSpeech.text = voiceArray.current[timeLoop.current / 2];
                        textToSpeech.text = arrTemp[timeLoop.current / 2];
                        !textToSpeech.text.includes('cones') && speechSynthesis.speak(textToSpeech);
                    } else {
                        endRecord();
                    }
                }
            }, 2 * 1000) as any; // number of second for loop
        }, 3000);
    };

    const stopRecordingCallback = () => {
        setPlayBackVideoUrl(URL.createObjectURL(record.getBlob()));
        const file = new File([record.getBlob()], `${new Date().getTime()}-${currentDifficulty}-training-record.mp4`, {
            type: 'video/mp4',
        });
        uploadVideo.current = file as any;
        record.camera.stop();
        record.destroy();
        setStarted(false);
        setRecord(undefined);
    };

    const endRecord = () => {
        clearInterval(loopColor.current);
        try {
            record.stopRecording(stopRecordingCallback);
        } catch (error) {
            console.log(error);
        }
    };

    const handleUploadVideo = async () => {
        colorVoice.current.pause();
        setLoading(true);
        useUploadFileRequest({
            variables: {
                file: uploadVideo.current,
                memberId: profile._id,
                catalogueId,
                audioFilename: `${getCatalogueNameAlias()}-${currentDifficulty}.mp3`,
            },
        });
        setPlayBackVideoUrl('');
    };

    const handleCloseModal = () => {
        if (!uploadVideo.current) {
            clearInterval(loopColor.current);
            if (record && record.camera) {
                record.camera.stop();
                record.destroy();
            }
            setStarted(false);
            setRecord(undefined);
            video = document.querySelector('video');
            if (video) {
                const stream = video.srcObject;
                if (stream) {
                    const tracks = stream.getTracks();
                    tracks.forEach(function (track: any) {
                        track.stop();
                    });
                }
                video.srcObject = null;
            }
        }
        setFilename('');
        uploadVideo.current = undefined;
        setShowModal();
        setPlayBackVideoUrl('');
        colorVoice.current.pause();
        colorVoice.current.currentTime = 0;
    };

    return (
        <>
            <IonLoading isOpen={showModal && loading} />
            <IonToast
                duration={3000}
                color="success"
                isOpen={!!toast}
                onDidDismiss={() => setToast('')}
                message={toast}
            />
            <IonModal isOpen={showModal}>
                {playBackVideoUrl ? (
                    <PlayBackVideo colorVoice={colorVoice.current} playBackVideoUrl={playBackVideoUrl} />
                ) : (
                    <Video autoPlay playsInline id="record" />
                )}
                {introCounter > 0 ? (
                    <IntroCounterNumber introCounter={introCounter} setIntroCounter={setIntroCounter} />
                ) : null}

                <TrainingImage
                    filename={filename}
                    level={currentDifficulty}
                    isMixedTraining={catalogueName === 'Mixed Training'}
                />

                {!started && uploadVideo.current ? (
                    <ButtonGroup>
                        <IonButton onClick={handleCloseModal} expand="block">
                            Cancel
                        </IonButton>
                        <IonButton onClick={handleUploadVideo} expand="block" color="tertiary">
                            Submit
                        </IonButton>
                    </ButtonGroup>
                ) : (
                    <ControlButton disabled={loading} onClick={started ? endRecord : startRecord}>
                        <IonIcon
                            css={css`
                                width: 55px;
                                height: 55px;
                            `}
                            icon={started ? stopCircleOutline : playCircleOutline}
                            size="large"
                            color="dark"
                        />
                    </ControlButton>
                )}
            </IonModal>
        </>
    );
};

export default TrainingModal;

const Video = styled.video`
    height: 50%;
    background-color: black;
`;

const ControlButton = styled(IonButton)`
    color: var(--ion-color-primary);
    position: absolute;
    bottom: 0;
    left: 50%;
    top: 94%;
    transform: translate(-50%, -50%);
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    &::part(native) {
        box-shadow: none;
        height: fit-content;
    }
`;

const ButtonGroup = styled.div`
    padding: 0.125rem 2rem;
    display: flex;
    justify-content: space-between;
    ion-button {
        width: 45%;
    }
`;
