/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonIcon, IonModal, IonText } from '@ionic/react';
import dayjs from 'dayjs';
import { reorderTwoOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';

import { MemberAttendanceGrid, useMember } from '../features/Member';
import { useMemberProfileAttendances } from '../hooks/useMemberProfileAttendances';

import { useModal } from '.';

export const AttendanceContext = React.createContext<MemberProfileAttendances | null>(null);

const ProfileAttendanceModal: React.FC = () => {
    const { profile } = useMember();
    const [date, setDate] = useState(dayjs());

    const { data } = useMemberProfileAttendances(profile._id, date.year(), date.month());
    const { toggle, showMemberAttendances } = useModal();

    return (
        <AttendanceContext.Provider value={data ? data.member.attendances : null}>
            <Container
                swipeToClose
                isOpen={showMemberAttendances}
                onDidDismiss={() => toggle('showMemberAttendances')}
                backdropDismiss
            >
                <Handle>
                    <IonIcon icon={reorderTwoOutline} />
                </Handle>

                <ContentContainer>
                    <MemberAttendanceGrid {...{ date, setDate }} />

                    <div
                        css={css`
                            margin-top: auto;
                        `}
                    >
                        <IonText>
                            <p>
                                <strong>This Month:</strong> {data?.member.attendances.monthlyCount}
                            </p>
                        </IonText>

                        <IonText>
                            <p>
                                <strong>Total Since First Class:</strong> {data?.member.attendances.totalCount || 0}
                            </p>
                        </IonText>
                    </div>
                </ContentContainer>
            </Container>
        </AttendanceContext.Provider>
    );
};

export default ProfileAttendanceModal;

const Container = styled(IonModal)`
    color: var(--ion-text-color);

    .modal-wrapper[role='dialog'] {
        margin-top: auto;
        height: 640px;
        border-radius: 20px;
    }

    @media only screen and (max-width: 600px) {
        .modal-wrapper[role='dialog'] {
            height: 520px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
`;

const ContentContainer = styled.div`
    height: 100%;
    padding: 1rem;

    overflow: auto;
    display: flex;
    flex-direction: column;
    min-width: 0;

    ion-slides {
        width: 100%;
    }

    ion-slide {
        align-items: flex-start;
    }
`;

const Handle = styled.div`
    text-align: center;
    padding: 1rem;
`;
