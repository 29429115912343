import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const GET_PROGRAMS = gql`
    query GetPrograms {
        programs {
            _id
            name
            link
            startDate
            endDate
            startTime
            endTime
            address
            type
            fee
        }
    }
`;

type TData = { programs: Program[] };

export const usePrograms = (options?: QueryHookOptions<TData>) => {
    return useQuery<TData>(GET_PROGRAMS, options);
};
