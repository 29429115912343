/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonInput, IonItem, IonLabel, IonText } from '@ionic/react';
import { ErrorMessage } from 'formik';
import React from 'react';

import { BirthdatePicker } from './inputs/BirthdatePicker';
import { ExperiencePicker } from './inputs/ExperiencePicker';
import { GenderPicker } from './inputs/GenderPicker';
import { InputItem } from './inputs/Input';
import { JerseySizePicker } from './inputs/JerseySizePicker';
import { PhoneInputItem } from './inputs/PhoneInput';

export type InputProps = typeof IonInput['defaultProps'];

export interface FieldProps {
    name: string;
    label: string;
    inputProps?: InputProps;
}

const getInputElement = ({ name, label, inputProps }: FieldProps) => {
    if (name.includes('phoneNumber')) {
        return <PhoneInputItem fieldName={name} />;
    }

    if (name.includes('birthdate')) {
        return <BirthdatePicker fieldName={name} />;
    }

    if (name.includes('gender')) {
        return <GenderPicker fieldName={name} />;
    }

    if (name.includes('experience')) {
        return <ExperiencePicker fieldName={name} />;
    }

    if (name.includes('jerseySize')) {
        return <JerseySizePicker fieldName={name} />;
    }

    return (
        <IonItem>
            <IonLabel position="floating">{label}</IonLabel>
            <InputItem fieldName={name} {...inputProps} />
        </IonItem>
    );
};

export const Field: React.FC<FieldProps> = ({ name, label, inputProps }) => {
    const inputElement = getInputElement({ name, label, inputProps });

    return (
        <Container>
            {inputElement}
            <ErrorMessage name={name} render={(msg) => <IonText color="danger">{msg}</IonText>} />
        </Container>
    );
};

export default Field;

const Container = styled.div`
    ion-text[color='danger'] {
        font-size: smaller;
        text-align: left;
        padding-left: var(--ion-padding-start, 20px);
    }
`;
