import { IonContent, IonPage } from '@ionic/react';
import React from 'react';

import { Spacer } from '../components';
import Header from '../components/Header';
import Grid from '../features/Home/Grid';
import Slider from '../components/Slider';
import Ads from '../components/Ads';

const Home: React.FC = () => {
    return (
        <IonPage>
            <Header />

            <IonContent>
                <Spacer />
                <Slider />
                <Grid />
                <Ads />
            </IonContent>
        </IonPage>
    );
};

export default Home;
