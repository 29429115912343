import styled from '@emotion/styled';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';

import { usePrevious } from '../../hooks/usePrevious';

type Props = {
    fieldName?: string;
};

export const PhoneInputItem: React.FC<Props> = ({ fieldName = 'phoneNumber' }) => {
    const [{ onBlur, onChange, value }, , { setValue }] = useField(fieldName);

    // Local state
    const [phoneNumber, setPhoneNumber] = useState<[string, string]>([value.slice(0, 3), value.slice(3)]);
    const [prefix, number] = phoneNumber;
    const lastPhoneNumber = usePrevious(phoneNumber);

    useEffect(() => {
        if (phoneNumber && phoneNumber !== lastPhoneNumber) {
            setValue(prefix + number);
        }
    }, [lastPhoneNumber, number, phoneNumber, prefix, setValue]);

    const handlePrefixChange = (e: any) => !!e.detail.value && setPhoneNumber([e.detail.value, number]);

    return (
        <CompundField>
            <IonItem>
                <IonLabel position="floating">Prefix</IonLabel>
                <IonInput value={prefix} onIonChange={handlePrefixChange} readonly />
            </IonItem>
            <IonItem>
                <IonLabel position="floating">Phone Number</IonLabel>
                <IonInput
                    autocomplete={'tel-national' as 'on'}
                    name={fieldName}
                    value={number}
                    onIonBlur={onBlur}
                    onIonChange={(e) => !!e.detail.value && setPhoneNumber([prefix, e.detail.value])}
                    onIonInput={onChange}
                />
            </IonItem>
        </CompundField>
    );
};

const CompundField = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
`;
