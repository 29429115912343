/** @jsxImportSource @emotion/react */

import React from 'react';
import styled from '@emotion/styled';
import { IonGrid, IonRow, IonText } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';

import leagueImg from '../../assets/League.jpeg';
import liveGameImg from '../../assets/Live-Game.jpeg';
import shopImg from '../../assets/Shop.jpeg';
import trainImg from '../../assets/Train.jpeg';
import { useMember } from '../Member';

const Grid: React.FunctionComponent = () => {
    const history = useHistory();
    const { profile } = useMember();

    return (
        <Container>
            <IonRow>
                <SquareContainer onClick={() => history.push('/watch')}>
                    <Square
                        css={css(`background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
                    url(${liveGameImg});`)}
                    >
                        <IonText>
                            <h4>WATCH GAME</h4>
                        </IonText>
                    </Square>
                </SquareContainer>
                <SquareContainer onClick={() => window.open('https://www.youthfloorballshop.com/')}>
                    <Square
                        css={css(`background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
                    url(${shopImg});`)}
                    >
                        <IonText>
                            <h4>SHOP</h4>
                        </IonText>
                    </Square>
                </SquareContainer>
                <SquareContainer onClick={() => history.push('/leagues')}>
                    <Square
                        css={css(`background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
                    url(${leagueImg});`)}
                    >
                        <IonText>
                            <h4>LEAGUE</h4>
                        </IonText>
                    </Square>
                </SquareContainer>
                <SquareContainer onClick={() => history.push(profile ? '/training-catalogue' : '/login')}>
                    <Square
                        css={css(`background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
                    url(${trainImg});`)}
                    >
                        <IonText>
                            <h4>TRAIN</h4>
                        </IonText>
                    </Square>
                </SquareContainer>
            </IonRow>
        </Container>
    );
};

export default Grid;

const Container = styled(IonGrid)`
    margin: 0.8125rem;
`;

const SquareContainer = styled.div`
    width: calc(100% / 2);
    position: relative;
    padding-bottom: calc(100% / 2);
`;

const Square = styled.div`
    width: calc(100% - 14px);
    height: calc(100% - 14px);
    margin: 7px;
    position: absolute;
    color: white;
    background-size: cover;
    border-radius: 8px;

    ion-text {
        h4 {
            margin: 0;
            font-size: 20px;
            font-family: 'Montserrat-Bold';
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 1;
        }
    }
`;
