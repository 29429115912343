import { gql, MutationHookOptions, useMutation } from '@apollo/client';

interface UpdateCatalogueInput {
    memberId: string;
    difficulty: string;
}

export const UPDATE_CATALOGUE_REQUEST = gql`
    mutation UpdateCatalogue($id: String!, $input: UpdateCatalogueInput!) {
        updateCatalogue(id: $id, input: $input) {
            _id
            members {
                memberId
                difficulties
            }
        }
    }
`;

type TData = { updateCatalogue: { _id: string } };
type TVariables = { id: string; input: UpdateCatalogueInput };

export const useUpdateCatalogue = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, { id: string; input: UpdateCatalogueInput }>(UPDATE_CATALOGUE_REQUEST, options);
};
