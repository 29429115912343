import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';

type TData = {
    leagueTable: {
        ID: string;
        name: string;
        flag: string;
        rating: { win: number; draw: number; lose: number; points: number; total: number };
    }[];
};

type TVariables = { category: string };

export const useLeague = (options?: QueryHookOptions<TData, TVariables>) => {
    return useLazyQuery<TData, TVariables>(LEAGUE, options);
};

export const LEAGUE = gql`
    query LeagueTable($category: String!) {
        leagueTable(category: $category) {
            ID
            name
            flag
            rating {
                win
                draw
                lose
                total
                points
            }
        }
    }
`;
