import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useField } from 'formik';
import React from 'react';

type Props = {
    fieldName?: string;
};

export const GenderPicker: React.FC<Props> = ({ fieldName = 'gender' }) => {
    const [{ value }, , { setValue }] = useField(fieldName);
    return (
        <IonItem>
            <IonLabel position="floating">Gender</IonLabel>
            <IonSelect value={value} onIonChange={(e) => setValue(e.detail.value)} placeholder="Select">
                <IonSelectOption value="MALE">Male</IonSelectOption>
                <IonSelectOption value="FEMALE">Female</IonSelectOption>
            </IonSelect>
        </IonItem>
    );
};

export default GenderPicker;
