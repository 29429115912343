/** @jsxImportSource @emotion/react */

import Storage from '@aws-amplify/storage';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonIcon, IonRange } from '@ionic/react';
import { pause, play } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';

import { ContentNameType, useContentByName } from '../../hooks/useContentByName';
import { useOnScreen } from '../../hooks/useOnScreen';

import AudioTab from './AudioTab';

export interface TabType {
    title: string;
    backgroundColor: string;
    speed: number;
    shadow: string;
    difficulty: string;
}
const tabs = [
    { title: 'Beginner', backgroundColor: '#7ed321', speed: 1, difficulty: 'beginner', shadow: '#7ED32133' },
    { title: 'Intermediate', backgroundColor: '#FDCA40', speed: 1.5, difficulty: 'intermediate', shadow: '#FDCA4033' },
    { title: 'Advanced', backgroundColor: '#E53D3F', speed: 3, difficulty: 'advanced', shadow: '#E53D3F33' },
];

interface AudioPlayerProps {
    setCurrentDifficulty: (difficulty: string) => void;
    showModal: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ setCurrentDifficulty, showModal }) => {
    const [currentTab, setCurrentTab] = useState<TabType>(tabs[0]);
    // const [isPaused, setIsPaused] = useState(true);
    // const [currentValue, setCurrentValue] = useState(0);
    // const [audio, setAudio] = useState(new Audio(''));
    // const timer = useRef<any>(null);
    // const pauseBeforeSeek = useRef(false);
    // const firstSeek = useRef(true);

    // const ref = useRef<HTMLDivElement | null>(null);
    // const isVisible = useOnScreen(ref);

    // useEffect(() => {
    //     if (!isVisible && location.pathname !== '/do-training') {
    //         handlePlayPause(true, true);
    //     }
    // }, [isVisible, location.pathname]);

    // useContentByName({
    //     variables: { name: ContentNameType.MP3 },
    //     async onCompleted(data) {
    //         try {
    //             const url = await Storage.get(data?.contentByName?.description);
    //             setAudio(new Audio(url as string));
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     },
    // });

    useEffect(() => {
        // audio.playbackRate = currentTab.speed;
        setCurrentDifficulty(currentTab.difficulty);
    }, [currentTab]);

    // useEffect(() => {
    //     if (showModal) {
    //         handlePlayPause(true, true);
    //     }
    // }, [showModal]);

    // useEffect(() => {
    //     if (currentValue === 100) {
    //         handlePlayPause(true, false);
    //     }
    // }, [currentValue]);

    // useEffect(() => {
    //     if (!isPaused) {
    //         const audioPlayer = setInterval(() => {
    //             setCurrentValue(Math.trunc(((audio.currentTime || 0) / audio.duration) * 100));
    //         }, 100);
    //         return () => clearInterval(audioPlayer);
    //     }
    // }, [isPaused]);

    // const handlePlayPause = (status: boolean, seeking: boolean) => {
    //     if (isPaused && !seeking) {
    //         audio
    //             .play()
    //             .then(() => {})
    //             .catch((err: any) => console.log(err));
    //     } else {
    //         audio.pause();
    //     }
    //     setIsPaused(status);
    // };

    return (
        <div
            css={css`
                box-shadow: 0px 2px 16px 5px ${currentTab.shadow};
                transition: 0.2s;
            `}
        >
            <AudioTabs>
                {tabs.map((tab, index) => (
                    <AudioTab tab={tab} key={index} currentTab={currentTab} setCurrentTab={setCurrentTab} />
                ))}
            </AudioTabs>
            {/* <AudioRange ref={ref}>
                <Range>
                    <IonIcon
                        css={css`
                            cursor: pointer;
                        `}
                        icon={isPaused ? play : pause}
                        onClick={() => {
                            !!audio.duration && handlePlayPause(!isPaused, false);
                        }}
                    />
                    <IonRange
                        value={currentValue}
                        onIonFocus={(e) => {
                            if (firstSeek.current) {
                                pauseBeforeSeek.current = isPaused;
                                handlePlayPause(true, true);
                                firstSeek.current = false;
                            }
                            const ionRangeElement: any = e.target;
                            ionRangeElement.blur();
                        }}
                        onIonChange={(e) => {
                            if (isPaused && currentValue !== 100) {
                                if (!audio.duration) {
                                    return;
                                }
                                setCurrentValue(e.detail.value as number);
                                audio.currentTime = ((e.detail.value as number) * audio.duration) / 100;
                                if (timer.current) {
                                    clearTimeout(timer.current);
                                }
                                timer.current = setTimeout(() => {
                                    handlePlayPause(pauseBeforeSeek.current, pauseBeforeSeek.current);
                                    firstSeek.current = true;
                                }, 200);
                                return () => clearTimeout(timer.current);
                            }
                        }}
                    />
                </Range>
            </AudioRange> */}
        </div>
    );
};

const AudioTabs = styled.div`
    display: flex;
`;

const AudioRange = styled.div`
    height: 75px;
    width: 100%;
    display: flex;
    align-items: center;
`;

const Range = styled.div`
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    ion-icon {
        transform: scale(1.5);
        color: var(--ion-title-color);
        margin-right: 5px;
    }
    ion-range {
        padding-right: 0px;
        --knob-size: 15px;
        --bar-background: var(--ion-text-color);
        --knob-background: var(--ion-title-color);
        --bar-background-active: var(--ion-title-color);
    }
`;

export default AudioPlayer;
