import { IonInput } from '@ionic/react';
import { useField } from 'formik';
import React from 'react';

import { InputProps } from '../Field';

export const InputItem: React.FC<
    InputProps & {
        fieldName: string;
    }
> = ({ fieldName, ...rest }) => {
    const [field] = useField(fieldName);

    return (
        <IonInput
            name={fieldName}
            value={field.value}
            onIonInput={field.onChange}
            onIonChange={field.onChange}
            onIonBlur={field.onBlur}
            {...rest}
        />
    );
};
