import { gql, QueryHookOptions, useQuery } from '@apollo/client';

type TData = { videos: Video[] };
type TVariables = { skip: number; isShow: boolean; competitionId: string; isLimit: boolean };

export const useVideos = (options?: QueryHookOptions<TData, TVariables>) => {
    return useQuery<TData, TVariables>(VIDEOS, options);
};

export const VIDEOS = gql`
    query Videos($skip: Float!, $isShow: Boolean!, $competitionId: String!, $isLimit: Boolean!) {
        videos(skip: $skip, isShow: $isShow, competitionId: $competitionId, isLimit: $isLimit) {
            _id
            userId {
                _id
                name
            }
            videoUrl
            liked
            countLiked
            competitionId
        }
    }
`;
