import { gql, useQuery } from '@apollo/client';

export const useMemberProfileAttendances = (id: string, year: number, month: number) => {
    return useQuery<{ member: MemberProfile }, { id: string; year: number; month: number }>(
        MEMBER_PROFILE_ATTENDANCES,
        {
            variables: { id, year, month },
        },
    );
};

const MEMBER_PROFILE_ATTENDANCES = gql`
    query Member($id: String!, $year: Int!, $month: Int!) {
        member(id: $id) {
            _id
            attendances(year: $year, month: $month) {
                monthlyCount
                totalCount
                activities {
                    _id
                    programId
                    createdAt
                }
            }
        }
    }
`;
