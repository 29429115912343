import styled from '@emotion/styled';
import React from 'react';

interface CounterCardProps {
    time: number;
}

const CounterCard: React.FunctionComponent<CounterCardProps> = ({ time }) => {
    return <Container>{time}</Container>;
};

export default React.memo(CounterCard);

const Container = styled.div`
    background-color: var(--ion-header-background);
    border-radius: 10%;
    color: var(--ion-white-text-button);
    font-size: 30px;
    font-family: 'Montserrat-Bold';
    width: 2rem;
    display: flex;
    justify-content: center;
`;
