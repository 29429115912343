import styled from '@emotion/styled';
import { IonIcon, IonLoading, IonModal } from '@ionic/react';
import { ReactModalOptions } from '@ionic/react/dist/types/components/IonModal';
import { reorderTwoOutline } from 'ionicons/icons';
import React from 'react';

export interface ModalProps extends ReactModalOptions {
    isOpen: boolean;
    loading?: boolean;
}

/**
 * @deprecated
 */
export const Modal: React.FC<ModalProps> = ({ children, loading, ...props }) => {
    return (
        <Container swipeToClose {...props}>
            <Handle>
                <IonIcon icon={reorderTwoOutline} />
            </Handle>

            <ContentContainer>
                <IonLoading isOpen={!!loading} />
                {children}
            </ContentContainer>
        </Container>
    );
};

export default Modal;

const Container = styled(IonModal)`
    color: var(--ion-text-color);

    .modal-wrapper[role='dialog'] {
        margin-top: auto;
        height: 640px;
        border-radius: 20px;
    }

    @media only screen and (max-width: 600px) {
        .modal-wrapper[role='dialog'] {
            height: 500px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
`;

const ContentContainer = styled.div`
    height: 100%;
    padding: 1rem;
`;

const Handle = styled.div`
    text-align: center;
    padding: 1rem;
`;
