/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
    IonButton,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';

import Header from '../components/Header';
import { PayWithBankTransferModal, PayWithSGQRModal, useModal } from '../modals';
import { usePrograms } from '../hooks/usePrograms';
import ProgramItem from '../features/Payment/ProgramItem';
import { useMember } from '../features/Member';

interface ProgramExtend extends Program {
    isSelected: boolean;
}

const Payment: React.FC = () => {
    const [programs, setPrograms] = useState<ProgramExtend[]>([]);
    usePrograms({
        onCompleted: (data) => {
            setPrograms(
                data.programs.map((p) => {
                    return { ...p, isSelected: false };
                }),
            );
        },
    });

    const { toggle } = useModal();
    const [amount, setAmount] = useState(0);
    const [approvalPrograms, setApprovalPrograms] = useState<ApprovalProgram[]>([]);

    const calculateAmount = () => {
        let tmp = 0;
        programs.forEach((p) => {
            if (p.isSelected) tmp += p.fee * p.quantity!;
        });
        setAmount(tmp);
    };

    const onTickProgramHandler = (id: string, quantity = 1) => {
        setPrograms(
            programs.map((p) => {
                if (p._id === id) return { ...p, isSelected: !p.isSelected, quantity: quantity };
                return p;
            }),
        );
    };

    const onUpdateQuantity = (id: string, quantity: number) => {
        setPrograms(
            programs.map((p) => {
                if (p._id === id) return { ...p, quantity: quantity };
                return p;
            }),
        );
    };

    useEffect(() => {
        calculateAmount();
        setApprovalPrograms(
            programs
                .filter((item) => item.isSelected)
                .map((item) => ({ programId: item._id, quantity: item.quantity || 1 })),
        );
    }, [JSON.stringify(programs)]);

    return (
        <IonPage>
            <PayWithSGQRModal {...{ amount, approvalPrograms }} />
            <PayWithBankTransferModal {...{ amount, approvalPrograms }} />

            <Header />

            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Programs</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <Container>
                    <IonList>
                        {!programs.length ? (
                            <IonItem>
                                <h1>Empty Program</h1>
                            </IonItem>
                        ) : (
                            <>
                                <IonItem lines="none">
                                    <h3>Session Programs</h3>
                                </IonItem>
                                {programs
                                    .filter((program) => program.type === 'session')
                                    .map((program, index) => (
                                        <IonItem key={index} lines="none">
                                            <ProgramItem
                                                program={program}
                                                onTickProgramHandler={onTickProgramHandler}
                                                onUpdateQuantity={onUpdateQuantity}
                                            />
                                        </IonItem>
                                    ))}
                                <IonItem lines="none">
                                    <h3>Monthly Programs</h3>
                                </IonItem>
                                {programs
                                    .filter((program) => program.type === 'monthly')
                                    .map((program, index) => (
                                        <IonItem key={index} lines="none">
                                            <ProgramItem
                                                program={program}
                                                onTickProgramHandler={onTickProgramHandler}
                                            />
                                        </IonItem>
                                    ))}
                            </>
                        )}
                    </IonList>

                    <IonList>
                        <IonItem
                            css={css`
                                text-align: right;
                                font-weight: 600;
                            `}
                        >
                            <IonLabel>Total Fees</IonLabel>
                            <IonInput readonly color="primary" value={`$${amount}`} />
                        </IonItem>
                        <IonListHeader>
                            <IonLabel>Payment Methods</IonLabel>
                        </IonListHeader>

                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                padding: 1rem;
                            `}
                        >
                            {/* {sessionType === 'GENERAL' && ( */}
                            <IonButton onClick={() => toggle('showPayWithSGQR')}>Pay via PayNow</IonButton>
                            {/* )} */}

                            {/* {sessionType === 'GENERAL' && ( */}
                            <IonButton onClick={() => toggle('showPayWithBankTransfer')}>
                                Pay via Bank Transfer
                            </IonButton>
                            {/* )} */}

                            {/* {sessionType === 'OTHER' && (
                                <Alert>
                                    <IonIcon icon={alertCircle} />
                                    <div>
                                        Monthly subscription rate is available for trainees who would like to sign up
                                        for at least one training a week, for the period of 1 month. Please contact our
                                        staff to arrange this option.
                                    </div>
                                </Alert>
                            )} */}
                        </div>
                    </IonList>
                </Container>
            </IonContent>
        </IonPage>
    );
};

export default Payment;

const Container = styled.div`
    padding-top: 1rem;

    ion-input {
        text-align: right;
    }

    ion-select {
        max-width: 70% !important;
    }
`;

// const Alert = styled.div`
//     padding: 1rem;
//     color: rgba(180, 83, 9, 1);
//     background-color: rgba(255, 251, 235, 1);
//     display: grid;
//     grid-template-columns: auto 1fr;
//     grid-gap: 0.5rem;
// `;
