/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { IonSlide, IonSlides } from '@ionic/react';
import React from 'react';

import { MemberCompetitionRecords, MemberFeedbacks, MemberPerformance, MemberReports } from '.';

const MemberDetails: React.FC = () => {
    return (
        <Container>
            <IonSlides pager>
                <IonSlide>
                    <MemberPerformance />
                </IonSlide>

                <IonSlide>
                    <MemberFeedbacks />
                </IonSlide>

                <IonSlide>
                    <MemberReports />
                </IonSlide>

                <IonSlide>
                    <MemberCompetitionRecords />
                </IonSlide>
            </IonSlides>
        </Container>
    );
};

export default MemberDetails;

const Container = styled.div`
    flex: 1;
    display: grid;
    grid-template-rows: 1fr auto;
    ion-slides {
        width: 100vw;
        padding-bottom: 0.5rem;
    }

    ion-slide {
        align-items: flex-start;
    }

    ion-slide > ion-card {
        width: 100%;
    }

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0;
    }
`;
