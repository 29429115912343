import create from 'zustand';
import { devtools } from 'zustand/middleware';

const shareWithNativeAPI = async (shareData: ShareData) => {
    try {
        await navigator.share(shareData);
        console.log('Shared successfully.');
    } catch (error) {
        console.log(error);
        throw new Error('There was an error sharing');
    }
};

type ShareData = {
    title?: string;
    text?: string;
    url: string;
};

type State = {
    showMemberAttendances: boolean;
    showMemberShare: ShareData | null;
    showMemberShareSuccess: boolean;
    showMemberQRCode: boolean;
    showPayWithSGQR: boolean;
    showPayWithBankTransfer: boolean;
};

const useModal = create<
    State & {
        toggle: (key: keyof State, visible?: boolean) => void;
        toggleWebShare: (shareData?: ShareData) => void;
    }
>(
    devtools((set) => ({
        shareData: null,
        showMemberAttendances: false,
        showMemberShare: null,
        showMemberShareSuccess: false,
        showMemberQRCode: false,
        showPayWithSGQR: false,
        showPayWithBankTransfer: false,
        toggle: (key, visible) =>
            // eslint-disable-next-line
            // @ts-ignore
            set((state) => ({
                [key]: typeof visible === 'undefined' ? !state[key] : visible,
            })),
        toggleWebShare: async (shareData?: ShareData) => {
            const fallback = (shareData?: ShareData) => {
                // eslint-disable-next-line
                // @ts-ignore
                return set((state) => ({
                    showMemberQRCode: false,
                    showMemberShare: shareData,
                }));
            };

            // Toggle off
            if (!shareData) {
                return set({ showMemberShare: null });
            }

            // Attempt to use native Web Share API first, upon error use UI fallback
            if (window.location.protocol === 'https' && 'share' in navigator) {
                try {
                    await shareWithNativeAPI(shareData);
                } catch (err) {
                    return fallback(shareData);
                }
            } else {
                console.log('The Web Share API is not supported in your browser.');
                return fallback(shareData);
            }
        },
    })),
);

export default useModal;
