import { useClipboard } from '@capacitor-community/react-hooks/clipboard';
import styled from '@emotion/styled';
import { IonIcon, IonModal, IonText, IonToast } from '@ionic/react';
import { chatbubbleEllipsesOutline, copy, logoWhatsapp, reorderTwoOutline } from 'ionicons/icons';
import React from 'react';

import { useModal } from '.';

const ShareMethod: React.FC<{
    type: 'whatsapp' | 'sms' | 'copy';
}> = ({ type }) => {
    const { setValue } = useClipboard();
    const { showMemberShare: data, toggle } = useModal();

    if (!data) return null;

    switch (type) {
        case 'whatsapp':
            return (
                <ShareItem
                    href={`https://api.whatsapp.com/send?text=${data.url}`}
                    data-action="share/whatsapp/share"
                    onClick={() => {
                        toggle('showMemberShareSuccess');
                    }}
                >
                    <IonIcon icon={logoWhatsapp} style={{ color: '#25D366' }} />
                    <IonText>
                        <p>Whatsapp</p>
                    </IonText>
                </ShareItem>
            );

        case 'sms':
            return (
                <ShareItem href={`sms:&body=${data.url}`} onClick={() => toggle('showMemberShareSuccess')}>
                    <IonIcon icon={chatbubbleEllipsesOutline} />
                    <IonText>
                        <p>SMS</p>
                    </IonText>
                </ShareItem>
            );

        case 'copy':
            return (
                <ShareItem
                    href="#"
                    onClick={async (e) => {
                        e.preventDefault();
                        setValue(data.url);
                        toggle('showMemberShareSuccess');
                    }}
                >
                    <IonIcon icon={copy}></IonIcon>
                    <IonText>
                        <p>Copy to Clipboard</p>
                    </IonText>
                </ShareItem>
            );

        default:
            return null;
    }
};

const ProfileShareModal = () => {
    const { showMemberShare, showMemberShareSuccess, toggleWebShare, toggle } = useModal();

    if (!showMemberShare) return null;

    return (
        <Container swipeToClose isOpen onDidDismiss={() => toggleWebShare()}>
            <Handle onClick={() => toggleWebShare()}>
                <IonIcon icon={reorderTwoOutline} />
            </Handle>

            <ContentContainer>
                <ShareMethod type="whatsapp" />
                <ShareMethod type="sms" />
                <ShareMethod type="copy" />
            </ContentContainer>

            <IonToast
                isOpen={showMemberShareSuccess}
                message="Success"
                duration={300}
                onDidDismiss={() => toggle('showMemberShareSuccess')}
            />
        </Container>
    );
};

export default ProfileShareModal;

const Container = styled(IonModal)`
    color: var(--ion-text-color);

    .modal-wrapper[role='dialog'] {
        height: 200px;
        margin-top: auto;
        border-radius: 20px 20px 0 0;
    }
`;

const ContentContainer = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
`;

const Handle = styled.div`
    text-align: center;
    padding: 1rem;
`;

const ShareItem = styled.a`
    flex: 1;
    text-align: center;

    ion-icon {
        font-size: 3rem;
    }
`;
