import styled from '@emotion/styled';
import { IonSlide, IonSlides } from '@ionic/react';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext, useMemo } from 'react';

import { AttendanceContext } from '../../modals/MemberAttendanceModal';

type Props = {
    date: Dayjs;
    setDate: (date: Dayjs) => void;
};

const MemberAttendanceGrid: React.FC<Props> = ({ date, setDate }) => {
    const swiper = React.createRef<HTMLIonSlidesElement>();
    const slides = useMemo(() => {
        return [
            {
                id: date.subtract(1, 'month').format('YYYYMM'),
                value: date.subtract(1, 'month'),
            },
            {
                id: date.format('YYYYMM'),
                value: date,
            },
            {
                id: date.add(1, 'month').format('YYYYMM'),
                value: date.add(1, 'month'),
            },
        ];
    }, [date]);

    const initialSlide = slides.findIndex((slide) => slide.id === date.format('YYYYMM'));

    const MemoDatePicker = () =>
        useMemo(
            () => (
                <IonSlides
                    ref={swiper}
                    key={slides.map((slide) => slide.id).join('-')}
                    options={{ initialSlide }}
                    onIonSlideNextEnd={async () => {
                        if (swiper.current) {
                            const activeIndex = await swiper.current.getActiveIndex();
                            if (activeIndex !== initialSlide) {
                                setDate(date.add(1, 'month'));
                            }
                        }
                    }}
                    onIonSlidePrevEnd={async () => {
                        if (swiper.current) {
                            const activeIndex = await swiper.current.getActiveIndex();
                            if (activeIndex !== initialSlide) {
                                setDate(date.subtract(1, 'month'));
                            }
                        }
                    }}
                >
                    {slides.map((slide) => (
                        <IonSlide key={slide.id}>
                            <MonthGrid date={slide.value} />
                        </IonSlide>
                    ))}
                </IonSlides>
            ),
            [slides],
        );
    return <MemoDatePicker />;
};

const MonthGrid: React.FC<{ date: Dayjs }> = ({ date }) => {
    const attendances = useContext(AttendanceContext);

    const days = date.daysInMonth();
    const monthTitle = date.format('MMMM YYYY');
    const monthStartDay = date.startOf('month').day();

    return (
        <Container>
            <Title>{monthTitle}</Title>
            <DayOfWeek>
                {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, i) => (
                    <div key={i}>{day}</div>
                ))}
            </DayOfWeek>
            <WeekGrid startDay={monthStartDay}>
                {Array.from(Array(days), (_, i) => {
                    return (
                        <DateGrid
                            key={i}
                            isActive={attendances?.activities?.some((item) =>
                                dayjs(item.createdAt).isSame(date.date(i + 1), 'date'),
                            )}
                            isSession={attendances?.activities?.some(
                                (item) => dayjs(item.createdAt).isSame(date.date(i + 1), 'date') && !!item.programId,
                            )}
                        >
                            <div>{i + 1}</div>
                        </DateGrid>
                    );
                })}
            </WeekGrid>
        </Container>
    );
};

export default MemberAttendanceGrid;

const Container = styled.div`
    color: var(--ion-color-medium);
    width: 100%;
    height: inherit;
    overflow: auto;
`;

const Title = styled.div`
    text-align: center;
    margin-bottom: 1rem;
`;

const DayOfWeek = styled.div`
    display: grid;
    grid-gap: 0.25rem;
    grid-template-columns: repeat(7, minmax(0, 1fr));

    & > div {
        text-align: center;
        font-size: smaller;
    }
`;

const WeekGrid = styled.div<{ startDay?: number }>`
    display: grid;
    grid-gap: 0.25rem;
    grid-template-columns: repeat(7, minmax(0, 1fr));

    & > *:first-of-type {
        grid-column-start: ${({ startDay }) => (startDay === 0 ? 7 : startDay)};
    }
`;

const DateGrid = styled.div<{ isActive?: boolean; isSession?: boolean }>`
    width: 100%;
    display: block;
    position: relative;
    padding-bottom: 100%;
    background-color: ${({ isActive, isSession }) =>
        isActive
            ? isSession
                ? 'var(--ion-session-attendance)'
                : 'var(--ion-monthly-attendance)'
            : 'var(--ion-not-attendance)'};

    & > div {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        justify-items: flex-end;
        padding: 0 0.25rem;
        font-size: smaller;
    }
`;
