/** @jsxImportSource @emotion/react */

import { IonContent, IonPage } from '@ionic/react';
import styled from '@emotion/styled';
import React from 'react';

import { CompetitionCard } from '../features/OngoingCompetitions';
import Header from '../components/Header';
import { useCompetitions } from '../hooks/useCompetitions';
import Title from '../components/Title';
import { Spacer } from '../components';

const OngoingCompetition = () => {
    const { data } = useCompetitions();

    return (
        <IonPage>
            <Header />
            <IonContent>
                <ContentContainer>
                    <Title title="Ongoing Competitions" />
                    <Spacer />
                    {data?.competitions.map((competition) => (
                        <CompetitionCard key={competition._id} competition={competition} />
                    ))}
                </ContentContainer>
            </IonContent>
        </IonPage>
    );
};

export default OngoingCompetition;

const ContentContainer = styled.div`
    --background: var(--ion-content-dark);
    margin: 1.5rem 1.5rem 5rem;
`;
