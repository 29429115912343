/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import { FormInput, SubmitButton } from './styled';

import { FormProps } from '.';

const VerifyEmailSignUpForm: React.FunctionComponent<FormProps> = ({ values, setFieldValue, handleSubmit }) => {
    return (
        <>
            <h5>Confirm sign up</h5>
            <small>Please enter the verification code sent to you at</small>
            <div
                css={css`
                    margin: 1rem 0 1.5rem;
                `}
            >
                +{values.phoneNumber}
            </div>
            <FormInput
                placeholder="Verification code"
                type="text"
                name="verificationCode"
                value={values.verificationCode}
                onIonChange={(e) => setFieldValue('verificationCode', e.detail.value!)}
            />
            <SubmitButton
                css={css`
                    width: 100%;
                `}
                onClick={() => handleSubmit()}
                color="danger"
            >
                Confirm
            </SubmitButton>
        </>
    );
};

export default VerifyEmailSignUpForm;
