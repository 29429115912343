/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { IonButton, IonText } from '@ionic/react';
import { useHistory } from 'react-router';
import Storage from '@aws-amplify/storage';

import { useCompetitionStore } from '../../hooks/useCompetitionStore';

interface CompetitionCardProps {
    competition: Competition;
}

const CompetitionCard: React.FunctionComponent<CompetitionCardProps> = ({ competition }) => {
    const history = useHistory();
    const { _id, name, description, videoDemo, numberOfVideo, thumbnail } = competition;
    const setDescription = useCompetitionStore((state) => state.setDescription);
    const setVideoDemo = useCompetitionStore((state) => state.setVideoDemo);
    const [thumbnailUrl, setThumbnailUrl] = useState<string>('');

    const getThumbnailUrl = async () => {
        try {
            const url = await Storage.get(thumbnail);
            setThumbnailUrl(url as string);
        } catch (error) {}
    };

    useEffect(() => {
        thumbnail && getThumbnailUrl();
    }, []);

    return (
        <CardContainer
            css={css`
                background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${thumbnailUrl});
            `}
        >
            <div>
                <CardTitle>{name}</CardTitle>
                <CardSubTitle>{numberOfVideo} users joined</CardSubTitle>
                <Button
                    onClick={() => {
                        setDescription(description);
                        setVideoDemo(videoDemo);
                        history.push(`/videos/${_id}`);
                    }}
                >
                    JOIN NOW
                </Button>
            </div>
        </CardContainer>
    );
};

export default CompetitionCard;

const CardContainer = styled.div`
    margin-bottom: 2rem;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url('https://youthfloorballacademy.com/wp-content/uploads/2019/09/YFL2019-A.jpg');
    background-size: cover;
    color: var(--ion-title-color);
    border-radius: 8px;

    b {
        font-size: 22px;
    }

    div {
        display: flex;
        flex-direction: column;
        padding: 92px 30px 30px;
    }
    ion-text:nth-of-type(2) {
        color: var(--ion-text-color);
        font-size: 14px;
    }
`;

const CardTitle = styled(IonText)`
    font-family: 'Montserrat-Bold';
    font-size: 22px;
`;

const CardSubTitle = styled(IonText)`
    color: var(--ion-text-color);
    font-size: 14px;
`;

const Button = styled(IonButton)`
    font-size: 9px;
    padding: 0px 0;
    margin-top: 20px;
    --background: var(--ion-header-background);
    --border-radius: 4px;
    text-transform: none;
    font-weight: bold;
    width: 75px;
    height: 25px;
`;
