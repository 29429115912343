/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonContent, IonHeader, IonMenu, IonToolbar } from '@ionic/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { menuController } from '@ionic/core';

import LogoMenu from '../assets/logo/YFA-logo.png';
import { useSidebar } from '../hooks/useSidebar';

interface MenuProps {
    id: string;
}

const Menu: React.FunctionComponent<MenuProps> = ({ id }) => {
    const [sidebars, setSidebars] = useState<Sidebar[]>([]);

    useSidebar({
        onCompleted(data) {
            setSidebars(data.sidebars);
        },
    });
    return (
        <IonMenu side="start" menuId="first" contentId={id}>
            <IonHeader>
                <IonToolbar
                    css={css`
                        height: 70px;
                        display: flex;
                        overflow: hidden;
                    `}
                >
                    <Link
                        to="/"
                        css={css`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-decoration: none;
                            color: var(--ion-title-color);
                        `}
                        onClick={async () => await menuController.toggle()}
                    >
                        <img
                            css={css`
                                height: 60px;
                                width: auto;
                            `}
                            src={LogoMenu}
                            alt=""
                        />
                    </Link>
                </IonToolbar>
            </IonHeader>
            <MenuSideBar>
                {sidebars.map((sidebar) => (
                    <a onClick={() => (window.location.href = sidebar.link)} key={sidebar._id}>
                        {sidebar.title}
                    </a>
                ))}
            </MenuSideBar>
        </IonMenu>
    );
};

const MenuSideBar = styled(IonContent)`
    display: flex;
    flex-direction: column;
    a {
        display: block;
        text-decoration: none;
        color: var(--ion-title-color);
        padding: 15px 0 15px 25px;
        border-bottom: 1px solid var(--ion-border-card);
        &:hover {
            background-color: var(--ion-sliver-background-color);
        }
    }
`;
export default Menu;
