import { gql, QueryHookOptions, useQuery } from '@apollo/client';

type TData = {
    member: MemberProfile | null;
};

type TVariables = {
    id: string;
};

export const useMemberProfile = (options?: QueryHookOptions<TData, TVariables>) => {
    return useQuery<TData, TVariables>(MEMBER_PROFILE, options);
};

export const MEMBER_PROFILE = gql`
    query Member($id: String!) {
        member(id: $id) {
            _id
            name
            age
            picture
            tags
            progress {
                year
                month
                score
                gameAwareness
                dribbling
                shooting
                passing
            }
            remainderAmount
            loyaltyPoint
        }
    }
`;
