import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const GET_LIST_CATALOGUES = gql`
    query GetListCatalogues {
        catalogues {
            _id
            name
            members {
                memberId
                difficulties
            }
            privacyPoint
            videoDemo
            thumbnail
        }
    }
`;

type TData = { catalogues: Catalogue[] };

export const useCatalogues = (options?: QueryHookOptions<TData>) => {
    return useQuery<TData>(GET_LIST_CATALOGUES, options);
};
