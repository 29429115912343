/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { IonHeader, IonToolbar, IonButtons, IonIcon, IonButton, IonMenuButton } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from '../hooks/useUser';

const Header: React.FunctionComponent = () => {
    const [user, setUser] = useState<User>();
    const history = useHistory();

    useUser({ onCompleted: (data) => setUser(data.user) });

    const handleLogin = () => {
        if (user?._id) return history.push('/dashboard');
        return history.push('/login');
    };

    return (
        <IonHeader className="ion-no-border">
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonButtons slot="primary">
                    {/* TODO */}
                    {/* <IonButton>
                        <IonIcon slot="icon-only" icon={notificationsSharp} />
                    </IonButton> */}
                    <IonButton
                        onClick={handleLogin}
                        css={css`
                            color: var(--ion-toolbar-color);
                        `}
                    >
                        <IonIcon slot="icon-only" icon={personCircleOutline} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
};

export default Header;
