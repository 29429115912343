import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const GET_PAST_GAMES = gql`
    query GetPastGames {
        pastGames {
            url
        }
    }
`;

type TData = { pastGames: { url: string }[] };

export const usePastGames = (options?: QueryHookOptions<TData>) => {
    return useQuery<TData>(GET_PAST_GAMES, options);
};
