/* eslint-disable @typescript-eslint/no-this-alias */
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonSlides, IonSlide, IonCard, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Storage from '@aws-amplify/storage';

import { useBanners } from '../hooks/useBanners';

const SlideItem: React.FC<{ slide: Banner }> = ({ slide }) => {
    const [imageUrl, setImageUrl] = useState<string>('');
    const getImageUrl = async () => {
        try {
            const url = await Storage.get(slide.imageUrl);
            setImageUrl(url as string);
        } catch (error) {}
    };

    useEffect(() => {
        getImageUrl();
    }, []);

    return (
        <CardContainer
            css={css(`
        background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(${imageUrl});`)}
        >
            <IonText color="light">
                <h5>{slide.title}</h5>
            </IonText>
            <p>{slide.subtitle}</p>
        </CardContainer>
    );
};

const Slider: React.FunctionComponent = () => {
    const history = useHistory();
    const [reRender, setReRender] = useState(false);
    const [banners, setBanners] = useState<Banner[]>([]);
    useBanners({
        onCompleted: (data) => {
            setBanners(data.banners);
        },
    });

    useEffect(() => {
        history.listen(() => {
            setReRender(true);
        });
    }, []);

    useEffect(() => {
        if (reRender) {
            setReRender(false);
        }
    }, [reRender]);

    const handleRegister = (url?: string) => {
        url ? window.open(url) : null;
    };

    const slideOpts = {
        initialSlide: 1,
        slidesPerView: 1.15,
        centeredSlides: true,
        speed: 400,
        coverflowEffect: {
            rotate: 45,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        },
        on: {
            beforeInit() {
                const swiper: any = this;

                swiper.classNames.push(`${swiper.params.containerModifierClass}coverflow`);
                swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);

                swiper.params.watchSlidesProgress = true;
                swiper.originalParams.watchSlidesProgress = true;
            },
            setTranslate() {
                const swiper: any = this;
                const { width: swiperWidth, height: swiperHeight, slides, $wrapperEl, slidesSizesGrid } = swiper;
                const params = swiper.params.coverflowEffect;
                const isHorizontal = swiper.isHorizontal();
                const transform$$1 = swiper.translate;
                const center = isHorizontal ? -transform$$1 + swiperWidth / 2 : -transform$$1 + swiperHeight / 2;
                const rotate = isHorizontal ? params.rotate : -params.rotate;
                const translate = params.depth;
                // Each slide offset from center
                for (let i = 0, length = slides.length; i < length; i += 1) {
                    const $slideEl = slides.eq(i);
                    const slideSize = slidesSizesGrid[i];
                    const slideOffset = $slideEl[0].swiperSlideOffset;
                    const offsetMultiplier = ((center - slideOffset - slideSize / 2) / slideSize) * params.modifier;

                    let rotateY = isHorizontal ? rotate * offsetMultiplier : 0;
                    let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier;
                    // var rotateZ = 0
                    let translateZ = -translate * Math.abs(offsetMultiplier);

                    let translateY = isHorizontal ? 0 : params.stretch * offsetMultiplier;
                    let translateX = isHorizontal ? params.stretch * offsetMultiplier : 0;

                    // Fix for ultra small values
                    if (Math.abs(translateX) < 0.001) translateX = 0;
                    if (Math.abs(translateY) < 0.001) translateY = 0;
                    if (Math.abs(translateZ) < 0.001) translateZ = 0;
                    if (Math.abs(rotateY) < 0.001) rotateY = 0;
                    if (Math.abs(rotateX) < 0.001) rotateX = 0;

                    const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

                    $slideEl.transform(slideTransform);
                    $slideEl[0].style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1;
                    if (params.slideShadows) {
                        // Set shadows
                        let $shadowBeforeEl = isHorizontal
                            ? $slideEl.find('.swiper-slide-shadow-left')
                            : $slideEl.find('.swiper-slide-shadow-top');
                        let $shadowAfterEl = isHorizontal
                            ? $slideEl.find('.swiper-slide-shadow-right')
                            : $slideEl.find('.swiper-slide-shadow-bottom');
                        if ($shadowBeforeEl.length === 0) {
                            $shadowBeforeEl = swiper.$(
                                `<div class="swiper-slide-shadow-${isHorizontal ? 'left' : 'top'}"></div>`,
                            );
                            $slideEl.append($shadowBeforeEl);
                        }
                        if ($shadowAfterEl.length === 0) {
                            $shadowAfterEl = swiper.$(
                                `<div class="swiper-slide-shadow-${isHorizontal ? 'right' : 'bottom'}"></div>`,
                            );
                            $slideEl.append($shadowAfterEl);
                        }
                        if ($shadowBeforeEl.length)
                            $shadowBeforeEl[0].style.opacity = offsetMultiplier > 0 ? offsetMultiplier : 0;
                        if ($shadowAfterEl.length)
                            $shadowAfterEl[0].style.opacity = -offsetMultiplier > 0 ? -offsetMultiplier : 0;
                    }
                }

                // Set correct perspective for IE10
                if (swiper.support.pointerEvents || swiper.support.prefixedPointerEvents) {
                    const ws = $wrapperEl[0].style;
                    ws.perspectiveOrigin = `${center}px 50%`;
                }
            },
            setTransition(duration: any) {
                const swiper: any = this;
                swiper.slides
                    .transition(duration)
                    .find(
                        '.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left',
                    )
                    .transition(duration);
            },
        },
    };

    return (
        <>
            {!reRender && (
                <IonSlides options={slideOpts}>
                    {banners
                        .filter((banner) => banner.isActive)
                        .map((slide) => (
                            <IonSlide key={slide._id} onClick={() => handleRegister(slide.link)}>
                                <SlideItem slide={slide} />
                            </IonSlide>
                        ))}
                </IonSlides>
            )}
        </>
    );
};

export default Slider;

const CardContainer = styled(IonCard)`
    margin: 0px;
    box-shadow: none;
    height: calc(100vw / 2.4);
    width: inherit;
    background-size: cover;

    ion-text {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(0%, -50%);
        text-align: initial;
        margin-left: 20px;

        h5 {
            font-family: 'Montserrat-Bold';
            font-size: 20px;
            margin: 0px;
            width: 250px;
        }
    }

    p {
        font-weight: bold;
        color: white;
        position: absolute;
        bottom: 20px;
        left: 20px;
        margin: 0;
    }
`;
