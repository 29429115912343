import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const GET_BANNERS = gql`
    query GetBanners {
        banners {
            _id
            title
            subtitle
            link
            imageUrl
            isActive
        }
    }
`;

type TData = { banners: Banner[] };

export const useBanners = (options?: QueryHookOptions<TData>) => {
    return useQuery<TData>(GET_BANNERS, options);
};
