import { gql, useMutation } from '@apollo/client';

export const useSetupUserMutation = () => {
    const res = useMutation(
        gql`
            mutation SetupUser($input: SetupUserInput!) {
                setupUser(input: $input) {
                    _id
                    isOnboarded
                }
            }
        `,
        {
            onCompleted: console.log,
            onError: console.log,
        },
    );

    return res;
};
