/** @jsxImportSource @emotion/react */
import Storage from '@aws-amplify/storage';
import styled from '@emotion/styled';
import { IonContent, IonIcon, IonPage } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { videocam } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { Toast } from '@capacitor/core';
import { css } from '@emotion/react';
import ReactPlayer from 'react-player';

import Header from '../components/Header';
import Video from '../components/Video';
import AudioPlayer from '../features/DoTraining/AudioPlayer';
import TrainingModal from '../features/DoTraining/TrainingModal';
import TrainingModalRecorder from '../features/DoTraining/TrainingModalRecorder';
import SpaceHorizontal from '../components/SpaceHorizontal';
import { Spacer } from '../components';
import { useMember } from '../features/Member';
import { useCatalogues } from '../hooks/useCatalogues';
import dribblingImage from '../assets/DRIBBLING TRAINING SETUP.png';
import goalieTrainingImage from '../assets/GOALIE TRAINING SETUP.png';
import passingDrillImage from '../assets/PASSING TRAINING SETUP.png';
import hookAndQuickImage from '../assets/HOOK AND QUICK LEFTRIGHT DRILL TRAINING SETUP.jpg';
import mixedTrainingImage from '../assets/MIXED TRAINING SETUP.jpg';
import { useOnScreen } from '../hooks/useOnScreen';

const DoTraining: React.FunctionComponent = () => {
    const [showModal, setShowModal] = useState(false);
    const [currentDifficulty, setCurrentDifficulty] = useState('beginner');
    const history = useHistory<{ catalogue: Catalogue }>();
    const [catalogueId, setCatalogueId] = useState('');
    const { profile } = useMember();
    const [uploaded, setUploaded] = useState(false);
    const [catalogue, setCatalogue] = useState<Catalogue>();
    const [isPlaying, setIsPlaying] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const isVisible = useOnScreen(ref);
    const [url, setUrl] = useState('');
    const isAssigned = catalogue?.members
        .find((userId) => userId.memberId === profile._id)
        ?.difficulties.includes(currentDifficulty);

    const getVideoUrl = async () => {
        if (catalogue?.videoDemo) {
            const url = await Storage.get(catalogue.videoDemo);
            setUrl(url as string);
        }
    };

    useEffect(() => {
        getVideoUrl();
    }, [catalogue]);

    useEffect(() => {
        if (!isVisible) {
            setIsPlaying(false);
        }
    }, [isVisible]);

    useCatalogues({
        onCompleted: (data) => {
            setCatalogue(data?.catalogues.find((catalogue) => catalogue._id === history.location.state?.catalogue._id));
        },
    });

    useEffect(() => {
        history.listen(() => {
            if (history.action === 'PUSH') setCatalogue(history.location.state?.catalogue);
        });
    }, [history.location.pathname]);

    const modalHandle = () => {
        if (uploaded) {
            Toast.show({ text: 'You have already uploaded video!' });
        }
        if (
            !history.location.state?.catalogue._id ||
            !catalogue?.members.find((member) => member.memberId === profile._id)
        ) {
            history.push('/training-catalogue');
            return;
        } else {
            if (
                history.location.state.catalogue.members.find((userId) => userId.memberId === profile._id) &&
                !uploaded
            ) {
                setCatalogueId(history.location.state?.catalogue._id);
                setShowModal(!showModal);
            }
        }
    };

    const getDescriptionImage = () => {
        const catalogueName = history.location.state?.catalogue.name;
        switch (catalogueName) {
            case 'Goalie Training':
                return goalieTrainingImage;
            case 'Passing Drill':
                return passingDrillImage;
            case 'Hook Drill':
            case 'Quick Left/Right Drill':
                return hookAndQuickImage;
            case 'Mixed Training':
                return mixedTrainingImage;
            case 'Dribbling':
            default:
                return dribblingImage;
        }
    };

    return (
        <IonPage>
            <Header />
            <Container>
                <TrainingModal
                    catalogueId={catalogueId}
                    currentDifficulty={currentDifficulty}
                    setShowModal={modalHandle}
                    showModal={showModal}
                    setUploaded={setUploaded}
                    catalogueName={catalogue?.name || ''}
                />
                {/* <TrainingModalRecorder
                    catalogueId={catalogueId}
                    currentTimeSpeech={2}
                    setShowModal={modalHandle}
                    setUploaded={setUploaded}
                    showModal={showModal}
                /> */}
                <Media ref={ref}>
                    <ReactPlayer
                        css={css`
                            height: 100% !important;
                            display: flex;
                            align-items: center;
                        `}
                        id={catalogue?._id}
                        url={url}
                        playing={isVisible ? isPlaying : false}
                        controls
                        playsinline
                        onPause={() => setIsPlaying(false)}
                    />
                </Media>
                <SpaceHorizontal size="20px">
                    <div
                        css={css`
                            position: relative;
                        `}
                    >
                        <Title>{catalogue?.name || ''}</Title>
                        {isAssigned ? (
                            <UploadButton onClick={modalHandle}>
                                <IonIcon color="light" icon={videocam} />
                                <span>Start Training</span>
                            </UploadButton>
                        ) : (
                            <span>You need to be assigned to start training</span>
                        )}
                    </div>
                    <ImgContainer>
                        <img src={getDescriptionImage() || ''} alt="" />
                    </ImgContainer>
                    <Title title="Difficulty" />
                    <Spacer />
                    <AudioPlayer showModal={showModal} setCurrentDifficulty={setCurrentDifficulty} />
                </SpaceHorizontal>
            </Container>
        </IonPage>
    );
};

const Container = styled(IonContent)`
    text-align: center;
    ion-text {
        text-align: initial;
    }
`;

const UploadButton = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 0;
    padding: 10px;
    background: var(--ion-header-background);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    span {
        color: white;
        padding-left: 4px;
        font-size: 12px;
        font-weight: bold;
    }
`;

const ImgContainer = styled.div`
    height: calc((100vw - 40px) / 1920 * 1080);
    width: 100%;
    margin-top: 1.5rem;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const Title = styled.h3`
    width: 60%;
    font-size: 22px;
    align-items: left;
    text-align: left;
    align-items: flex-end;
    color: var(--ion-title-color);
    margin-bottom: 24px;
    font-family: Montserrat-Bold;
`;

const Media = styled.div`
    width: auto !important;
    height: calc(100vw / 1920 * 1080);
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    video {
        width: 100%;
        height: 100%;
    }
`;

export default DoTraining;
