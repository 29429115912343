import { gql, useQuery } from '@apollo/client';

type TData = { matchInComing: MatchResult };
type TVariable = { start_at: string };

export const useMatchUpcoming = (start_at: string) => {
    return useQuery<TData, TVariable>(MATCH, { variables: { start_at } });
};

export const MATCH = gql`
    query GetMatchUpcomingByTime($start_at: String!) {
        matchInComing(start_at: $start_at) {
            ID
            title
            match_result {
                home {
                    flag
                    name
                    goal
                }
                away {
                    flag
                    name
                    goal
                }
            }
            post_name
            match_date
            match_time
            match_highlight_url
        }
    }
`;
