import { gql, QueryHookOptions, useQuery } from '@apollo/client';

type TData = { user: User };

export const useUser = (options?: QueryHookOptions<TData>) => {
    return useQuery<TData>(CURRENT_USER, options);
};

export const CURRENT_USER = gql`
    query User {
        user {
            _id
            name
            email
            phoneNumber
            profiles {
                _id
                name
                age
                gender
                jerseySize
                tags
                lastActiveAt
                picture
                progress {
                    year
                    month
                    score
                    gameAwareness
                    dribbling
                    shooting
                    passing
                }
            }
            numTokens
            mainProfileId
            isOnboarded
        }
    }
`;
