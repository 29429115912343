/** @jsxImportSource @emotion/react */

import { Toast } from '@capacitor/core';
import styled from '@emotion/styled';
import {
    IonButton,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonLoading,
    IonPage,
    IonText,
    IonToast,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Spacer, Upload } from '../components';
import Header from '../components/Header';
import SpaceHorizontal from '../components/SpaceHorizontal';
import Title from '../components/Title';
import TopLiked from '../features/Videos/TopLiked';
import DemoVideo from '../features/Videos/DemoVideo';
import { useCompetitionStore } from '../hooks/useCompetitionStore';
import useS3Upload from '../hooks/useS3Upload';
import { useUploadVideoRequest } from '../hooks/useUploadVideoMutation';
import { useUser } from '../hooks/useUser';
import { useVideos } from '../hooks/useVideos';
import { useCompetition } from '../hooks/useCompetition';

const Videos: React.FC = () => {
    const { competitionId } = useParams<{ competitionId: string }>();
    const [uploadVideoRequest] = useUploadVideoRequest({
        onCompleted: () => {
            setIsSubmitting(false);
            setToast('Uploaded Successfully!');
        },
        onError: (error) => {
            setIsSubmitting(false);
            Toast.show({ text: error.message });
        },
    });
    const [getCompetition] = useCompetition({
        onCompleted: (data) => {
            setVideoDemo(data.competition.videoDemo);
            setDescription(data.competition.description);
        },
    });

    const { description, videoDemo, setDescription, setVideoDemo } = useCompetitionStore();
    const [videos, setVideos] = useState<Video[]>([]);
    const [currentLength, setCurrentLength] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [disabledInfiniteScroll, setDisabledInfiniteScroll] = useState(false);
    const [user, setUser] = useState<User>();
    const [toast, setToast] = useState('');

    useEffect(() => {
        setToast('');
        setCurrentLength(0);
        setVideos([]);
    }, []);

    useEffect(() => {
        if (!videoDemo || !description) {
            getCompetition({ variables: { id: competitionId } });
        }
    }, [videoDemo, description]);

    useUser({
        onCompleted: (data) => {
            setUser(data.user);
        },
    });

    useVideos({
        variables: { skip: currentLength, isShow: true, competitionId, isLimit: true },
        onCompleted: (data) => {
            data.videos.length && setVideos([...videos, ...data.videos]);
            // currentLength + 5 > data.videos.length && setDisabledInfiniteScroll(true);
            data.videos.length < 5 && setDisabledInfiniteScroll(true);
            const scroll: any = document.getElementById('infinite-scroll-videos');
            scroll?.complete();
        },
    });

    const handleUpload = useS3Upload({
        uid: user?._id || '',
        onCompleted(res) {
            uploadVideoRequest({
                variables: {
                    input: {
                        userId: user?._id || '',
                        videoUrl: res,
                        competitionId,
                    },
                },
            });
        },
        onError(err) {
            setIsSubmitting(false);
            Toast.show({ text: err.message });
        },
    });

    const updateVideo = (video: { id: string; liked: string[] }) => {
        const videoModified = videos.map((i) => (i._id === video.id ? { ...i, liked: video.liked } : i));
        setVideos(videoModified);
    };

    return (
        <IonPage>
            <Header />
            <IonToast
                duration={2000}
                color="success"
                isOpen={!!toast}
                onDidDismiss={() => setToast('')}
                message={toast}
            />
            <IonLoading isOpen={isSubmitting} />
            <Container>
                <DemoVideo videoUrl={videoDemo} />
                <Spacer />
                {description && (
                    <IonText>
                        <DescriptionText>{description}</DescriptionText>
                    </IonText>
                )}
                {user?._id && (
                    <Upload
                        style={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={(e: any) => {
                            handleUpload(e);
                            setIsSubmitting(true);
                        }}
                    >
                        <UploadButton>Upload your Entry</UploadButton>
                    </Upload>
                )}
                <SpaceHorizontal>
                    <Title title="Vote for your favorite videos" />
                    <Spacer />
                    {videos.length
                        ? videos.map((video, index) => (
                              <div key={index}>
                                  <TopLiked video={video} updateVideo={updateVideo} ranked={index + 1} />
                                  <Spacer />
                              </div>
                          ))
                        : null}
                    <IonInfiniteScroll
                        id="infinite-scroll-videos"
                        threshold="30px"
                        disabled={disabledInfiniteScroll}
                        onIonInfinite={() => setCurrentLength(currentLength + 5)}
                    >
                        <IonInfiniteScrollContent
                            loading-spinner="bubbles"
                            loading-text="...Loading"
                        ></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                </SpaceHorizontal>
            </Container>
        </IonPage>
    );
};

export default Videos;

const Container = styled(IonContent)`
    text-align: center;
    ion-text {
        text-align: initial;
    }
`;

const UploadButton = styled(IonButton)`
    height: 35px;
    font-weight: bold;
    font-size: 12px;
    margin: 0px;
    width: 100%;
    --border-radius: 4px;
    text-transform: capitalize;

    &::part(native) {
        --background: var(--ion-header-background);
    }
`;

const DescriptionText = styled.p`
    margin: 0 1rem 1rem;
    white-space: pre-wrap;
`;
