/** @jsxImportSource @emotion/react */

import Auth from '@aws-amplify/auth';
import styled from '@emotion/styled';
import { IonContent, IonLoading, IonPage, IonText, IonToast } from '@ionic/react';
import dayjs from 'dayjs';
import { Formik, FormikHelpers } from 'formik';
import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import Header from '../components/Header';
import { SignUpFields, SignUpForm, VerificationForm } from '../features/Auth';
import { FormGroup, PageFooter } from '../features/Auth/styled';
import { SetupProfiles } from '../features/Setup';
import { useSetupUserMutation } from '../hooks/useSetupUserMutation';

const useConfirmHandler = () => {
    const handler = useCallback(async (values: SignUpFields) => {
        const { email, verificationCode, password, phoneNumber } = values;
        await Auth.confirmSignUp('+' + phoneNumber, verificationCode);
        return Auth.signIn({ username: '+' + phoneNumber, password });
    }, []);
    return handler;
};

const useSignupHandler = () => {
    const handler = useCallback(async (values: SignUpFields) => {
        if (values.password && values.password !== values.retypePassword) {
            throw new Error('Retype password is not match!');
        }

        const { email, password, birthdate, firstName, gender, lastName, phoneNumber } = values;
        const attributes: Record<string, string> = {};
        attributes.email = email;
        attributes.phone_number = '+' + phoneNumber;
        attributes.birthdate = dayjs(birthdate).format('DD/MM/YYYY');
        attributes.given_name = firstName;
        attributes.family_name = lastName;
        attributes.gender = gender;

        return Auth.signUp({
            username: '+' + phoneNumber,
            password,
            attributes,
        });
    }, []);
    return handler;
};

const AccountLogin = () => {
    const history = useHistory();
    const handleConfirm = useConfirmHandler();
    const [setupUser] = useSetupUserMutation();

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showToast, setShowToast] = useState<boolean>(false);

    const handleSignup = useSignupHandler();

    const handleOnSignUp = async (values: SignUpFields, formikHelpers: FormikHelpers<SignUpFields>) => {
        const { firstName, lastName, phoneNumber, members } = values;
        let successRegister = true;
        try {
            setIsSubmitting(true);
            switch (currentStep) {
                case 1:
                    await handleSignup(values);
                    formikHelpers.setSubmitting(false);
                    setCurrentStep(currentStep + 1);

                    break;
                case 2:
                    await handleConfirm(values);
                    setCurrentStep(currentStep + 1);
                    break;
                case 3:
                    await setupUser({
                        variables: {
                            input: {
                                firstName,
                                lastName,
                                phoneNumber,
                                members,
                            },
                        },
                    });
                    window.location.href = '/';
                    break;
                default:
                    break;
            }
        } catch (error) {
            setErrorMessage(error.message);
            setShowToast(true);
            successRegister = false;
        } finally {
            setIsSubmitting(false);
        }

        if (successRegister === true) {
            // send SMS
            const userName = values.firstName + ' ' + values.lastName;
            const password = values.password;
            const phoneNumber = '+' + values.phoneNumber;

            // await fetch('http://[::1]:4000/api/activities/sendSMS', {
            await fetch('https://api.youthfloorballacademy.com/api/activities/sendSMS', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    from: 'YFA',
                    phoneNumber: phoneNumber,
                    contentBody: `Dear ${userName},
                    Your YFA account has been created! Please login to https://app.youthfloorballacademy.com/login to view your attendance records, skills assessment and exclusive training materials.
                    You may login with your username "${phoneNumber}" and password "${password}". You may change your password upon successful login.
                    Thank you.`,
                }),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log(responseJson);
                    // window.location.href = '/login';
                });
        }
    };

    return (
        <IonPage>
            <Header />
            <IonContentContainer>
                <IonLoading isOpen={isSubmitting} />
                <Container>
                    <Formik initialValues={initialValues} onSubmit={handleOnSignUp}>
                        {({ handleSubmit, values, setFieldValue, isValid, setValues }) => {
                            return (
                                <AnimatePresence>
                                    <FormGroup onSubmit={handleSubmit}>
                                        {currentStep === 1 && (
                                            <SignUpForm
                                                handleSubmit={handleSubmit}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                            />
                                        )}
                                        {currentStep === 2 && (
                                            <VerificationForm
                                                handleSubmit={handleSubmit}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                        {currentStep === 3 && (
                                            <SetupProfiles
                                                setValues={setValues}
                                                values={values}
                                                isLoading={isSubmitting}
                                                handleSubmit={handleSubmit}
                                                isBlocked={!isValid}
                                            />
                                        )}
                                    </FormGroup>
                                </AnimatePresence>
                            );
                        }}
                    </Formik>

                    {currentStep === 1 && (
                        <PageFooter>
                            Already have account? <IonText onClick={() => history.push('/login')}> Login now !</IonText>{' '}
                        </PageFooter>
                    )}
                    <IonToast
                        color="danger"
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={errorMessage}
                        duration={3500}
                    />
                </Container>
            </IonContentContainer>
        </IonPage>
    );
};

export default AccountLogin;

const IonContentContainer = styled(IonContent)`
    --background: #000 url('https://youthfloorballacademy.com/wp-content/uploads/2019/09/YFL2019-A.jpg') no-repeat
        center center / cover;
`;
const Container = styled.div`
    background-color: var(--ion-layout-dark);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    ion-button {
        text-transform: none;
    }
    ion-item {
        --min-height: 34px;
    }
    form {
        margin: 3rem 0 4rem;
        overflow: auto;
    }
`;

export const initialMembers = {
    name: '',
    gender: '',
    birthdate: '',
    experience: '',
    jerseySize: '',
};

export const initialValues: SignUpFields = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    birthdate: '',
    gender: 'MALE',
    password: '',
    retypePassword: '',
    verificationCode: '',
    members: [initialMembers],
};
