/** @jsxImportSource @emotion/react */

import Auth, { CognitoUser } from '@aws-amplify/auth';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonContent, IonLoading, IonPage, IonText, IonToast } from '@ionic/react';
import { Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import Header from '../components/Header';
import PasswordInput from '../components/inputs/PasswordInput';
import { SignUpFields } from '../features/Auth';
import { FormGroup, FormInput, PageFooter, SubmitButton } from '../features/Auth/styled';
import { SetupProfiles } from '../features/Setup';
import { useSetupUserMutation } from '../hooks/useSetupUserMutation';

interface LoginFields {
    username: string;
    password: string;
    newPassword: string;
    code: string;
}

enum Challenge {
    NONE,
    RESET_PASSWORD,
    NEW_PASSWORD,
    CONFIRM_CODE,
}

const AccountLogin: React.FunctionComponent = () => {
    const history = useHistory<{ redirect: string }>();

    const [challenge, setChallenge] = useState<Challenge>(Challenge.NONE);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [user, setUser] = useState<CognitoUser>();
    const [setupUser] = useSetupUserMutation();
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [currentStepComfirmCode, setCurrentStepComfirmCode] = useState<number>(1);

    const handleError = (username: string, err: any, hasErr?: boolean, contenErrorCode?: any) => {
        if (err.code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when
            // signing up In this case you need to resend the code and confirm the
            // user About how to resend the code and confirm the user, please check
            // the signUp part
            try {
                // Auth.resendSignUp(username).then(() => {

                // });
                setChallenge(Challenge.CONFIRM_CODE);
                setIsSubmitting(false);
            } catch (err) {
                console.log('error resending code: ', err);
            }
        } else if (err.code === 'PasswordResetRequiredException') {
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
            setChallenge(Challenge.RESET_PASSWORD);
            setIsSubmitting(false);
        } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
            if (contenErrorCode && contenErrorCode !== '') {
                setErrorMessage(contenErrorCode);
            } else {
                setErrorMessage(err.message);
            }
            setShowToast(true);
        } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in
            // the Cognito user pool
            if (contenErrorCode && contenErrorCode !== '') {
                setErrorMessage(contenErrorCode);
            } else {
                setErrorMessage(err.message);
            }
            setShowToast(true);
        } else if (err.code === 'InvalidParameterException') {
            setErrorMessage('Password is required');
            setShowToast(true);
        } else if (hasErr === true) {
            setChallenge(Challenge.CONFIRM_CODE);
            if (contenErrorCode && contenErrorCode !== '') {
                setErrorMessage(contenErrorCode);
            } else {
                setErrorMessage(err.message);
            }
            setShowToast(true);
        } else {
            console.log(err);
            if (contenErrorCode && contenErrorCode !== '') {
                setErrorMessage(contenErrorCode);
            } else {
                setErrorMessage(err.message);
            }
            setShowToast(true);
        }
    };

    const handleOnSignIn = async (values: LoginFields) => {
        const { username, password, newPassword, code } = values;
        let hasErr = false;
        let stepProcessOK = false;
        let contenErrorCode = '';
        try {
            setIsSubmitting(true);

            if (challenge) {
                switch (challenge) {
                    case Challenge.NEW_PASSWORD:
                        await Auth.completeNewPassword(
                            user, // the Cognito User Object
                            newPassword, // the new password
                            {}, // no required attributes
                        );
                        break;
                    case Challenge.RESET_PASSWORD:
                        await Auth.forgotPasswordSubmit(username, code, newPassword);
                        break;
                    case Challenge.CONFIRM_CODE:
                        try {
                            if (currentStepComfirmCode === 1) {
                                contenErrorCode = 'Verification code is incorrect';
                                await Auth.confirmSignUp(username, code);
                                setCurrentStepComfirmCode(currentStepComfirmCode + 1);
                                stepProcessOK = true;
                                contenErrorCode = '';
                            }

                            if (currentStepComfirmCode === 2 || stepProcessOK === true) {
                                contenErrorCode = 'Temp password is incorrect';
                                await Auth.signIn({ username, password });
                                if (stepProcessOK === true) {
                                    setCurrentStepComfirmCode(currentStepComfirmCode + 2);
                                } else {
                                    setCurrentStepComfirmCode(currentStepComfirmCode + 1);
                                }
                                stepProcessOK = true;
                                contenErrorCode = '';
                            }

                            if (currentStepComfirmCode === 3 || stepProcessOK === true) {
                                contenErrorCode = 'New password must be 8 characters or more';
                                const user = await Auth.signIn({ username, password });
                                setUser(user); // store in local state for different scenarios
                                await Auth.changePassword(
                                    user, // the Cognito User Object
                                    password,
                                    newPassword, // the new password
                                    {}, // no required attributes
                                );
                                setCurrentStep(currentStep + 1);
                                contenErrorCode = '';
                            }

                            hasErr = false;
                        } catch (err) {
                            handleError(username, err, undefined, contenErrorCode);
                            hasErr = true;
                            stepProcessOK = false;
                        }
                        break;
                    default:
                        console.log('Challenge not implemented');
                        break;
                }
                // window.location.href = history.location.state?.redirect || '/dashboard';
            } else {
                const user = await Auth.signIn({ username, password });

                setUser(user); // store in local state for different scenarios

                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setChallenge(Challenge.NEW_PASSWORD);
                } else {
                    // The user directly signs in
                    window.location.href = history.location.state?.redirect || '/dashboard';
                }
            }
        } catch (err) {
            handleError(username, err, hasErr, contenErrorCode);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleOnSignUp = async (values: SignUpFields) => {
        const { firstName, lastName, phoneNumber, members } = values;
        try {
            setIsSubmitting(true);
            debugger;
            switch (currentStep) {
                case 2:
                    await setupUser({
                        variables: {
                            input: {
                                firstName,
                                lastName,
                                phoneNumber,
                                members,
                            },
                        },
                    });
                    window.location.href = '/';
                    break;
                default:
                    break;
            }
        } catch (error) {
            setErrorMessage(error.message);
            setShowToast(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleResentOTP = async (values: LoginFields) => {
        const { username } = values;
        try {
            Auth.resendSignUp(username).then(() => {
                setChallenge(Challenge.CONFIRM_CODE);
                setIsSubmitting(false);
            });
            setErrorMessage(
                'Resend the verification code successfully. Please enter the verification code just sent to your phone.',
            );
            setShowToast(true);
        } catch (err) {
            console.log('error resending code: ', err);
        }
    };

    return (
        <IonPage>
            <Header />
            {currentStep === 2 ? (
                <IonContentContainer>
                    <IonLoading isOpen={isSubmitting} />
                    <Container>
                        <Formik initialValues={initialValues} onSubmit={handleOnSignUp}>
                            {({ handleSubmit, values, isValid, setValues }) => {
                                return (
                                    <AnimatePresence>
                                        <FormGroup onSubmit={handleSubmit}>
                                            <SetupProfiles
                                                setValues={setValues}
                                                values={values}
                                                isLoading={isSubmitting}
                                                handleSubmit={handleSubmit}
                                                isBlocked={!isValid}
                                            />
                                        </FormGroup>
                                    </AnimatePresence>
                                );
                            }}
                        </Formik>
                    </Container>
                    <IonToast
                        color="danger"
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={errorMessage}
                        duration={2000}
                    />
                </IonContentContainer>
            ) : (
                <IonContentContainer>
                    <Container>
                        <IonLoading isOpen={isSubmitting} />
                        <Formik
                            initialValues={{
                                username: '',
                                password: '',
                                newPassword: '',
                                code: '',
                            }}
                            onSubmit={handleOnSignIn}
                        >
                            {({ handleSubmit, handleChange, values }) => (
                                <FormGroup onSubmit={handleSubmit}>
                                    {challenge ? (
                                        <>
                                            {challenge === Challenge.RESET_PASSWORD ||
                                                (challenge === Challenge.CONFIRM_CODE && (
                                                    <>
                                                        <FormInput
                                                            type="text"
                                                            name="code"
                                                            placeholder="Enter verification code"
                                                            value={values.code}
                                                            onIonChange={handleChange}
                                                        />
                                                        <SubmitButton
                                                            fill="solid"
                                                            size="small"
                                                            onClick={() => handleResentOTP(values)}
                                                        >
                                                            Resend
                                                        </SubmitButton>
                                                    </>
                                                ))}
                                            <PasswordInput
                                                name="password"
                                                placeholder="Temp password"
                                                value={values.password}
                                                onIonChange={handleChange}
                                            />
                                            <PasswordInput
                                                name="newPassword"
                                                placeholder="Enter a new password"
                                                value={values.newPassword}
                                                onIonChange={handleChange}
                                            />
                                            <SubmitButton expand="block" fill="solid" onClick={() => handleSubmit()}>
                                                Submit
                                            </SubmitButton>
                                        </>
                                    ) : (
                                        <>
                                            <h5
                                                css={css`
                                                    margin-bottom: 1.25rem;
                                                `}
                                            >
                                                Account login
                                            </h5>
                                            <FormInput
                                                placeholder="Email / PhoneNumber"
                                                type="text"
                                                name="username"
                                                value={values.username}
                                                onIonChange={handleChange}
                                            />
                                            <PasswordInput
                                                name="password"
                                                placeholder="Password"
                                                value={values.password}
                                                onIonChange={handleChange}
                                            />
                                            <div
                                                css={css`
                                                    display: flex;
                                                    justify-content: space-between;
                                                    align-items: center;
                                                `}
                                            >
                                                <SubmitButton
                                                    css={css`
                                                        --padding-start: 1.5rem;
                                                        --padding-end: 1.5rem;
                                                    `}
                                                    onClick={() => handleSubmit()}
                                                    color="danger"
                                                    expand="block"
                                                >
                                                    Submit
                                                </SubmitButton>
                                                <IonText
                                                    css={css`
                                                        font-size: 12px;
                                                        font-weight: bold;
                                                        a {
                                                            color: var(--ion-yellow-text-color);
                                                            text-decoration: none;
                                                        }
                                                    `}
                                                >
                                                    <a
                                                        href={`https://youthfloorballacademy.auth.${process.env.REACT_APP_MEMBER_REGION}.amazoncognito.com/forgotPassword?response_type=token&client_id=${process.env.REACT_APP_MEMBER_USER_POOL_WEB_CLIENT_ID}&redirect_uri=${window.location.origin}`}
                                                    >
                                                        Forgot password
                                                    </a>
                                                </IonText>
                                            </div>
                                        </>
                                    )}
                                </FormGroup>
                            )}
                        </Formik>

                        <PageFooter
                            css={css`
                                margin-top: 30vh;
                            `}
                        >
                            Don&apos;t have an account?{' '}
                            <IonText onClick={() => history.push('/sign-up')}> Sign up now !</IonText>{' '}
                        </PageFooter>
                    </Container>
                    <IonToast
                        color="danger"
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={errorMessage}
                        duration={3000}
                    />
                </IonContentContainer>
            )}
        </IonPage>
    );
};

export default AccountLogin;

const IonContentContainer = styled(IonContent)`
    --background: #000 url('https://youthfloorballacademy.com/wp-content/uploads/2019/09/YFL2019-A.jpg') no-repeat
        center center / cover;
`;
// const Container = styled.div`
//     background-color: var(--ion-layout-dark);
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
// `;
const Container = styled.div`
    background-color: var(--ion-layout-dark);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    ion-button {
        text-transform: none;
    }
    ion-item {
        --min-height: 34px;
    }
    form {
        margin: 3rem 0 4rem;
        overflow: auto;
    }
`;

export const initialMembers = {
    name: '',
    gender: '',
    birthdate: '',
    experience: '',
    jerseySize: '',
};

export const initialValues: SignUpFields = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    birthdate: '',
    gender: 'MALE',
    password: '',
    retypePassword: '',
    verificationCode: '',
    members: [initialMembers],
};
