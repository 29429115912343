import styled from '@emotion/styled';
import { IonRippleEffect } from '@ionic/react';
import React, { HTMLProps } from 'react';

export const Upload: React.FC<HTMLProps<HTMLInputElement>> = ({ style, children, ...props }) => {
    return (
        <Container style={style} className="ion-activatable ripple-parent">
            {children}
            <IonRippleEffect />
            <input type="file" {...props} />
        </Container>
    );
};

const Container = styled.div`
    position: relative;

    input[type='file'] {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        opacity: 0;
    }
`;
