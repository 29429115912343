/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonIcon, useIonViewDidEnter } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import Storage from '@aws-amplify/storage';

import { ContentNameType, useContentByName } from '../hooks/useContentByName';

import { Spacer } from '.';

const Ads: React.FunctionComponent = () => {
    const [isShow, setShow] = useState(false);
    const [adsImageUrl, setAdsImageUrl] = useState<string>('');
    const [bannerUrl, setBannerUrl] = useState('');

    useIonViewDidEnter(() => {
        setShow(true);
    });

    useContentByName({
        variables: { name: ContentNameType.IMAGE },
        async onCompleted(data) {
            try {
                const url = await Storage.get(data?.contentByName?.description);
                setAdsImageUrl(url as string);
            } catch (error) {
                console.log(error);
            }
        },
    });

    useContentByName({
        variables: { name: ContentNameType.BANNER },
        onCompleted(data) {
            setBannerUrl(data?.contentByName?.description);
        },
    });

    const handleClickBanner = () => {
        if (bannerUrl) window.open(bannerUrl);
    };

    const handleCloseBanner = (e: React.MouseEvent<HTMLIonIconElement, MouseEvent>) => {
        e.stopPropagation();
        setShow(false);
    };

    return (
        <>
            {isShow && adsImageUrl ? (
                <Container>
                    <Spacer size="5rem" />
                    <AdsContainer
                        css={css`
                            background-image: url(${adsImageUrl});
                        `}
                        onClick={handleClickBanner}
                    >
                        <IonIcon
                            onClick={(e) => handleCloseBanner(e)}
                            slot="icon-only"
                            icon={closeOutline}
                            color="light"
                        />
                    </AdsContainer>
                </Container>
            ) : (
                <></>
            )}
        </>
    );
};

export default Ads;

const Container = styled.div`
    margin-bottom: 0 !important;
`;

const AdsContainer = styled.div`
    position: fixed;
    bottom: 0;
    height: 5rem;
    background-color: var(--ion-border-card);
    width: 100%;
    text-align: end;
    background-size: cover;
    background-position: center;
    margin-bottom: 0 !important;
`;
