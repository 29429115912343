/** @jsxImportSource @emotion/react */

import Storage from '@aws-amplify/storage';
import { Toast } from '@capacitor/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonButton, IonIcon, IonPage, IonText } from '@ionic/react';
import { arrowBack, heart } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useHistory, useParams } from 'react-router';

import shared from '../../src/assets/share.svg';
import Header from '../components/Header';
import { useOnScreen } from '../hooks/useOnScreen';
import { useUpdateVideoLikedRequest } from '../hooks/useUpdateVideoLikedMutation';
import { useUser } from '../hooks/useUser';
import { useVideo } from '../hooks/useVideo';

const VideoDetail: React.FunctionComponent = () => {
    const history = useHistory();
    const ref = useRef<HTMLDivElement | null>(null);
    const [isPlayingVideo, setIsPlayingVideo] = useState(false);
    const [videoDetail, setVideoDetail] = useState<Video>();
    const [user, setUser] = useState<User>();
    const isVisible = useOnScreen(ref);
    const [url, setUrl] = useState('');
    const [useUploadVideoRequest] = useUpdateVideoLikedRequest();
    const { videoId } = useParams<{ videoId: string }>();
    const [competitionId, setCompetitionId] = useState('');

    useUser({
        onCompleted: (data) => {
            setUser(data.user);
        },
    });

    useVideo({
        variables: { id: videoId },
        onCompleted: async (data) => {
            setVideoDetail(data.video);
            setCompetitionId(data.video.competitionId);
            try {
                const url = await Storage.get(data.video.videoUrl);
                setUrl(url as string);
            } catch (error) {}
        },
    });

    const handleLikeVideo = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (!user?._id) {
            history.push({
                pathname: '/login',
                state: {
                    redirect: location.pathname,
                },
            });
        } else {
            if (videoDetail) {
                const liked = videoDetail.liked.includes(user?._id)
                    ? videoDetail.liked.filter((i) => i !== user?._id)
                    : [...videoDetail.liked, user?._id];
                setVideoDetail({ ...videoDetail, liked });
                useUploadVideoRequest({
                    variables: { id: videoDetail._id, input: { liked } },
                });
            }
        }
    };

    const handleCopyVideoUrl = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        try {
            await navigator.clipboard.writeText(`${window.location.host}/video/${videoId}`);
            Toast.show({ text: 'Copied to clipboard!' });
        } catch (error) {
            Toast.show({ text: 'Failed to copied!' });
        }
    };

    return (
        <IonPage css={css(`justify-content: unset; background-color: var(--ion-color-background)`)}>
            <Header />
            <TopLikedCard ref={ref}>
                <div
                    css={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: calc(100% + 1px);
                        z-index: 10;
                        border-radius: 4px;
                        ${!isPlayingVideo
                            ? 'background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));'
                            : ''}
                    `}
                    onClick={() => setIsPlayingVideo(!isPlayingVideo)}
                >
                    {!isPlayingVideo ? (
                        <IonText color="light">
                            <Center>
                                <h4>{videoDetail?.userId.name}</h4>
                            </Center>
                            <Bottom>
                                <Div
                                    css={css`
                                        margin-right: 1.5rem;
                                    `}
                                    onClick={(e) => handleLikeVideo(e)}
                                >
                                    <IonIcon
                                        icon={heart}
                                        color={user?._id && videoDetail?.liked.includes(user?._id) ? 'danger' : 'light'}
                                    />
                                    <p>{videoDetail?.liked.length}</p>
                                </Div>
                                <Div
                                    css={css`
                                        margin-left: 1.5rem;
                                    `}
                                    onClick={handleCopyVideoUrl}
                                >
                                    <img css={css(`width: 16px; height: 16px; color: white`)} src={shared} />
                                </Div>
                            </Bottom>
                        </IonText>
                    ) : null}
                </div>
                <ReactPlayer
                    css={css`
                        width: 100% !important;
                        height: calc((100vw - 2rem) / 1920 * 1080) !important;
                        display: flex;
                        align-items: center;
                    `}
                    url={url}
                    playing={isVisible ? isPlayingVideo : false}
                    controls={false}
                    playsinline
                    onPause={() => setIsPlayingVideo(false)}
                />
            </TopLikedCard>
            <IonButton
                expand="block"
                css={css`
                    margin: 1rem 2rem 0;
                    &::part(native) {
                        --background: var(--ion-header-background);
                    }
                `}
                onClick={() => history.push(`/videos/${competitionId}`)}
            >
                <IonIcon icon={arrowBack} color="light" />
                Back to Competition
            </IonButton>
        </IonPage>
    );
};

export default VideoDetail;

const TopLikedCard = styled.div`
    height: calc((100vw - 2rem) / 1920 * 1080);
    position: relative;
    background-color: var(--ion-layout-dark);
`;

const Center = styled.div`
    position: absolute;
    top: 45%;
    left: 8%;
    transform: translate(-0%, -50%);

    h4 {
        margin: 0px;
        font-family: 'Montserrat-Bold';
    }

    small {
        font-size: 12px;
    }
`;
const Bottom = styled.div`
    display: flex;
    position: absolute;
    bottom: 8%;
    left: 8%;
`;

const Div = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    p {
        font-weight: bold;
        font-size: 10px;
        margin: 0 4px;
    }
`;
