/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { IonButton, IonText } from '@ionic/react';
import React from 'react';

const Banner: React.FunctionComponent = () => {
    return (
        <BannerContainer>
            <div>
                <IonText>Join our Virtual Competition</IonText>
                <IonText>Attractive prizes to be won!</IonText>
                <Button routerLink="/ongoing-competitions">Register now</Button>
            </div>
        </BannerContainer>
    );
};

export default Banner;

const BannerContainer = styled.div`
    border-radius: 5px;
    margin: 30px 0 80px;
    background: #000 url('https://youthfloorballacademy.com/wp-content/uploads/2019/09/YFL2019-A.jpg') no-repeat center
        center / cover;
    div {
        background-color: var(--ion-layout-dark);
        padding: 30px;
        height: 220px;
        display: flex;
        flex-direction: column;
    }
    ion-text:first-of-type {
        color: var(--ion-title-color);
        font-size: 1em;
        font-family: 'Montserrat-Bold';
        letter-spacing: 0.2px;
        flex: 1;
        margin-bottom: 5px;
        margin-top: 30px;
    }
    ion-text:nth-of-type(2) {
        color: var(--ion-text-muted-color);
        font-size: 0.8rem;
        max-width: 212px;
        white-space: break-word;
        line-height: 1.6;
        flex: 3;
    }
`;

const Button = styled(IonButton)`
    font-size: 12px;
    padding: 0px 0;
    margin: 0 0 30px 0;
    --background: var(--ion-header-background);
    --border-radius: 4px;
    text-transform: none;
    font-weight: bold;
    width: 130px;
`;
