import React from 'react';

import { useAuthContext } from './AuthProvider';

/**
 * Hides the component by default unless the current view is not anonymous
 * e.g. (logged in via account owner)
 */
const AccountGuard: React.FC = ({ children }) => {
    const { isAnonymous } = useAuthContext();
    return isAnonymous ? null : <>{children}</>;
};

export default AccountGuard;
