import { useContext } from 'react';

import { UserContext } from '../features/Auth';

export const useCurrentUser = () => {
    const user = useContext(UserContext);

    if (!user) throw new Error('No user found.');

    return user;
};
