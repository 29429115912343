/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
    IonContent,
    IonHeader,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React from 'react';

import { useCurrentUser } from '../hooks/useCurrentUser';

const Settings = () => {
    const user = useCurrentUser();
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Account</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent
                css={css`
                    --background: var(--ion-color-step-100, #e6e6e6);
                `}
            >
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Account</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <Container>
                    <IonList>
                        <IonItem>
                            <IonLabel>Name</IonLabel>
                            {user.name}
                        </IonItem>
                        <IonItem>
                            <IonLabel>Email</IonLabel>
                            {user.email}
                        </IonItem>
                        <IonItem lines="none">
                            <IonLabel>Phone Number</IonLabel>
                            {user.phoneNumber}
                        </IonItem>

                        <IonItemDivider />

                        <IonItem lines="none" button detail routerLink="/tabs/settings/add-profile">
                            <IonLabel>Add Player</IonLabel>
                        </IonItem>

                        <IonItemDivider />

                        <IonItem lines="none" button detail={false} routerLink="/logout">
                            <IonLabel
                                css={css`
                                    text-align: center;
                                `}
                            >
                                Log out
                            </IonLabel>
                        </IonItem>
                    </IonList>
                </Container>
            </IonContent>
        </IonPage>
    );
};

export default Settings;

const Container = styled.div``;
