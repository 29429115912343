import { gql, MutationHookOptions, useMutation } from '@apollo/client';

type UploadVideoInput = {
    userId: string;
    videoUrl: string;
    competitionId: string;
};

export const UPLOAD_VIDEO_REQUEST = gql`
    mutation UploadVideo($input: UploadVideoInput!) {
        uploadVideo(input: $input) {
            _id
            videoUrl
            competitionId
        }
    }
`;

type TData = { uploadVideo: unknown };
type TVariables = { input: UploadVideoInput };

export const useUploadVideoRequest = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, { input: UploadVideoInput }>(UPLOAD_VIDEO_REQUEST, options);
};
