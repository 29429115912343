import { gql, MutationHookOptions, useMutation } from '@apollo/client';

interface UpdateVideoLikedInput {
    liked: string[];
}

export const UPDATE_VIDEO_LIKED_REQUEST = gql`
    mutation UpdateVideoLiked($id: String!, $input: UpdateVideoLikedInput!) {
        updateVideoLiked(id: $id, input: $input) {
            _id
            liked
            countLiked
        }
    }
`;

type TData = { updateVideoLiked: { _id: string; liked: string[] } };
type TVariables = { id: string; input: UpdateVideoLikedInput };

export const useUpdateVideoLikedRequest = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, { id: string; input: UpdateVideoLikedInput }>(UPDATE_VIDEO_LIKED_REQUEST, options);
};
