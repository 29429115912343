import { gql, useQuery } from '@apollo/client';

type TData = { matchResults: MatchResult[] };
type TVariable = { start_at: string };

export const useMatchResults = (start_at: string) => {
    return useQuery<TData, TVariable>(MATCHES, { variables: { start_at } });
};

export const MATCHES = gql`
    query GetMatchResultByTime($start_at: String!) {
        matchResults(start_at: $start_at) {
            ID
            title
            match_result {
                home {
                    flag
                    name
                    goal
                }
                away {
                    flag
                    name
                    goal
                }
            }
            post_name
            match_date
            match_time
            match_highlight_url
        }
    }
`;
