/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

const TrainingImage: React.FC<{ filename: string; level: string; isMixedTraining?: boolean }> = ({
    filename,
    level,
    isMixedTraining,
}) => {
    filename += filename === 'juggle' ? '-' + level : '';
    filename += filename === 'pass' && isMixedTraining ? '-b' : '';
    return (
        <div
            css={css`
                transition: opacity 1s ease-in-out;
                background-repeat: no-repeat;
                height: 100%;
                background-size: contain;
                background-position-y: center;
                background-image: url('/assets/images/${filename}.jpg');
            `}
        />
    );
};
export default TrainingImage;
