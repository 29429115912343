/** @jsxImportSource @emotion/react */

import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle } from '@ionic/react';
import styled from '@emotion/styled';
import React from 'react';
import { css } from '@emotion/react';

import { SelectMonth } from '../../components';

import { MemberProgressChart, MemberRatings } from '.';

const MemberPerformance: React.FC = () => {
    return (
        <SlideItem>
            <IonCardHeader
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0.5rem 1rem;
                `}
            >
                <IonCardSubtitle
                    css={css`
                        color: var(--ion-yellow-color);
                        font-size: 16px;
                    `}
                >
                    PERFORMANCE
                </IonCardSubtitle>
                <IonCardSubtitle
                    css={css`
                        background: var(--ion-sliver-background-color);
                        border-radius: 8px;
                        padding-right: 6px;
                    `}
                >
                    <SelectMonth />
                </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <MemberRatings />
                <MemberProgressChart />
            </IonCardContent>
        </SlideItem>
    );
};

export default MemberPerformance;

export const SlideItem = styled(IonCard)`
    border: 1px solid var(--ion-border-card);
`;
