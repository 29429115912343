import { IonLoading } from '@ionic/react';
import qs from 'qs';
import React, { useEffect, useState } from 'react';

import { useMemberProfile } from '../../hooks/useMemberProfile';
import { useUser } from '../../hooks/useUser';
import { useMember } from '../Member';

import { useAuthContext } from './AuthProvider';

export const UserContext = React.createContext<User | null>(null);

export const UserProvider: React.FC = ({ children }) => {
    const { memberId } = qs.parse(window.location.search.substring(1));
    const [isAppReady, setAppReady] = useState(false);
    const { isAnonymous } = useAuthContext();
    const { profile, profileId, setProfileId, unsetProfile, setProfile } = useMember();

    const { data } = useUser();

    useMemberProfile({
        variables: { id: profileId! },
        skip: !profileId,
        onCompleted(res) {
            if (res?.member) setProfile(res.member);
        },
    });

    useEffect(() => {
        if (!data) return;

        const isOnboarded = data?.user.isOnboarded === true;
        const isCached = !!profileId;
        const isSameAccount = !!profileId && data?.user.profiles.map((profile) => profile._id).includes(profileId);

        if (!isCached || !isSameAccount) {
            setProfileId(data.user.mainProfileId);
        }

        if (isAnonymous) {
            setProfileId(memberId as string);
        }

        if (!isOnboarded) {
            unsetProfile();
        }
    }, [isAnonymous, memberId, profileId, setProfileId, unsetProfile, data]);

    useEffect(() => {
        if (!!profile || data?.user.isOnboarded === false) setAppReady(true);
    }, [data?.user.isOnboarded, profile]);

    if (!data || !isAppReady) {
        return <IonLoading isOpen />;
    }

    return <UserContext.Provider value={data.user}>{children}</UserContext.Provider>;
};
