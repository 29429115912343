/** @jsxImportSource @emotion/react */

import Storage from '@aws-amplify/storage';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router';

import { useOnScreen } from '../../hooks/useOnScreen';

interface TopLikedProps {
    videoUrl: string;
    rounded?: boolean;
}

const DemoVideo: React.FunctionComponent<TopLikedProps> = ({ videoUrl, rounded }) => {
    const history = useHistory();
    const [url, setUrl] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const [showControl, setShowControl] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const isVisible = useOnScreen(ref);

    const getVideoUrl = async () => {
        try {
            const url = await Storage.get(videoUrl);
            setUrl(url as string);
            setTimeout(() => {
                setShowControl(true);
                history.location.pathname !== '/watch' && setIsPlaying(true);
            }, 500);
        } catch (error) {}
    };

    useEffect(() => {
        if (!isVisible) {
            setIsPlaying(false);
        }
    }, [isVisible]);

    useEffect(() => {
        if (videoUrl) getVideoUrl();
    }, [videoUrl]);

    return (
        <Container ref={ref}>
            <ReactPlayer
                css={css`
                    width: 100% !important;
                    height: calc((100vw - 2rem) / 1920 * 1080) !important;
                    display: flex;
                    align-items: center;
                    video {
                        ${rounded ? 'border-radius: 8px;' : ''}
                    }
                `}
                url={url}
                playing={isVisible && isPlaying}
                controls={showControl && isVisible}
                playsinline
                onPlay={() => setIsPlaying(true)}
            />
        </Container>
    );
};

export default DemoVideo;

const Container = styled.div`
    height: calc((100vw - 2rem) / 1920 * 1080);
    position: relative;
`;
