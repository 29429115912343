/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import ReactPlayer from 'react-player';
import { playCircleOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

interface PlayBackVideoProps {
    colorVoice: HTMLAudioElement;
    playBackVideoUrl: string;
}

const PlayBackVideo: React.FunctionComponent<PlayBackVideoProps> = ({ colorVoice, playBackVideoUrl }) => {
    const [isPlayReactPlayer, setIsPlayReactPlayer] = useState(false);

    useEffect(() => {
        if (playBackVideoUrl) {
            if (!isPlayReactPlayer) {
                colorVoice.pause();
            } else {
                colorVoice.play();
            }
        }
    }, [isPlayReactPlayer]);

    return (
        <>
            <div
                css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 50%;
                    z-index: 10;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    ${!isPlayReactPlayer
                        ? 'background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));'
                        : ''}
                `}
                onClick={() => {
                    setIsPlayReactPlayer(!isPlayReactPlayer);
                }}
            >
                {!isPlayReactPlayer ? (
                    <IonIcon
                        css={css`
                            opacity: 0.5;
                            transform: scale(2);
                        `}
                        size="large"
                        icon={playCircleOutline}
                    />
                ) : null}
            </div>
            <ReactPlayer
                css={css`
                    height: 50% !important;
                    width: 100% !important;
                    background-color: black;
                `}
                url={playBackVideoUrl}
                playing={isPlayReactPlayer}
                onPause={() => setIsPlayReactPlayer(false)}
                onEnded={() => (colorVoice.currentTime = 0)}
            />
        </>
    );
};

export default PlayBackVideo;
