/** @jsxImportSource @emotion/react */

import { Toast } from '@capacitor/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonCard, IonIcon } from '@ionic/react';
import { lockClosed } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Storage from '@aws-amplify/storage';

import { useMember } from '../Member';

interface CatalogueProps {
    catalogue: Catalogue;
}
const Catalogue: React.FC<CatalogueProps> = ({ catalogue }) => {
    const history = useHistory();
    const { profile } = useMember();
    const isOnProgress = catalogue.members.length
        ? catalogue.members.find((userId) => userId.memberId === profile._id)
        : false;
    const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
    const [isLock, setIsLock] = useState(!isOnProgress);

    const handleClickCatalogue = () => {
        if (!isLock) {
            history.push({
                pathname: '/do-training',
                state: { catalogue: catalogue },
            });
        }
    };

    const getThumbnailUrl = async () => {
        try {
            const url = await Storage.get(catalogue.thumbnail);
            setThumbnailUrl(url as string);
        } catch (error) {}
    };

    useEffect(() => {
        if (profile.loyaltyPoint >= catalogue.privacyPoint) setIsLock(false);
        catalogue.thumbnail && getThumbnailUrl();
    }, []);

    return (
        <Card onClick={handleClickCatalogue}>
            <BackgroundImage
                css={css`
                    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${thumbnailUrl});
                    color: ${thumbnailUrl && `var(--ion-title-color)`};
                    background-size: cover;
                `}
            >
                {isLock && (
                    <div>
                        <IonIcon
                            icon={lockClosed}
                            css={css`
                                transform: scale(1.1);
                                font-size: 25px;
                                margin: 10px auto;
                            `}
                        />
                        <span>Need {catalogue.privacyPoint} points to unlock</span>
                    </div>
                )}
            </BackgroundImage>
            <StatusCatalogue
                css={css`
                    color: ${!isLock ? 'var(--ion-active-status-color)' : 'var(--ion-text-color)'};
                    border-bottom: 3px solid ${!isLock ? 'var(--ion-active-status-color)' : 'var(--ion-text-color)'};
                `}
            >
                <span>{catalogue.name}</span>
            </StatusCatalogue>
        </Card>
    );
};

const Card = styled(IonCard)`
    border: 1px solid var(--ion-border-card);
    width: 49%;
    margin: 0 0 15px 0;
`;

const BackgroundImage = styled.div`
    width: 100%;
    height: 35vw;
    object-fit: cover;
    position: relative;
    div {
        position: absolute;
        top: 20%;
        display: grid;
        text-align: center;
        margin: auto 10%;
    }
`;

const StatusCatalogue = styled.div`
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 16px;
        font-family: Montserrat-Bold;
        position: absolute;
        line-height: 18px;
        letter-spacing: 0.2px;
        margin: auto 10%;
    }
`;

export default Catalogue;
