import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Auth from '@aws-amplify/auth';
import { createUploadLink } from 'apollo-upload-client';
import qs from 'qs';

const baseUrl = process.env.REACT_APP_API_URL || '';

const httpLink = createHttpLink({
    uri: baseUrl + '/graphql',
});

const uploadLink: any = createUploadLink({
    uri: baseUrl + '/graphql',
});

const authLink = setContext(async () => {
    try {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { headers: { authorization: `Bearer ${token}` } };
    } catch (err) {
        const { nonce, accountId, memberId } = qs.parse(window.location.search.substring(1));

        if (nonce && memberId && accountId) {
            return {
                headers: {
                    'x-nonce': nonce,
                    'x-member-id': memberId,
                    'x-account-id': accountId,
                },
            };
        }
    }
});

export const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    member(_, { args, toReference }) {
                        return toReference({
                            __typename: 'Member',
                            id: args!.id,
                        });
                    },
                },
            },
        },
    }),
    link: authLink.concat(ApolloLink.from([uploadLink, httpLink])),
});
