import create from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
    profile: MemberProfile;
    profileId: string | null;
    setProfileId: (profileId: string) => void;
    setProfile: (profile: MemberProfile) => void;
    unsetProfile: () => void;
};

const useMember = create<State>(
    persist(
        (set) => ({
            // @ts-expect-error - Member is guranteed to be set in UserProvider
            profile: null,
            profileId: null,
            setProfileId: (profileId) => set({ profileId }),
            setProfile: (profile: MemberProfile) => set({ profile }),
            // @ts-expect-error - Member is guranteed to be set in UserProvider
            unsetProfile: () => set({ profile: null, profileId: null }),
        }),
        {
            name: 'yfa-member-profile',
        },
    ),
);

export default useMember;
