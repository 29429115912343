import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const UPLOAD_FILE = gql`
    mutation UploadFile($file: Upload!, $memberId: String!, $catalogueId: String!, $audioFilename: String!) {
        uploadFile(file: $file, memberId: $memberId, catalogueId: $catalogueId, audioFilename: $audioFilename)
    }
`;

type TData = { uploadFile: boolean };
type TVariables = { file: File | undefined; memberId: string; catalogueId: string; audioFilename: string };

export const useUploadFile = (options?: MutationHookOptions<TData, TVariables>) => {
    return useMutation<TData, TVariables>(UPLOAD_FILE, options);
};
