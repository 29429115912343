import React from 'react';

interface SpaceHorizontalProps {
    size?: string;
}

const SpaceHorizontal: React.FunctionComponent<SpaceHorizontalProps> = ({ size = '1rem', children }) => {
    return <div style={{ marginLeft: size, marginRight: size }}>{children}</div>;
};

export default SpaceHorizontal;
