import dayjs, { Dayjs } from 'dayjs';
import create from 'zustand';

type State = {
    date: Dayjs;
    change: (date: Dayjs) => void;
};

export const useCalendar = create<State>((set, get) => ({
    date: dayjs(),
    change: (date) => set({ date }),
}));
