/** @jsxImportSource @emotion/react */

import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IonContent, IonPage, IonSpinner } from '@ionic/react';

import Header from '../components/Header';
import Catalogue from '../features/TrainingCatalogue/Catalogue';
import Title from '../components/Title';
import { useCatalogues } from '../hooks/useCatalogues';

const TrainingCatalogue: React.FC = () => {
    const { data, loading, refetch } = useCatalogues();

    useEffect(() => {
        refetch();
    }, [location.pathname]);

    return (
        <IonPage>
            <Header />
            <Container>
                <Title title="Training Catalogue" />
                <FlexContainer>
                    {loading ? (
                        <div
                            css={css`
                                width: 100%;
                                text-align: center;
                            `}
                        >
                            <IonSpinner />
                        </div>
                    ) : (
                        data?.catalogues.map((catalogue, index) => <Catalogue key={index} catalogue={catalogue} />)
                    )}
                </FlexContainer>
            </Container>
        </IonPage>
    );
};

const Container = styled(IonContent)`
    padding: 20px;
    h3 {
        margin-left: 20px;
    }
`;

const FlexContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-between;
`;

export default TrainingCatalogue;
