/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';

import { Spacer } from '../components';
import Header from '../components/Header';
import Slider from '../components/Slider';
import Title from '../components/Title';
import { Banner, LeaguesTable, StatisticGameCard, UpcomingGameTimer } from '../features/Leagues';
import { ContentNameType, useContentByName } from '../hooks/useContentByName';
import { useMatchResults } from '../hooks/useMatchResults';
import { useMatchUpcoming } from '../hooks/useMatchUpcoming';

const Leagues: React.FunctionComponent = () => {
    const now = useRef(dayjs().format('YYYY-MM-DD HH:mm'));
    const { data } = useMatchResults(now.current.toString());
    const { data: upcomingGameData } = useMatchUpcoming(now.current.toString());
    const [showCompetition, setShowCompetition] = useState(false);

    useContentByName({
        variables: { name: ContentNameType.COMPETITION },
        onCompleted: (data) => {
            setShowCompetition(data?.contentByName?.description === 'activate');
        },
        onError: (error) => {
            console.log(error);
        },
    });

    return (
        <IonPage>
            <Header />
            <IonContent>
                <Spacer />
                <Slider />

                <ContentContainer>
                    {upcomingGameData?.matchInComing && <Title title="Next Game" />}
                    {upcomingGameData?.matchInComing && (
                        <UpcomingGameTimer
                            deadline={`${upcomingGameData?.matchInComing?.match_date} ${upcomingGameData?.matchInComing?.match_time}`}
                        />
                    )}

                    {upcomingGameData?.matchInComing && (
                        <StatisticGameCard match={upcomingGameData.matchInComing} isUpcoming />
                    )}

                    {data?.matchResults.length ? (
                        data?.matchResults.map((match, index) => (
                            <StatisticGameCard match={match} key={index} isUpcoming={false} />
                        ))
                    ) : (
                        <div
                            css={css`
                                width: 100%;
                                text-align: center;
                            `}
                        >
                            <IonSpinner />
                        </div>
                    )}

                    {/* league table */}
                    <Title title="League Table" />
                    <LeaguesTable />

                    {/* banner */}
                    {showCompetition ? <Banner /> : null}
                </ContentContainer>
            </IonContent>
        </IonPage>
    );
};

export default Leagues;

const ContentContainer = styled.div`
    margin: 1.5rem;
`;
