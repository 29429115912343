/** @jsxImportSource @emotion/react */

import { IonButton, IonCardContent, IonCardHeader, IonCardSubtitle, IonNote } from '@ionic/react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import React from 'react';

import { SelectMonth } from '../../components';
import { useMemberProfileRatings } from '../../hooks/useMemberProfileRatings';
import { useS3Object } from '../../hooks/useS3Object';

import { SlideItem } from './MemberPerformance';

import { useMember } from '.';

const MemberReports: React.FC = () => {
    const {
        profile: { _id: id },
    } = useMember();

    const { data } = useMemberProfileRatings(id, dayjs().year(), dayjs().month());

    const report = data?.member.ratings?.report;
    const signedURL = useS3Object(report);

    return (
        <SlideItem>
            <IonCardHeader
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0.5rem 1rem;
                    font-family: Open Sans;
                `}
            >
                <IonCardSubtitle
                    css={css`
                        color: var(--ion-yellow-color);
                        font-size: 16px;
                    `}
                >
                    Report
                </IonCardSubtitle>
                <IonCardSubtitle
                    css={css`
                        background: var(--ion-sliver-background-color);
                        border-radius: 8px;
                        padding-right: 6px;
                    `}
                >
                    <SelectMonth />
                </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                {report ? (
                    <>
                        <IonNote>{report}</IonNote>
                        <IonButton href={signedURL} target="_blank" expand="full">
                            Download Report
                        </IonButton>
                    </>
                ) : (
                    <em>No report for the month.</em>
                )}
            </IonCardContent>
        </SlideItem>
    );
};

export default MemberReports;
