/** @jsxImportSource @emotion/react */

import { Plugins } from '@capacitor/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonButton, IonChip, IonContent, IonIcon, IonLabel, IonModal } from '@ionic/react';
import { copy } from 'ionicons/icons';
import React, { useState } from 'react';

import SGQR from '../assets/2003302FA716-QRCODESGQR_20200330.png';
import { Upload } from '../components';
import { useCurrentUser } from '../hooks/useCurrentUser';
import useS3Upload from '../hooks/useS3Upload';
import { useSubmitPaymentApprovalRequest } from '../hooks/useSubmitPayment';
import { useMember } from '../features/Member';

import { useModal } from '.';

const { Toast, Clipboard } = Plugins;

const SGQR_UEN = '201911910N';

const PayViaPayNowModal: React.FC<{
    amount: number;
    approvalPrograms: ApprovalProgram[];
}> = ({ amount, approvalPrograms }) => {
    const user = useCurrentUser();
    const { profileId } = useMember();
    const { showPayWithSGQR, toggle } = useModal();
    const [isUploaded, setUploaded] = useState(false);
    const [submitPaymentApprovalRequest] = useSubmitPaymentApprovalRequest({
        onCompleted() {
            setUploaded(true);
        },
    });

    const handleUpload = useS3Upload({
        uid: profileId || user.mainProfileId,
        onCompleted(res) {
            submitPaymentApprovalRequest({
                variables: {
                    input: {
                        memberId: profileId || user.mainProfileId,
                        attachment: res,
                        currency: 'SGD',
                        amount,
                        programs: approvalPrograms,
                    },
                },
            });
        },
        onError(err) {
            Toast.show({ text: err.message });
        },
    });

    return (
        <Container
            isOpen={showPayWithSGQR}
            swipeToClose
            backdropDismiss
            onDidDismiss={() => {
                toggle('showPayWithSGQR', false);
                setUploaded(false);
            }}
        >
            <ContentContainer>
                <Content>
                    <h2>PAY VIA PAYNOW</h2>
                    <p>
                        Make payment via Paynow by scanning the code below. If you are unable to scan the code, you can
                        also make payment via our UEN number
                        <IonChip
                            color="primary"
                            onClick={() => {
                                Clipboard.write({ string: SGQR_UEN }).then(() => {
                                    Toast.show({ text: 'Copied.' });
                                });
                            }}
                        >
                            <IonLabel>201911910N</IonLabel>
                            <IonIcon icon={copy} />
                        </IonChip>
                        .
                    </p>

                    <p>
                        Once payment is complete, please upload a screenshot of your payment confirmation for
                        verification.
                    </p>

                    <Img src={SGQR} alt="SGQR" />
                </Content>

                <div
                    css={css`
                        text-align: center;
                    `}
                >
                    {isUploaded ? (
                        <IonChip color="success">
                            Thank you for your payment. Your balance sessions will be reflected in your account after
                            our staff have verified your payment.
                        </IonChip>
                    ) : (
                        <Upload accept="image/*" onChange={handleUpload}>
                            <IonButton>Upload Screenshot</IonButton>
                        </Upload>
                    )}
                </div>
            </ContentContainer>
        </Container>
    );
};

export default PayViaPayNowModal;

const Container = styled(IonModal)`
    ion-modal {
        color: var(--ion-text-color);
    }

    .modal-wrapper[role='dialog'] {
        max-height: 620px;
        border-radius: 20px 20px 0 0;
        margin-top: auto;
    }

    ion-button {
        width: 100%;
    }
`;

const ContentContainer = styled.div`
    padding: 1rem;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;

    ion-chip {
        height: inherit;
        line-height: 1.2em;
    }
`;

const Content = styled(IonContent)`
    --padding-start: 1rem;
    --padding-end: 1rem;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
`;

const Img = styled.img`
    height: 300px;
    object-fit: contain;
    min-width: 100%;
`;
