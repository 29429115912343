import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useField } from 'formik';
import React from 'react';

type Props = {
    fieldName?: string;
};

export const ExperiencePicker = ({ fieldName = 'experience' }) => {
    const [{ value }, , { setValue }] = useField(fieldName);
    return (
        <IonItem>
            <IonLabel position="floating">Years of Floorball Experience</IonLabel>
            <IonSelect value={value} onIonChange={(e) => setValue(e.detail.value)} placeholder="Select">
                <IonSelectOption>No experience</IonSelectOption>
                <IonSelectOption>1 - 2 Years</IonSelectOption>
                <IonSelectOption>More than 2 Years</IonSelectOption>
            </IonSelect>
        </IonItem>
    );
};

export default ExperiencePicker;
