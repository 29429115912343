import styled from '@emotion/styled/macro';

import logo from '../logo.png';

type LogoProps = {
    size?: number;
};

export const Logo = styled.img<LogoProps>`
    ${({ size }) => ({
        width: size,
        height: size,
    })}
`;

Logo.defaultProps = { src: logo, size: 48 };
