import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Spacer } from '../../components';
import Title from '../../components/Title';
import Video from '../../components/Video';
import { ContentNameType, useContentByName } from '../../hooks/useContentByName';

const LiveWatch: React.FC = () => {
    const [youtubeId, setYoutubeId] = useState<string>('');

    useContentByName({
        variables: { name: ContentNameType.VIDEO },
        onCompleted: (data) => {
            setYoutubeId(data?.contentByName?.description?.split('v=')[1]);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    return (
        <Container>
            <Title title="Live Game" />
            <Spacer />
            {youtubeId ? <Video rounded ytbVideoId={youtubeId} /> : <Error>No Live Video Game</Error>}
        </Container>
    );
};

export default LiveWatch;

const Container = styled.div`
    margin: 1.5rem;
`;

const Error = styled.div`
    border-radius: 8px;
    height: calc(100vw * 0.5);
    background-color: var(--ion-border-card);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat-Bold';
    font-size: 20px;
    color: black;
`;
