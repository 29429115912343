import Auth from '@aws-amplify/auth';
import styled from '@emotion/styled';
import { IonButton, IonContent, IonInput, IonItem, IonLoading, IonPage, IonText } from '@ionic/react';
import { useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import tw from 'twin.macro';

import Step from '../components/Step';

const useSignupHandler = () => {
    const handler = useCallback(async ({ username, password }, _) => {
        const attributes: Record<string, string> = {};

        // Simple check for whether user is signed up via email/phone_number
        if (username.startsWith('+')) {
            attributes.phone_number = username;
        } else {
            attributes.email = username;
        }
        return Auth.signUp({ username, password, attributes });
    }, []);
    return handler;
};

const useConfirmHandler = () => {
    const handler = useCallback(async ({ username, password, code }, _) => {
        await Auth.confirmSignUp(username, code);
        return Auth.signIn({ username, password });
    }, []);
    return handler;
};

const Signup = () => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const handleSignup = useSignupHandler();
    const handleConfirm = useConfirmHandler();

    const {
        isSubmitting,
        handleSubmit,
        handleChange,
        setFieldValue,
        setSubmitting,
        values: { username, password, message, code },
    } = useFormik({
        initialValues: {
            username: '',
            password: '',
            message: '',
            code: '',
        },
        async onSubmit(...args) {
            try {
                switch (currentStep) {
                    case 0:
                        await handleSignup(...args);
                        setCurrentStep((step) => step + 1);
                        setSubmitting(false);
                        break;
                    case 1:
                        await handleConfirm(...args);
                        window.location.href = '/';
                        break;
                }
            } catch (err) {
                setFieldValue('message', err.message);
            }
        },
    });

    const getStep = () => {
        switch (currentStep) {
            case 0:
                return (
                    <Step i={0}>
                        <FormContainer onSubmit={handleSubmit}>
                            <IonText>
                                <h1>Signup</h1>
                            </IonText>
                            {message && (
                                <Message>
                                    <IonText>{message}</IonText>
                                </Message>
                            )}
                            <IonItem>
                                <IonInput
                                    name="username"
                                    value={username}
                                    type="email"
                                    autofocus
                                    autocomplete={'email' as 'on'}
                                    placeholder="Email"
                                    inputMode="email"
                                    onIonChange={(e) => setFieldValue('username', e.detail.value)}
                                    onIonInput={handleChange as any}
                                />
                            </IonItem>
                            <IonItem>
                                <IonInput
                                    name="password"
                                    value={password}
                                    placeholder="Password"
                                    type="password"
                                    autocomplete={'password' as 'on'}
                                    onIonChange={(e) => setFieldValue('password', e.detail.value)}
                                    onIonInput={handleChange as any}
                                />
                            </IonItem>
                            <div>
                                <IonButton color="light" shape="round" fill="solid" type="submit">
                                    Sign up
                                </IonButton>
                                <IonButton
                                    color="light"
                                    fill="clear"
                                    shape="round"
                                    routerLink="/login"
                                    routerDirection="back"
                                >
                                    <p>Already have an account? Log in</p>
                                </IonButton>
                            </div>
                        </FormContainer>
                    </Step>
                );

            case 1:
                return (
                    <Step i={1}>
                        <FormContainer onSubmit={handleSubmit}>
                            <IonText>
                                <h1>Confirm Signup</h1>
                                <p>Please enter the verification code sent to you at {username}</p>
                            </IonText>
                            {message && (
                                <Message>
                                    <IonText>{message}</IonText>
                                </Message>
                            )}
                            <IonItem>
                                <IonInput
                                    autofocus
                                    name="code"
                                    value={code}
                                    inputMode="numeric"
                                    placeholder="Verification Code"
                                    onIonChange={(e) => setFieldValue('code', e.detail.value)}
                                />
                            </IonItem>
                            <div>
                                <IonButton color="light" shape="round" fill="solid" type="submit">
                                    Confirm
                                </IonButton>
                            </div>
                        </FormContainer>
                    </Step>
                );
            default:
                return null;
        }
    };

    return (
        <PageContainer>
            <IonContent>
                <IonLoading isOpen={isSubmitting} />
                <Container>
                    <AnimatePresence>{getStep()}</AnimatePresence>
                </Container>
            </IonContent>
        </PageContainer>
    );
};

export default Signup;

const PageContainer = styled(IonPage)`
    ion-content {
        --background: var(--ion-color-primary);
        --color: var(--ion-color-primary-contrast);
    }
`;

const Container = styled.div`
    ${tw`relative max-w-sm mx-auto sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl`}
`;

/**
 * @deprecated
 */
const FormContainer = styled.form`
    ${tw`flex flex-col items-stretch m-12`}

    ion-item {
        ${tw`mb-4`}

        --background: var(--ion-color-primary);
        --border-color: var(--ion-color-primary-contrast);
        --color: var(--ion-color-primary-contrast);
        --highlight-color-focused: var(--ion-color-primary-contrast);

        font-size: smaller;

        &:first-of-type {
            ${tw`mt-8`}
        }

        &:last-of-type {
            ${tw`mb-16`}
        }
    }

    ion-text {
        ${tw`relative overflow-hidden`}
    }

    /* Buttons */
    & > div:last-of-type {
        ${tw`text-center`}

        ion-button {
            ${tw`mr-4`}
            font-size: smaller;
            min-width: 10rem;

            &.ion-color-light {
                --ion-color-contrast: var(--ion-color-primary) !important;
                --ion-color-contrast-rgb: var(--ion-color-primary-rgb) !important;
            }

            &.button-clear {
                text-transform: unset;
            }
        }
    }
`;

const Message = styled.div`
    ${tw`p-4 m-4`}
    font-size: smaller;
    background: var(--ion-color-primary-contrast);
    color: var(--ion-color-primary);
    border-radius: 0.5rem;
`;
