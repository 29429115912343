/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonCol, IonLabel, IonRadio, IonRadioGroup, IonRow } from '@ionic/react';
import React from 'react';

import PasswordInput from '../../components/inputs/PasswordInput';

import { DatetimeFormControl, FormInput, PhoneFormControl, RadioFormControl, SubmitButton } from './styled';

import { FormProps } from '.';

const SignUpForm: React.FunctionComponent<FormProps> = ({ setFieldValue, handleSubmit, values }) => (
    <>
        <h5
            css={css`
                margin-bottom: 0;
            `}
        >
            Create new account
        </h5>
        <small>Fill out the form below to create a new account</small>
        <PhoneFormControl>
            <p
                css={css`
                    margin-right: 0 !important;
                `}
            >
                +
            </p>
            <FormInput
                placeholder="Mobile number"
                name="phoneNumber"
                value={values.phoneNumber}
                onIonChange={(e) => setFieldValue('phoneNumber', e.detail.value!)}
                type="tel"
            />
        </PhoneFormControl>
        <FormInput
            name="email"
            placeholder="Email"
            type="email"
            value={values.email}
            onIonChange={(e) => setFieldValue('email', e.detail.value!)}
        />
        <IonRow>
            <IonCol
                css={css`
                    padding-left: 0;
                `}
            >
                <FormInput
                    placeholder="First name"
                    name="firstName"
                    value={values.firstName}
                    onIonChange={(e) => setFieldValue('firstName', e.detail.value!)}
                    type="text"
                />
            </IonCol>
            <IonCol
                css={css`
                    padding-right: 0;
                `}
            >
                <FormInput
                    placeholder="Last name"
                    name="lastName"
                    value={values.lastName}
                    onIonChange={(e) => setFieldValue('lastName', e.detail.value!)}
                    type="text"
                />
            </IonCol>
        </IonRow>
        <IonCol
            css={css`
                padding-right: 0;
            `}
        ></IonCol>
        <IonRow
            css={css`
                ion-col {
                    padding-left: 0;
                }
            `}
        >
            <IonCol sizeXs="6" sizeSm="6">
                <DatetimeFormControl
                    placeholder="DOB (DD/MM/YYYY)"
                    displayFormat="DD/MM/YYYY"
                    name="birthdate"
                    value={values.birthdate}
                    onIonChange={(e) => setFieldValue('birthdate', e.detail.value!)}
                />
            </IonCol>
            <IonCol sizeXs="6" sizeSm="6">
                <IonRadioGroup
                    name="gender"
                    value={values.gender}
                    onIonChange={(e) => setFieldValue('gender', e.detail.value)}
                    css={css`
                        padding: 0;
                    `}
                >
                    <IonRow>
                        <IonCol sizeXs="6" sizeSm="6">
                            <RadioFormControl>
                                <IonLabel>Male</IonLabel>
                                <IonRadio slot="start" value="MALE" />
                            </RadioFormControl>
                        </IonCol>
                        <IonCol sizeXs="6" sizeSm="6">
                            <RadioFormControl>
                                <IonLabel>Female</IonLabel>
                                <IonRadio slot="start" value="FEMALE" />
                            </RadioFormControl>
                        </IonCol>
                    </IonRow>
                </IonRadioGroup>
            </IonCol>
        </IonRow>
        <PasswordInput
            placeholder="Password"
            name="password"
            value={values.password}
            onIonChange={(e) => setFieldValue('password', e.detail.value!)}
        />
        <PasswordInput
            name="retypePassword"
            placeholder="Retype password"
            value={values.retypePassword}
            onIonChange={(e) => setFieldValue('retypePassword', e.detail.value!)}
        />

        <SubmitButton
            css={css`
                width: 100%;
            `}
            onClick={() => handleSubmit()}
            color="danger"
        >
            Create account
        </SubmitButton>
    </>
);

export default SignUpForm;
