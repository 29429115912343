import { Plugins } from '@capacitor/core';
import styled from '@emotion/styled';
import { IonButton, IonChip, IonModal } from '@ionic/react';
import React, { useState } from 'react';

import { Upload } from '../components';
import { useCurrentUser } from '../hooks/useCurrentUser';
import useS3Upload from '../hooks/useS3Upload';
import { useSubmitPaymentApprovalRequest } from '../hooks/useSubmitPayment';
import { useMember } from '../features/Member';

import { useModal } from '.';

const { Toast } = Plugins;

const PayWithBankTransferModal: React.FC<{
    amount: number;
    approvalPrograms: ApprovalProgram[];
}> = ({ amount, approvalPrograms }) => {
    const user = useCurrentUser();
    const { profileId } = useMember();
    const { showPayWithBankTransfer, toggle } = useModal();

    const [isUploaded, setUploaded] = useState(false);
    const [submitPaymentApprovalRequest] = useSubmitPaymentApprovalRequest({
        onCompleted() {
            setUploaded(true);
        },
    });

    const handleUpload = useS3Upload({
        uid: profileId || user.mainProfileId,
        onCompleted(res) {
            submitPaymentApprovalRequest({
                variables: {
                    input: {
                        memberId: profileId || user.mainProfileId,
                        attachment: res,
                        currency: 'SGD',
                        amount,
                        programs: approvalPrograms,
                    },
                },
            });
        },
        onError(err) {
            Toast.show({ text: err.message });
        },
    });

    return (
        <Container
            isOpen={showPayWithBankTransfer}
            swipeToClose
            backdropDismiss
            onDidDismiss={() => {
                toggle('showPayWithBankTransfer', false);
                setUploaded(false);
            }}
        >
            <ContentContainer>
                <Content>
                    <h2>Pay via Bank Transfer</h2>
                    <p>
                        Please refer to the below details if you would like to make payment via bank transfer. Once
                        completed, please upload a screenshot of the payment confirmation for verification.
                    </p>

                    <p>
                        <strong>DBS Current Account 072-009784-0</strong>
                    </p>

                    <DisplayField>
                        <label htmlFor="accountName">Account Name:</label>
                        <div>Youth Floorball Academy Pte. Ltd.</div>
                    </DisplayField>
                </Content>

                {isUploaded ? (
                    <IonChip color="success">
                        Thank you for your payment. Your balance sessions will be reflected in your account after our
                        staff have verified your payment.
                    </IonChip>
                ) : (
                    <Upload accept="image/*" onChange={handleUpload}>
                        <IonButton>Upload Screenshot</IonButton>
                    </Upload>
                )}
            </ContentContainer>
        </Container>
    );
};

export default PayWithBankTransferModal;

const Container = styled(IonModal)`
    color: var(--ion-text-color);

    .modal-wrapper[role='dialog'] {
        height: 500px;
        border-radius: 20px 20px 0 0;
        margin-top: auto;
    }

    ion-button {
        width: 100%;
    }
`;

const ContentContainer = styled.div`
    height: 100%;
    padding: 1rem;
    display: grid;
    grid-template-rows: 1fr auto;

    ion-chip {
        height: inherit;
        line-height: 1.2em;
    }
`;

const Content = styled.div`
    padding: 1rem;

    h2 {
        text-transform: uppercase;
    }
`;

const DisplayField = styled.div`
    margin-bottom: 1rem;

    label {
        font-weight: 500;
    }
`;
