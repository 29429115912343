/** @jsxImportSource @emotion/react */

import { IonIcon } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { eye, eyeOff } from 'ionicons/icons';

import { FormInput } from '../../features/Auth/styled';

interface PasswordInputProps {
    placeholder: string;
    name: string;
    value: string;
    onIonChange: ((event: CustomEvent<any>) => void) | undefined;
}

export enum PasswordInputType {
    TEXT = 'text',
    PASSWORD = 'password',
}

const PasswordInput: React.FunctionComponent<PasswordInputProps> = ({ placeholder, name, value, onIonChange }) => {
    const [type, setType] = useState<PasswordInputType>(PasswordInputType.PASSWORD);

    const handleChangeType = () => {
        if (type === PasswordInputType.PASSWORD) {
            setType(PasswordInputType.TEXT);
        } else setType(PasswordInputType.PASSWORD);
    };

    useEffect(() => {
        if (!value.length) setType(PasswordInputType.PASSWORD);
    }, [value]);

    return (
        <div
            css={css`
                position: relative;
            `}
        >
            <FormInput
                css={css`
                    padding-right: 40px !important;
                    input {
                        border-top-right-radius: 0px !important;
                        border-bottom-right-radius: 0px !important;
                    }
                `}
                type={type}
                placeholder={placeholder}
                name={name}
                value={value}
                onIonChange={onIonChange}
            />
            <ToggleButton onClick={handleChangeType}>
                <IonIcon slot="icon-only" icon={type === PasswordInputType.PASSWORD ? eye : eyeOff} />
            </ToggleButton>
        </div>
    );
};

export default PasswordInput;

const ToggleButton = styled.span`
    position: absolute;
    top: 8px;
    right: 10px;
    ion-icon {
        padding: 0;
        font-size: 19px;
        color: #606c72;
    }
    z-index: 2;
`;
