export const BEGINNER_LEVEL =
    'yellow green x x x green yellow x x x yellow green x x x green yellow green yellow x x x yellow green yellow green x x x green yellow x x x yellow green x x x green yellow green yellow x x x green yellow green yellow x x x green yellow x x x green yellow x x x yellow green x x x yellow green yellow green x x x green yellow green yellow green yellow x x x yellow green x x x';

export enum Dribbling {
    BEGINNER = 'yellow green x x x green yellow x x x yellow green x x x green yellow green yellow x x x ' +
        'yellow green yellow green x x x green yellow x x x yellow green x x x green yellow green yellow ' +
        'x x x green yellow green yellow x x x green yellow x x x yellow green x x x ' +
        'yellow green yellow green x x x green yellow green yellow green yellow x x x yellow green x x x',
    INTERMEDIATE = 'blue green red x x x red blue green x x x green red blue x x x red green blue x x x green red blue green red blue x x x blue green red blue green red x x x red blue green x x x green blue red x x x blue red green x x x red green blue x x x green blue red x x x red green blue red green blue x x x green red blue x x x blue green red blue green red x x x blue green red x x x red blue green red blue green x x x green red blue x x x blue red green',
    ADVANCED = 'yellow red blue green x x x red yellow green blue x x x green red yellow blue x x x blue green yellow red x x x yellow red blue green yellow red blue green x x x green red yellow blue green red yellow blue x x x red yellow green blue x x x green red yellow blue x x x yellow red blue green x x x blue green yellow red x x x red yellow green blue red yellow green blue x x x green red yellow blue green red yellow blue x x x red yellow green blue x x x yellow red blue green x x x blue green yellow red x x x green red yellow blue x x x blue green yellow red blue green yellow red x x x red yellow green blue red yellow green blue x x x',
}

export enum Passing {
    // BEGINNER = 'pass pass pass receive receive receive pass pass pass receive receive receive pass pass pass receive receive receive pass pass pass receive receive receive pass pass pass receive receive receive pass pass receive receive pass pass receive receive pass pass receive receive pass pass receive receive pass pass receive receive pass pass receive receive pass pass receive receive pass pass receive receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass receive pass pass pass receive receive receive pass pass pass receive receive receive pass pass pass receive',
    // INTERMEDIATE = 'pass pass pass receive receive receive red-left red-left red-left pass pass pass receive receive receive green-right green-right green-right ' +
    //     'pass pass pass receive receive receive red-left red-left red-left pass pass pass receive receive receive green-right green-right green-right ' +
    //     'pass pass receive receive red-left red-left pass pass receive receive green-right green-right pass pass receive receive red-left red-left ' +
    //     'pass pass receive receive green-right green-right pass pass receive receive red-left red-left pass pass receive receive green-right green-right ' +
    //     'pass pass receive receive red-left red-left pass pass receive receive green-right green-right pass pass receive receive red-left red-left ' +
    //     'pass pass pass receive receive receive green-right green-right green-right pass pass pass receive receive receive red-left red-left red-left ' +
    //     'pass pass receive receive green-right green-right pass pass receive receive red-left red-left pass pass receive receive green-right green-right ' +
    //     'pass pass receive receive red-left red-left pass pass receive receive green-right green-right pass pass receive receive red-left red-left',
    // ADVANCED = 'pass pass receive receive green-right green-right pass pass receive receive yellow-left yellow-left pass pass receive receive red-left red-left ' +
    //     'pass pass receive receive yellow-right yellow-right pass pass receive receive green-right green-right pass pass receive receive yellow-left yellow-left ' +
    //     'pass pass receive receive red-left red-left pass pass receive receive yellow-right yellow-right pass pass receive receive green-right green-right ' +
    //     'pass pass receive receive yellow-left yellow-left pass pass receive receive red-left red-left pass pass receive receive yellow-right yellow-right ' +
    //     'pass pass receive receive green-right green-right pass pass receive receive red-left red-left pass pass receive receive yellow-right yellow-right ' +
    //     'pass pass receive receive green-right green-right pass pass receive receive yellow-left yellow-left pass pass receive receive red-left red-left ' +
    //     'pass pass receive receive green-right green-right pass pass receive receive red-left red-left pass pass receive receive yellow-right yellow-right ' +
    //     'pass pass receive receive green-right green-right pass pass receive receive yellow-left yellow-left pass pass receive receive red-left red-left pass pass receive receive yellow-right yellow-right',
    BEGINNER = 'forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow backhand-pass-red ' +
        'backhand-pass-red forehand-pass-red forehand-pass-red backhand-pass-yellow backhand-pass-yellow backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red backhand-pass-yellow backhand-pass-yellow forehand-pass-red forehand-pass-red ' +
        'forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red forehand-pass-red forehand-pass-red forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red backhand-pass-yellow backhand-pass-yellow forehand-pass-red forehand-pass-red backhand-pass-yellow ' +
        'backhand-pass-yellow forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red backhand-pass-yellow backhand-pass-yellow ' +
        'forehand-pass-red forehand-pass-red backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow backhand-pass-yellow backhand-pass-yellow forehand-pass-red forehand-pass-red backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow backhand-pass-yellow ' +
        'backhand-pass-yellow forehand-pass-red forehand-pass-red backhand-pass-yellow backhand-pass-yellow forehand-pass-red forehand-pass-red backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow forehand-pass-red forehand-pass-red backhand-pass-red backhand-pass-red ' +
        'forehand-pass-yellow forehand-pass-yellow forehand-pass-red forehand-pass-red backhand-pass-yellow backhand-pass-yellow backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red forehand-pass-red forehand-pass-red backhand-pass-yellow ' +
        'backhand-pass-yellow forehand-pass-red forehand-pass-red forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow forehand-pass-red forehand-pass-red backhand-pass-yellow backhand-pass-yellow',
    INTERMEDIATE = 'forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow forehand-pass-red forehand-pass-red forehand-pass-red backhand-pass-red backhand-pass-red backhand-pass-red backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow ' +
        'backhand-pass-red backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow forehand-pass-red forehand-pass-red forehand-pass-red backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow forehand-pass-red forehand-pass-red forehand-pass-red ' +
        'forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow forehand-pass-red forehand-pass-red forehand-pass-red backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow backhand-pass-red backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow ' +
        'backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow forehand-pass-red forehand-pass-red forehand-pass-red backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow backhand-pass-red backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow ' +
        'forehand-pass-red forehand-pass-red forehand-pass-red backhand-pass-red backhand-pass-red backhand-pass-red backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow forehand-pass-red forehand-pass-red forehand-pass-red forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow ' +
        'backhand-pass-red backhand-pass-red backhand-pass-red forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow forehand-pass-red forehand-pass-red forehand-pass-red backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow backhand-pass-red backhand-pass-red backhand-pass-red ' +
        'forehand-pass-red forehand-pass-red forehand-pass-red backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red backhand-pass-red backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow',
    ADVANCED = 'forehand-pass-green forehand-pass-green forehand-pass-green forehand-pass-red forehand-pass-red forehand-pass-red forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red backhand-pass-red forehand-pass-green forehand-pass-green forehand-pass-green ' +
        'backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow forehand-pass-red forehand-pass-red forehand-pass-red backhand-pass-green backhand-pass-green backhand-pass-green forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow backhand-pass-red backhand-pass-red backhand-pass-red ' +
        'forehand-pass-green forehand-pass-green forehand-pass-green forehand-pass-red forehand-pass-red forehand-pass-red forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow forehand-pass-green forehand-pass-green forehand-pass-green ' +
        'backhand-pass-red backhand-pass-red backhand-pass-red forehand-pass-red forehand-pass-red forehand-pass-red backhand-pass-green backhand-pass-green backhand-pass-green forehand-pass-red forehand-pass-red forehand-pass-red backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow ' +
        'forehand-pass-green forehand-pass-green forehand-pass-green forehand-pass-red forehand-pass-red forehand-pass-red backhand-pass-green backhand-pass-green backhand-pass-green forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow forehand-pass-green forehand-pass-green forehand-pass-green ' +
        'backhand-pass-green backhand-pass-green backhand-pass-green forehand-pass-red forehand-pass-red forehand-pass-red forehand-pass-green forehand-pass-green forehand-pass-green backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow forehand-pass-red forehand-pass-red forehand-pass-red ' +
        'forehand-pass-green forehand-pass-green forehand-pass-green forehand-pass-red forehand-pass-red forehand-pass-red backhand-pass-green backhand-pass-green backhand-pass-green backhand-pass-red backhand-pass-red backhand-pass-red forehand-pass-red forehand-pass-red forehand-pass-red ' +
        'backhand-pass-yellow backhand-pass-yellow backhand-pass-yellow forehand-pass-red forehand-pass-red forehand-pass-red forehand-pass-yellow forehand-pass-yellow forehand-pass-yellow backhand-pass-green backhand-pass-green backhand-pass-green forehand-pass-red forehand-pass-red forehand-pass-red',
}

export enum Goalie {
    // BEGINNER = 'green-right green-right green-right green-right green-right red-down red-down red-down red-down red-down yellow-left yellow-left yellow-left yellow-left yellow-left blue-up blue-up blue-up blue-up blue-up ' +
    //     'juggle 10 9 8 7 6 5 4 3 2 1 yellow-down yellow-down yellow-down yellow-down yellow-down red-right red-right red-right red-right ' +
    //     'red-right green-up green-up green-up green-up green-up blue-left blue-left blue-left blue-left blue-left juggle 10 9 8 7 6 5 4 3 ' +
    //     '2 1 green-right green-right green-right green-right green-right red-down red-down red-down red-down red-down yellow-left yellow-left yellow-left yellow-left yellow-left blue-up blue-up blue-up ' +
    //     'juggle 10 9 8 7 6 5 4 3 2 1 yellow-down yellow-down yellow-down yellow-down yellow-down red-right red-right red-right red-right ' +
    //     'red-right green-up green-up green-up green-up green-up blue-left blue-left blue-left blue-left blue-left juggle 10 9 8 7 6 5 4 3 ' +
    //     'green-right green-right green-right green-right green-right red-down red-down red-down red-down red-down yellow-left yellow-left yellow-left yellow-left yellow-left blue-up blue-up blue-up blue-up blue-up ' +
    //     'juggle 10 9 8 7 6 5 4 3 2 1 yellow-down yellow-down yellow-down yellow-down yellow-down red-right red-right red-right red-right ' +
    //     'red-right green-up green-up green-up green-up green-up blue-left blue-left blue-left blue-left blue-left juggle 10 9 8 7 6 5 4 3',
    // INTERMEDIATE = 'red-down-right red-down-right red-down-right green-up green-up green-up yellow-down-left yellow-down-left yellow-down-left blue-up blue-up blue-up juggle 10 9 8 7 6 5 4 ' +
    //     '3 2 1 red-down-right red-down-right red-down-right green-up green-up green-up yellow-down-left yellow-down-left yellow-down-left blue-up blue-up blue-up juggle 10 9 8 7 ' +
    //     '6 5 4 3 2 1 red-down-right red-down-right red-down-right green-up green-up green-up yellow-down-left yellow-down-left yellow-down-left blue-up blue-up blue-up juggle 10 ' +
    //     '9 8 7 6 5 4 3 2 1 red-down-right red-down-right red-down-right green-up green-up green-up yellow-down-left yellow-down-left yellow-down-left blue-up blue-up ' +
    //     'blue-up juggle 10 9 8 7 6 5 4 3 2 1 red-down-right red-down-right red-down-right green-up green-up green-up yellow-down-left yellow-down-left ' +
    //     'blue-up blue-up blue-up juggle 10 9 8 7 6 5 4 3 2 1 red-down-right red-down-right red-down-right green-up green-up green-up ' +
    //     'yellow-down-left yellow-down-left yellow-down-left blue-up blue-up blue-up juggle 10 9 8 7 6 5 4 3 2 1',
    // ADVANCED = 'red-down-right red-down-right red-down-right green-up green-up green-up yellow-down-left yellow-down-left yellow-down-left jump-forward jump-forward jump-forward jump-forward jump-forward juggle 10 9 8 7 6 ' +
    //     '5 4 3 2 1 red-down-right red-down-right red-down-right green-up green-up green-up yellow-down-left yellow-down-left yellow-down-left jump-forward jump-forward jump-forward jump-forward jump-forward juggle ' +
    //     '10 9 8 7 6 5 4 3 2 1 red-down-right red-down-right red-down-right green-up green-up green-up yellow-down-left yellow-down-left yellow-down-left jump-forward ' +
    //     'jump-forward jump-forward jump-forward jump-forward juggle 10 9 8 7 6 5 4 3 2 1 red-down-right red-down-right red-down-right green-up green-up ' +
    //     'green-up yellow-down-left yellow-down-left yellow-down-left jump-forward jump-forward jump-forward jump-forward jump-forward juggle 10 9 8 7 6 5 4 3 2 1 r' +
    //     'ed-down-right red-down-right red-down-right green-up green-up green-up yellow-down-left yellow-down-left yellow-down-left jump-forward jump-forward jump-forward jump-forward jump-forward juggle 10 9 8 7 6 ' +
    //     '5 4 3 2 1 red-down-right red-down-right red-down-right green-up green-up green-up yellow-down-left yellow-down-left yellow-down-left jump-forward jump-forward jump-forward jump-forward jump-forward juggle ' +
    //     '10 9 8 7 6 5 4 3 2 1',
    BEGINNER = 'shuffle-green shuffle-green shuffle-green shuffle-red shuffle-red shuffle-red shuffle-yellow shuffle-yellow shuffle-yellow shuffle-blue shuffle-blue shuffle-blue ' +
        'juggle 10 9 8 7 6 5 4 3 2 1 shuffle-yellow shuffle-yellow shuffle-yellow shuffle-red shuffle-red shuffle-red ' +
        'shuffle-green shuffle-green shuffle-green shuffle-blue shuffle-blue shuffle-blue juggle 10 9 8 7 6 5 4 3 ' +
        '2 1 shuffle-green shuffle-green shuffle-green shuffle-red shuffle-red shuffle-red shuffle-yellow shuffle-yellow shuffle-yellow shuffle-blue shuffle-blue shuffle-blue ' +
        'juggle 10 9 8 7 6 5 4 3 2 1 shuffle-yellow shuffle-yellow shuffle-yellow shuffle-red shuffle-red shuffle-red ' +
        'shuffle-green shuffle-green shuffle-green shuffle-blue shuffle-blue shuffle-blue juggle 10 9 8 7 6 5 4 3 ' +
        'shuffle-green shuffle-green shuffle-green shuffle-red shuffle-red shuffle-red shuffle-yellow shuffle-yellow shuffle-yellow shuffle-blue shuffle-blue shuffle-blue ' +
        'juggle 10 9 8 7 6 5 4 3 2 1 shuffle-yellow shuffle-yellow shuffle-yellow shuffle-red shuffle-red shuffle-red ' +
        'shuffle-green shuffle-green shuffle-green shuffle-blue shuffle-blue shuffle-blue juggle 10 9 8 7 6 5 4 3',
    INTERMEDIATE = 'diagonal-red diagonal-red diagonal-red green-up green-up green-up diagonal-yellow diagonal-yellow diagonal-yellow blue-up blue-up blue-up juggle 10 9 8 7 6 5 4 ' +
        '3 2 1 diagonal-red diagonal-red diagonal-red green-up green-up green-up diagonal-yellow diagonal-yellow diagonal-yellow blue-up blue-up blue-up juggle 10 9 8 7 ' +
        '6 5 4 3 2 1 diagonal-red diagonal-red diagonal-red green-up green-up green-up diagonal-yellow diagonal-yellow diagonal-yellow blue-up blue-up blue-up juggle 10 ' +
        '9 8 7 6 5 4 3 2 1 diagonal-red diagonal-red diagonal-red green-up green-up green-up diagonal-yellow diagonal-yellow diagonal-yellow blue-up blue-up ' +
        'blue-up juggle 10 9 8 7 6 5 4 3 2 1 diagonal-red diagonal-red diagonal-red green-up green-up green-up diagonal-yellow diagonal-yellow ' +
        'blue-up blue-up blue-up juggle 10 9 8 7 6 5 4 3 2 1 diagonal-red diagonal-red diagonal-red green-up green-up green-up ' +
        'diagonal-yellow diagonal-yellow diagonal-yellow blue-up blue-up blue-up juggle 10 9 8 7 6 5 4 3 2 1',
    ADVANCED = 'diagonal-red diagonal-red diagonal-red green-up green-up green-up diagonal-yellow diagonal-yellow diagonal-yellow jump-forward jump-forward jump-forward juggle 10 9 8 7 6 ' +
        '5 4 3 2 1 diagonal-red diagonal-red diagonal-red green-up green-up green-up diagonal-yellow diagonal-yellow diagonal-yellow jump-forward jump-forward jump-forward juggle ' +
        '10 9 8 7 6 5 4 3 2 1 diagonal-red diagonal-red diagonal-red green-up green-up green-up diagonal-yellow diagonal-yellow diagonal-yellow jump-forward ' +
        'jump-forward jump-forward juggle 10 9 8 7 6 5 4 3 2 1 diagonal-red diagonal-red diagonal-red green-up green-up ' +
        'green-up diagonal-yellow diagonal-yellow diagonal-yellow jump-forward jump-forward jump-forward juggle 10 9 8 7 6 5 4 3 2 1 ' +
        'diagonal-red diagonal-red diagonal-red green-up green-up green-up diagonal-yellow diagonal-yellow diagonal-yellow jump-forward jump-forward jump-forward juggle 10 9 8 7 6 ' +
        '5 4 3 2 1 diagonal-red diagonal-red diagonal-red green-up green-up green-up diagonal-yellow diagonal-yellow diagonal-yellow jump-forward jump-forward jump-forward juggle ' +
        '10 9 8 7 6 5 4 3 2 1',
}

export enum HookDrill {
    BEGINNER = 'left-right left-right left-right blue-1 blue-1 blue-1 left-right left-right left-right green-1 green-1 green-1 left-right left-right left-right blue-2 blue-2 blue-2 left-right left-right ' +
        'green-2 green-2 green-2 left-right left-right green-1 green-1 green-1 left-right left-right blue-2 blue-2 blue-2 left-right left-right blue-1 blue-1 blue-1 left-right left-right ' +
        'left-right left-right green-2 green-2 green-2 left-right left-right blue-2 blue-2 blue-2 left-right left-right green-1 green-1 green-1 left-right left-right blue-2 blue-2 blue-2 ' +
        '2-cones left-right left-right blue-1 blue-1 blue-1 green-2 green-2 green-2 left-right left-right left-right green-1 green-1 green-1 blue-2 blue-2 blue-2 left-right left-right ' +
        'green-2 green-2 green-2 blue-1 blue-1 blue-1 left-right left-right blue-2 blue-2 blue-2 green-1 green-1 green-1 left-right left-right left-right blue-1 blue-1 blue-1 ' +
        'green-2 green-2 green-2 blue-1 blue-1 blue-1 3-cones left-right left-right green-1 green-1 green-1 blue-2 blue-2 blue-2 green-1 green-1 green-1 left-right left-right ' +
        'blue-2 blue-2 blue-2 green-1 green-1 green-1 blue-2 blue-2 blue-2 left-right left-right blue-1 blue-1 blue-1 blue-2 blue-2 blue-2 green-1 green-1 green-1 ' +
        '4-cones left-right left-right green-1 green-1 green-1 blue-1 blue-1 blue-1 green-2 green-2 green-2 blue-2 blue-2 blue-2 left-right left-right left-right green-2 green-2 ' +
        'green-2 blue-1 blue-1 blue-1 green-2 green-2 green-2 green-1 green-1 green-1 left-right left-right left-right blue-1 blue-1 blue-1 green-2 green-2 green-2 green-1 ' +
        'green-1 green-1 blue-2 blue-2 blue-2 left-right left-right left-right',
    INTERMEDIATE = 'left-right red-2 red-2 left-right green-1 green-1 blue-1 blue-1 left-right red-1 red-1 left-right green-2 green-2 left-right blue-2 blue-2 2-cones left-right ' +
        'red-2 red-2 red-2 green-1 green-1 green-1 left-right left-right blue-1 blue-1 blue-1 green-2 green-2 green-2 left-right left-right blue-2 blue-2 blue-2 red-1 ' +
        'red-1 red-1 left-right left-right green-1 green-1 green-1 red-1 red-1 red-1 left-right left-right green-1 green-1 green-1 blue-2 blue-2 blue-2 left-right left-right ' +
        '3-cones left-right left-right green-1 green-1 green-1 blue-2 blue-2 blue-2 red-1 red-1 red-1 left-right left-right blue-1 blue-1 blue-1 red-2 red-2 red-2 ' +
        'red-1 red-1 red-1 left-right left-right red-1 red-1 red-1 blue-2 blue-2 blue-2 green-1 green-1 green-1 4-cones left-right left-right blue-1 blue-1 blue-1 ' +
        'green-2 green-2 green-2 green-1 green-1 green-1 blue-2 blue-2 blue-2 left-right left-right left-right blue-1 blue-1 blue-1 red-2 red-2 red-2 red-1 red-1 ' +
        'red-1 blue-2 blue-2 blue-2 left-right left-right left-right red-1 red-1 red-1 green-1 green-1 green-1 green-2 green-2 green-2 red-2 red-2 red-2 left-right ' +
        'left-right left-right left-right green-1 green-1 green-1 red-2 red-2 red-2 red-1 red-1 red-1 green-2 green-2 green-2 left-right left-right left-right red-1 red-1 ' +
        'red-1 blue-2 blue-2 blue-2 green-1 green-1 green-1 blue-2 blue-2 blue-2 left-right left-right left-right green-1 green-1 green-1 red-2 red-2 red-2 blue-1 ' +
        'blue-1 blue-1 green-2 green-2 green-2 left-right left-right left-right left-right',
    ADVANCED = '2-cones left-right red-1 red-1 red-1 blue-2 blue-2 blue-2 left-right left-right green-1 green-1 green-1 red-2 red-2 red-2 left-right left-right blue-1 blue-1 ' +
        'blue-1 green-2 green-2 green-2 4-cones left-right blue-1 blue-1 blue-1 green-1 green-1 green-1 blue-2 blue-2 blue-2 green-2 green-2 green-2 left-right left-right ' +
        'left-right blue-1 blue-1 blue-1 blue-2 blue-2 blue-2 red-2 red-2 red-2 red-1 red-1 red-1 left-right left-right left-right red-1 red-1 red-1 blue-1 ' +
        'blue-1 blue-1 red-2 red-2 red-2 green-2 green-2 green-2 left-right left-right left-right green-1 green-1 green-1 blue-2 blue-2 blue-2 green-2 green-2 green-2 ' +
        'blue-1 blue-1 blue-1 6-cones left-right left-right red-1 red-1 red-1 green-2 green-2 green-2 red-2 red-2 red-2 green-1 green-1 green-1 blue-1 blue-1 ' +
        'blue-1 blue-2 blue-2 blue-2 left-right left-right left-right green-1 green-1 green-1 red-1 red-1 red-1 green-2 green-2 green-2 red-2 red-2 red-2 green-2 ' +
        'green-2 green-2 red-1 red-1 red-1 left-right left-right left-right blue-1 blue-1 blue-1 blue-2 blue-2 blue-2 green-1 green-1 green-1 green-2 green-2 green-2 ' +
        'red-1 red-1 red-1 red-2 red-2 red-2 left-right left-right left-right red-1 red-1 red-1 blue-2 blue-2 blue-2 green-1 green-1 green-1 blue-2 blue-2 ' +
        'blue-2 red-1 red-1 red-1 blue-2 blue-2 blue-2 left-right left-right left-right green-1 green-1 green-1 red-2 red-2 red-2 blue-1 blue-1 blue-1 green-2 ' +
        'green-2 green-2 red-1 red-1 red-1 left-right left-right left-right',
}

export enum QuickLeftRightDrill {
    BEGINNER = 'left-right left-right green-1-left-right green-1-left-right left-right left-right blue-2-left-right blue-2-left-right left-right left-right green-2-left-right green-2-left-right left-right left-right blue-1-left-right blue-1-left-right left-right left-right green-2-left-right green-2-left-right ' +
        'left-right left-right blue-1-left-right blue-1-left-right left-right left-right green-2-left-right green-2-left-right left-right left-right blue-2-left-right blue-2-left-right left-right left-right green-1-left-right green-1-left-right left-right left-right green-2-left-right green-2-left-right ' +
        'left-right green-1-left-right green-1-left-right left-right blue-2-left-right blue-2-left-right left-right green-1-left-right green-1-left-right left-right green-2-left-right green-2-left-right left-right green-1-left-right green-1-left-right left-right blue-1-left-right blue-1-left-right 2-cones left-right ' +
        'blue-2-left-right blue-2-left-right green-2-left-right green-2-left-right left-right left-right green-1-left-right green-1-left-right blue-2-left-right blue-2-left-right left-right left-right blue-1-left-right blue-1-left-right green-2-left-right green-2-left-right left-right left-right green-2-left-right green-2-left-right ' +
        'green-1-left-right green-1-left-right left-right left-right blue-1-left-right blue-1-left-right blue-2-left-right blue-2-left-right left-right left-right green-1-left-right green-1-left-right blue-1-left-right blue-1-left-right left-right left-right blue-1-left-right blue-1-left-right green-2-left-right green-2-left-right ' +
        'left-right left-right green-2-left-right green-2-left-right blue-1-left-right blue-1-left-right left-right left-right blue-1-left-right blue-1-left-right green-2-left-right green-2-left-right left-right left-right green-1-left-right green-1-left-right blue-2-left-right blue-2-left-right left-right left-right ' +
        'green-2-left-right green-2-left-right left-right blue-1-left-right blue-1-left-right left-right green-1-left-right green-1-left-right left-right blue-2-left-right blue-2-left-right left-right left-right green-1-left-right green-1-left-right blue-1-left-right blue-1-left-right left-right left-right blue-1-left-right ' +
        'blue-1-left-right green-2-left-right green-2-left-right left-right left-right green-1-left-right green-1-left-right blue-2-left-right blue-2-left-right left-right left-right blue-2-left-right blue-2-left-right green-1-left-right green-1-left-right left-right left-right blue-1-left-right blue-1-left-right green-1-left-right ' +
        'green-1-left-right left-right left-right blue-2-left-right blue-2-left-right green-2-left-right green-2-left-right left-right left-right green-1-left-right green-1-left-right blue-1-left-right blue-1-left-right left-right left-right green-2-left-right green-2-left-right left-right blue-1-left-right blue-1-left-right ' +
        'green-2-left-right green-2-left-right left-right left-right left-right left-right',
    INTERMEDIATE = 'left-right red-1-left-right red-1-left-right left-right blue-2-left-right blue-2-left-right left-right blue-1-left-right blue-1-left-right left-right red-2-left-right red-2-left-right left-right green-1-left-right green-1-left-right left-right blue-2-left-right blue-2-left-right left-right green-2-left-right ' +
        'green-2-left-right 2-cones blue-1-left-right blue-1-left-right green-2-left-right green-2-left-right left-right green-1-left-right green-1-left-right red-2-left-right red-2-left-right left-right red-1-left-right red-1-left-right green-2-left-right green-2-left-right left-right blue-2-left-right blue-2-left-right green-1-left-right ' +
        'green-1-left-right left-right green-1-left-right green-1-left-right green-2-left-right green-2-left-right left-right blue-1-left-right blue-1-left-right blue-2-left-right blue-2-left-right left-right red-1-left-right red-1-left-right blue-2-left-right blue-2-left-right left-right left-right green-1-left-right green-1-left-right ' +
        'red-2-left-right red-2-left-right red-1-left-right red-1-left-right 3-cones left-right blue-1-left-right blue-1-left-right blue-2-left-right blue-2-left-right red-1-left-right red-1-left-right left-right left-right red-1-left-right red-1-left-right green-1-left-right green-1-left-right blue-1-left-right blue-1-left-right ' +
        'left-right left-right green-2-left-right green-2-left-right red-2-left-right red-2-left-right blue-1-left-right blue-1-left-right left-right left-right green-1-left-right green-1-left-right blue-2-left-right blue-2-left-right red-1-left-right red-1-left-right left-right left-right green-2-left-right green-2-left-right ' +
        'blue-1-left-right blue-1-left-right red-2-left-right red-2-left-right left-right left-right red-1-left-right red-1-left-right green-2-left-right green-2-left-right blue-1-left-right blue-1-left-right left-right left-right blue-1-left-right blue-1-left-right red-2-left-right red-2-left-right green-1-left-right green-1-left-right ' +
        'left-right left-right blue-2-left-right blue-2-left-right green-2-left-right green-2-left-right red-2-left-right red-2-left-right 2-cones left-right green-2-left-right green-2-left-right blue-1-left-right blue-1-left-right green-1-left-right green-1-left-right 3-cones left-right red-2-left-right red-2-left-right ' +
        'green-1-left-right green-1-left-right blue-2-left-right blue-2-left-right left-right left-right red-1-left-right red-1-left-right green-2-left-right green-2-left-right red-1-left-right red-1-left-right left-right left-right red-2-left-right red-2-left-right green-1-left-right green-1-left-right red-2-left-right red-2-left-right ' +
        'left-right left-right blue-2-left-right blue-2-left-right blue-1-left-right blue-1-left-right green-2-left-right green-2-left-right left-right left-right blue-1-left-right blue-1-left-right green-2-left-right green-2-left-right red-1-left-right red-1-left-right left-right left-right left-right',
    ADVANCED = '2-cones left-right green-1-left-right green-1-left-right blue-2-left-right blue-2-left-right left-right blue-1-left-right blue-1-left-right red-2-left-right red-2-left-right left-right red-1-left-right red-1-left-right blue-2-left-right blue-2-left-right left-right left-right blue-1-left-right blue-1-left-right ' +
        'red-2-left-right red-2-left-right red-1-left-right red-1-left-right 3-cones left-right green-1-left-right green-1-left-right red-2-left-right red-2-left-right blue-1-left-right blue-1-left-right left-right left-right red-1-left-right red-1-left-right blue-2-left-right blue-2-left-right green-1-left-right green-1-left-right ' +
        'left-right left-right green-2-left-right green-2-left-right red-2-left-right red-2-left-right blue-1-left-right blue-1-left-right blue-1-left-right left-right left-right red-2-left-right red-2-left-right blue-1-left-right blue-1-left-right green-2-left-right green-2-left-right 4-cones left-right red-1-left-right ' +
        'red-1-left-right green-1-left-right green-1-left-right blue-1-left-right blue-1-left-right blue-2-left-right blue-2-left-right left-right left-right green-1-left-right green-1-left-right green-2-left-right green-2-left-right red-1-left-right red-1-left-right red-2-left-right red-2-left-right left-right left-right blue-2-left-right ' +
        'blue-2-left-right red-1-left-right red-1-left-right green-2-left-right green-2-left-right blue-1-left-right blue-1-left-right 5-cones left-right green-1-left-right green-1-left-right red-2-left-right red-2-left-right red-1-left-right red-1-left-right green-2-left-right green-2-left-right blue-1-left-right blue-1-left-right left-right ' +
        'left-right left-right blue-1-left-right blue-1-left-right blue-2-left-right blue-2-left-right red-1-left-right red-1-left-right red-2-left-right red-2-left-right blue-1-left-right blue-1-left-right left-right left-right green-2-left-right green-2-left-right red-2-left-right red-2-left-right green-1-left-right green-1-left-right ' +
        'red-1-left-right red-1-left-right blue-2-left-right blue-2-left-right 6-cones left-right left-right blue-2-left-right blue-2-left-right green-1-left-right green-1-left-right red-1-left-right red-1-left-right blue-2-left-right blue-2-left-right blue-1-left-right blue-1-left-right red-2-left-right red-2-left-right left-right ' +
        'left-right red-1-left-right red-1-left-right green-2-left-right green-2-left-right red-2-left-right red-2-left-right left-right left-right blue-2-left-right blue-2-left-right blue-2-left-right blue-1-left-right blue-1-left-right left-right left-right green-1-left-right green-1-left-right blue-2-left-right blue-2-left-right red-1-left-right ' +
        'red-1-left-right red-2-left-right red-2-left-right blue-1-left-right blue-1-left-right green-2-left-right green-2-left-right left-right left-right blue-1-left-right blue-1-left-right red-1-left-right red-1-left-right green-1-left-right green-1-left-right blue-2-left-right blue-2-left-right green-2-left-right green-2-left-right red-2-left-right ' +
        'red-2-left-right left-right left-right left-right left-right',
}

export enum MixedTraining {
    BEGINNER = 'left-right left-right green-1-left-right green-1-left-right left-right left-right blue-2 blue-2 blue-2 left-right left-right blue-1-left-right blue-1-left-right left-right left-right green-2 green-2 green-2 2-cones left-right blue-1-left-right ' +
        'blue-1-left-right green-1-left-right green-1-left-right left-right left-right green-1 green-1 green-1 blue-1 blue-1 blue-1 left-right left-right green-1-left-right green-1-left-right blue-1 blue-1 left-right left-right green-1-left-right blue-2-left-right ' +
        'left-right left-right green-2-left-right green-2-left-right blue-1 blue-1 pass pass pass left-right left-right green-2 green-2 green-2 green-1-left-right green-1-left-right left-right left-right blue-1-left-right blue-1-left-right green-1 ' +
        'green-1 green-1 left-right left-right green-1 green-1 green-1 blue-2 blue-2 blue-2 left-right left-right pass pass pass 3-cones left-right left-right blue-1-left-right blue-1-left-right green-1 ' +
        'green-1 green-1 blue-2 blue-2 blue-2 left-right left-right green-1-left-right green-1-left-right blue-2 blue-2 blue-2 green-2-left-right green-2-left-right left-right left-right blue-1 blue-1 blue-1 green-2 green-2 ' +
        'green-2 green-1 green-1 green-1 left-right left-right pass pass pass 4-cones left-right left-right green-1 green-1 green-1 blue-1-left-right blue-1-left-right green-2-left-right green-2-left-right blue-2 blue-2 ' +
        'blue-2 left-right left-right left-right blue-1 blue-1 blue-1 green-2 green-2 green-2 blue-1-left-right blue-1-left-right green-2-left-right green-2-left-right left-right left-right left-right pass pass pass blue-2 ' +
        'blue-2 blue-2 green-2 green-2 green-2 green-1-left-right green-1-left-right blue-1-left-right blue-1-left-right left-right left-right left-right pass pass pass green-1-left-right green-1-left-right blue-2 blue-2 blue-2 blue-1-left-right ' +
        'blue-1-left-right green-2 green-2 green-2 left-right left-right left-right',
    INTERMEDIATE = '2-cones left-right red-1-left-right red-1-left-right blue-1-left-right blue-1-left-right left-right blue-2 blue-2 blue-2 green-1 green-1 green-1 left-right left-right green-1-left-right green-1-left-right blue-1 blue-1 blue-1 left-right ' +
        'left-right red-2-left-right red-2-left-right blue-1-left-right blue-1-left-right left-right left-right pass pass pass 3-cones left-right left-right green-2 green-2 green-2 red-1 red-1 red-1 blue-1-left-right blue-1-left-right ' +
        'left-right left-right green-1-left-right green-1-left-right red-2 red-2 red-2 green-1 green-1 green-1 left-right left-right blue-1 blue-1 blue-1 red-2-left-right red-2-left-right green-1 green-1 green-1 left-right ' +
        'left-right green-2-left-right green-2-left-right red-1-left-right red-1-left-right blue-2 blue-2 blue-2 left-right left-right pass pass pass 4-cones left-right red-1 red-1 red-1 blue-2-left-right blue-2-left-right green-1 ' +
        'green-1 green-1 red-2 red-2 red-2 left-right left-right pass pass left-right left-right blue-1-left-right blue-1-left-right green-2 green-2 green-2 blue-1 blue-1 blue-1 blue-2-left-right blue-2-left-right ' +
        'left-right left-right red-2 red-2 red-2 green-1-left-right green-1-left-right green-2 green-2 green-2 red-1-left-right red-1-left-right left-right left-right blue-2 blue-2 blue-2 green-1 green-1 green-1 green-1-left-right ' +
        'green-1-left-right red-2 red-2 red-2 left-right left-right pass pass pass 5-cones left-right blue-1 blue-1 blue-1 green-1 green-1 green-1 blue-2-left-right blue-2-left-right red-1-left-right red-1-left-right ' +
        'red-2 red-2 red-2 left-right left-right red-1-left-right red-1-left-right green-1 green-1 green-1 blue-2-left-right blue-2-left-right red-1 red-1 red-1 green-2-left-right green-2-left-right left-right left-right pass pass ' +
        'pass left-right green-1 green-1 green-1 red-2-left-right red-1-left-right blue-1 blue-1 blue-1 green-2 green-2 green-2 red-2-left-right red-2-left-right left-right left-right green-1-left-right green-1-left-right red-1-left-right red-1-left-right ' +
        'blue-1 blue-1 blue-1 red-2-left-right red-2-left-right green-1-left-right green-1-left-right left-right left-right blue-1-left-right blue-1-left-right green-2 green-2 green-2 red-1 red-1 red-1 blue-2-left-right blue-2-left-right green-1-left-right green-1-left-right ' +
        'left-right left-right blue-1-left-right blue-1-left-right green-2 green-2 green-2 blue-1 blue-1 blue-1 blue-2-left-right blue-2-left-right red-2-left-right red-2-left-right red-2-left-right left-right left-right left-right',
    ADVANCED = '3-cones left-right left-right blue-1-left-right blue-1-left-right green-2 green-2 green-2 red-1-left-right red-1-left-right red-1-left-right left-right left-right blue-2 blue-2 blue-2 blue-1 blue-1 blue-1 green-2-left-right green-2-left-right ' +
        'left-right left-right pass pass pass left-right left-right red-1 red-1 red-1 blue-2-left-right blue-2-left-right green-1 green-1 green-1 left-right left-right red-1-left-right red-1-left-right green-1-left-right green-1-left-right ' +
        'blue-2 blue-2 blue-2 left-right left-right pass pass pass 4-cones left-right green-2-left-right green-2-left-right green-1 green-1 green-1 blue-1 blue-1 blue-1 red-1 red-1 red-1 ' +
        'left-right left-right red-1-left-right red-1-left-right green-2-left-right green-2-left-right red-2 red-2 red-2 blue-1 blue-1 blue-1 left-right left-right green-1 green-1 green-1 blue-2-left-right blue-2-left-right blue-2-left-right red-2 ' +
        'red-2 red-2 blue-1-left-right blue-1-left-right left-right left-right green-1-left-right green-1-left-right red-2 red-2 red-2 green-1 green-1 green-1 blue-1-left-right blue-1-left-right left-right left-right pass pass pass ' +
        '6-cones left-right red-1-left-right red-1-left-right blue-1-left-right blue-1-left-right red-2-left-right red-2-left-right blue-1 blue-1 blue-1 green-2-left-right green-2-left-right blue-2-left-right blue-2-left-right left-right pass pass pass green-1 green-1 ' +
        'green-1 blue-2-left-right blue-2-left-right blue-1 blue-1 blue-1 red-2-left-right red-2-left-right red-1 red-1 red-1 blue-2-left-right blue-2-left-right left-right pass pass pass left-right red-1 red-1 red-1 ' +
        'blue-2-left-right blue-2-left-right green-1 green-1 green-1 green-2-left-right green-2-left-right blue-1 blue-1 blue-1 red-2-left-right red-2-left-right left-right pass pass pass left-right blue-1-left-right blue-1-left-right red-2 red-2 ' +
        'red-2 green-2-left-right green-2-left-right red-1 red-1 red-1 blue-2-left-right blue-2-left-right blue-1 blue-1 blue-1 left-right pass pass pass left-right green-1 green-1 green-1 blue-2-left-right blue-2-left-right ' +
        'red-2 red-2 red-2 green-2 green-2 green-2 blue-1-left-right blue-1-left-right blue-2 blue-2 blue-2 left-right left-right left-right',
}

export enum Colour {
    YELLOW = '#FFFE54',
    RED = '#E73123',
    BLUE = '#0A205A',
    GREEN = '#54AD5B',
    X = 'white',
}

export const getVoiceArrayByCatalogue = (catalogue: any, difficulty: string) => {
    const speech = catalogue[difficulty.toUpperCase() as keyof typeof catalogue];
    return speech.split(' ');
};
