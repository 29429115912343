import Auth, { CognitoUser } from '@aws-amplify/auth';
import styled from '@emotion/styled';
import { IonButton, IonContent, IonInput, IonItem, IonLoading, IonPage, IonToast } from '@ionic/react';
import { useFormik } from 'formik';
import React, { useState } from 'react';

import { Affiliates, Logo } from '../components';

enum Challenge {
    NONE,
    RESET_PASSWORD,
    NEW_PASSWORD,
    CONFIRM_CODE,
}

export const Login = () => {
    const [user, setUser] = useState<CognitoUser>();

    const handleError = (err: any) => {
        if (err.code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when
            // signing up In this case you need to resend the code and confirm the
            // user About how to resend the code and confirm the user, please check
            // the signUp part
            try {
                Auth.resendSignUp(username).then((res) => {
                    setFieldValue('toast', 'Code resent succesfully');
                    setFieldValue('challenge', Challenge.CONFIRM_CODE);
                    setSubmitting(false);
                });
            } catch (err) {
                console.log('error resending code: ', err);
            }
        } else if (err.code === 'PasswordResetRequiredException') {
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
            setFieldValue('challenge', Challenge.RESET_PASSWORD);
            setSubmitting(false);
        } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
            setFieldValue('message', err.message);
        } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in
            // the Cognito user pool
            setFieldValue('message', err.message);
        } else {
            console.log(err);
            setFieldValue('message', err.message);
        }
    };

    const {
        isSubmitting,
        handleSubmit,
        setFieldValue,
        setSubmitting,
        values: { username, password, newPassword, challenge, code, message, toast },
    } = useFormik({
        initialValues: {
            username: '',
            password: '',
            newPassword: '',
            challenge: Challenge.NONE,
            code: '',
            message: '',
            toast: '',
        },
        async onSubmit({ username, password, newPassword, challenge }) {
            try {
                if (challenge) {
                    switch (challenge) {
                        case Challenge.NEW_PASSWORD:
                            await Auth.completeNewPassword(
                                user, // the Cognito User Object
                                newPassword, // the new password
                                {}, // no required attributes
                            );
                            break;
                        case Challenge.RESET_PASSWORD:
                            await Auth.forgotPasswordSubmit(username, code, newPassword);
                            break;
                        case Challenge.CONFIRM_CODE:
                            await Auth.confirmSignUp(username, code);
                            break;
                        default:
                            console.log('Challenge not implemented');
                            break;
                    }
                    window.location.href = '/';
                } else {
                    const user = await Auth.signIn({ username, password });

                    setUser(user); // store in local state for different scenarios

                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        setFieldValue('challenge', Challenge.NEW_PASSWORD);
                        setSubmitting(false);
                    } else {
                        // The user directly signs in
                        window.location.href = '/';
                    }
                }
            } catch (err) {
                handleError(err);
            }
        },
    });

    return (
        <Container>
            <IonContent>
                <IonToast isOpen={!!toast} onDidDismiss={() => setFieldValue('toast', '')} message={toast} />

                <IonLoading isOpen={isSubmitting} />

                <ContentContainer>
                    <Logo size={150} />

                    {message && <Message>{message}</Message>}

                    <FormContainer onSubmit={handleSubmit}>
                        {challenge ? (
                            <>
                                {challenge === Challenge.RESET_PASSWORD ||
                                    (challenge === Challenge.CONFIRM_CODE && (
                                        <IonItem>
                                            <IonInput
                                                type="text"
                                                name="code"
                                                placeholder="Enter verification code"
                                                value={code}
                                                onIonChange={(e) => setFieldValue('code', e.detail.value)}
                                            />
                                            <IonButton slot="end" size="small">
                                                RESEND
                                            </IonButton>
                                        </IonItem>
                                    ))}
                                <IonItem>
                                    <IonInput
                                        type="password"
                                        name="newPassword"
                                        placeholder="Enter a new password"
                                        value={newPassword}
                                        onIonChange={(e) => setFieldValue('newPassword', e.detail.value)}
                                    />
                                </IonItem>
                                <IonButton expand="block" fill="solid" type="submit">
                                    SUBMIT
                                </IonButton>
                            </>
                        ) : (
                            <>
                                <IonItem>
                                    <IonInput
                                        name="username"
                                        value={username}
                                        type="email"
                                        placeholder="Email"
                                        inputMode="email"
                                        onIonChange={(e) => setFieldValue('username', e.detail.value)}
                                        onIonInput={(e) => setFieldValue('username', (e!.target as any).value)}
                                    />
                                </IonItem>

                                <IonItem>
                                    <IonInput
                                        name="password"
                                        value={password}
                                        placeholder="Password"
                                        type="password"
                                        onIonChange={(e) => setFieldValue('password', e.detail.value)}
                                        onIonInput={(e) => setFieldValue('password', (e!.target as any).value)}
                                    />
                                </IonItem>

                                <IonButton expand="block" fill="solid" type="submit">
                                    LOG IN
                                </IonButton>

                                <IonButton expand="block" fill="outline" routerLink="/signup" routerDirection="forward">
                                    CREATE ACCOUNT
                                </IonButton>

                                <IonButton expand="block" fill="clear">
                                    Forgot Password?
                                </IonButton>
                            </>
                        )}
                    </FormContainer>

                    <Affiliates />
                </ContentContainer>
            </IonContent>
        </Container>
    );
};

export default Login;

const Container = styled(IonPage)``;

const ContentContainer = styled.div`
    position: relative;
    min-height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    ${Logo} {
        margin: 0 auto;
    }
`;

const FormContainer = styled.form`
    display: grid;
    grid-template-columns: repeat(auto-fill, 1fr);
    gap: 1rem;
    margin: 1rem;

    ion-button {
        font-size: smaller;
    }
`;

const Message = styled.div`
    padding: 1rem;
    box-shadow: 8px;
    font-size: smaller;
    background: var(--ion-color-primary-contrast);
    color: var(--ion-color-primary);
    border-radius: 0.5rem;
`;
