/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import React from 'react';

interface TitleProps {
    title: string;
}
const Title: React.FC<TitleProps> = ({ children, title }) => {
    return (
        <ToolbarContent>
            <TitlePage>{title}</TitlePage>
            {children}
        </ToolbarContent>
    );
};

const ToolbarContent = styled.div`
    position: relative;
`;
const TitlePage = styled.h3`
    font-size: 22px;
    align-items: left;
    text-align: left;
    align-items: flex-end;
    color: var(--ion-title-color);
    margin-bottom: 0;
    font-family: Montserrat-Bold;
`;
export default Title;
