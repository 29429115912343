import { gql, useQuery } from '@apollo/client';

type TData = {
    categories: Category[];
};

export const useCategories = () => {
    return useQuery<TData>(CATEGORIES);
};

export const CATEGORIES = gql`
    query {
        categories {
            term {
                term_id
                name
                slug
            }
        }
    }
`;
