/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { TabType } from './AudioPlayer';

interface AudioTab {
    tab: TabType;
    currentTab: TabType;
    setCurrentTab: (tab: TabType) => void;
}

const AudioTab: React.FC<AudioTab> = ({ tab, currentTab, setCurrentTab }) => {
    return (
        <Tab onClick={() => setCurrentTab(tab)}>
            <div
                className="audio-tab"
                css={css`
                    background: ${tab.backgroundColor};
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    ${currentTab.title === tab.title ? '' : 'top: 95%;'}
                    transition: 0.2s;
                `}
            ></div>
            {tab.title}
        </Tab>
    );
};

const Tab = styled.div`
    width: calc(100% / 3);
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 12px;
    color: var(--ion-title-color);
    overflow: hidden;
    cursor: pointer;
`;
export default AudioTab;
