import styled from '@emotion/styled';
import { IonButton, IonIcon, IonLoading, IonModal } from '@ionic/react';
import { reorderTwoOutline, share } from 'ionicons/icons';
import QRCode from 'qrcode.react';
import React from 'react';

import { AccountGuard } from '../features/Auth';
import { useMember } from '../features/Member';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { useShareQRCodeMutation } from '../hooks/useShareQRCodeMutation';

import { useModal } from '.';

const ProfileQRCodeModal: React.FC = () => {
    const { _id: accountId } = useCurrentUser();
    const {
        profile: { _id: id },
    } = useMember();
    const { toggle, toggleWebShare, showMemberQRCode } = useModal();

    const [shareQRCode, { loading }] = useShareQRCodeMutation({
        onCompleted(data) {
            toggleWebShare({
                title: 'QR Code',
                url: `${window.location.href}/?accountId=${accountId}&memberId=${id}&nonce=${encodeURIComponent(
                    data.shareQRCode,
                )}`,
            });
        },
    });

    return (
        <Container swipeToClose onDidDismiss={() => toggle('showMemberQRCode', false)} isOpen={showMemberQRCode}>
            <Handle onClick={() => toggle('showMemberQRCode', false)}>
                <IonIcon icon={reorderTwoOutline} />
            </Handle>

            <ContentContainer>
                <IonLoading isOpen={loading} />

                <div>Use your QR code for access into class</div>

                <QRCode value={id} size={200} />

                <AccountGuard>
                    <IonButton onClick={() => shareQRCode({ variables: { id } })} expand="block">
                        <IonIcon icon={share} slot="start" />
                        Share QR Code
                    </IonButton>
                </AccountGuard>
            </ContentContainer>
        </Container>
    );
};

export default ProfileQRCodeModal;

const Container = styled(IonModal)`
    color: var(--ion-text-color);

    .modal-wrapper[role='dialog'] {
        height: 400px;
        border-radius: 20px 20px 0 0;
        margin-top: auto;
    }

    ion-button {
        width: 100%;
    }
`;

const ContentContainer = styled.div`
    height: 100%;
    padding: 1rem;
    display: grid;
    grid-template-rows: auto 1fr auto;
    place-items: center;
`;

const Handle = styled.div`
    text-align: center;
    padding: 1rem;
`;
