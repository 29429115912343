import styled from '@emotion/styled';
import React from 'react';

import { Spacer } from '../../components';
import Title from '../../components/Title';
import { usePastGames } from '../../hooks/usePastGames';
import DemoVideo from '../Videos/DemoVideo';

const OlderWatch: React.FC = () => {
    const { data } = usePastGames();

    return (
        <Container>
            <Title title="Past Games" />

            {!!data?.pastGames.length ? (
                data.pastGames.map((pastGame, index) => (
                    <div key={index}>
                        <Spacer />
                        <DemoVideo videoUrl={pastGame.url} rounded />
                    </div>
                ))
            ) : (
                <Error>No Video Of Past Games</Error>
            )}
        </Container>
    );
};

export default OlderWatch;

const Container = styled.div`
    margin: 1.5rem;
`;

const Error = styled.div`
    border-radius: 8px;
    height: calc(100vw * 0.5);
    background-color: var(--ion-border-card);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat-Bold';
    font-size: 20px;
    color: black;
`;
