import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';

export const GET_COMPETITION = gql`
    query getCompetition($id: String!) {
        competition(id: $id) {
            description
            videoDemo
        }
    }
`;

type TData = { competition: Pick<Competition, 'description' | 'videoDemo'> };
type TVariables = { id: string };

export const useCompetition = (options?: LazyQueryHookOptions<TData, TVariables>) => {
    return useLazyQuery<TData, TVariables>(GET_COMPETITION, options);
};
