import { gql, MutationHookOptions, useMutation } from '@apollo/client';

interface TData {
    shareQRCode: string;
}

export const useShareQRCodeMutation = (options?: MutationHookOptions<TData>) => {
    return useMutation<TData>(
        gql`
            mutation ShareQRCode($id: String!) {
                shareQRCode(id: $id)
            }
        `,
        options,
    );
};
