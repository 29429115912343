/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { IonCol, IonGrid, IonRow, IonSlide, IonSlides, IonSpinner, IonText } from '@ionic/react';
import { css } from '@emotion/react';

import { useLeague } from '../../hooks/useLeague';
import { useCategories } from '../../hooks/useCategories';

const LeaguesTable: React.FunctionComponent = () => {
    const sliderRef = useRef<HTMLIonSlidesElement>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const { data: categoryData } = useCategories();
    const [getLeague, { data, loading }] = useLeague();

    useEffect(() => {
        categoryData && getLeague({ variables: { category: categoryData?.categories[currentIndex].term.slug } });
    }, [categoryData, currentIndex]);

    const slideOpts = {
        initialSlide: 0,
        speed: 400,
    };

    if (!categoryData) return null;

    return (
        <TableContainer>
            <IonSlides
                pager={true}
                options={slideOpts}
                onIonSlideDidChange={async () => {
                    const idx = await sliderRef.current?.getActiveIndex();
                    setCurrentIndex(idx || 0);
                }}
                ref={sliderRef}
            >
                {categoryData?.categories.map((category, index) => (
                    <IonSlide key={index} css={css(`display: block; padding-bottom: 30px`)}>
                        <IonText>{category.term.name}</IonText>
                        <IonGrid>
                            {/* header */}
                            <TableHeader>
                                <IonCol />
                                <IonCol />
                                <IonCol size="6">CLUB</IonCol>
                                <IonCol>P</IonCol>
                                <IonCol>W</IonCol>
                                <IonCol>D</IonCol>
                                <IonCol>L</IonCol>
                                <IonCol>PTS</IonCol>
                            </TableHeader>
                            {/* body */}
                            {loading ? (
                                <div
                                    css={css`
                                        width: 100%;
                                        text-align: center;
                                    `}
                                >
                                    <IonSpinner />
                                </div>
                            ) : (
                                data?.leagueTable &&
                                data?.leagueTable.map((team, index) => (
                                    <TableRow key={index}>
                                        <IonCol>{index + 1}</IonCol>
                                        <IonCol>
                                            <TeamLogo
                                                src={
                                                    team?.flag
                                                        ? `${process.env.REACT_APP_DOMAIN_PREFIX}/${team?.flag}`
                                                        : ''
                                                }
                                            />
                                        </IonCol>
                                        <IonCol size="6">{team.name}</IonCol>
                                        <IonCol>{team.rating.total}</IonCol>
                                        <IonCol>{team.rating.win}</IonCol>
                                        <IonCol>{team.rating.draw}</IonCol>
                                        <IonCol>{team.rating.lose}</IonCol>
                                        <IonCol>{team.rating.points}</IonCol>
                                    </TableRow>
                                ))
                            )}
                        </IonGrid>
                    </IonSlide>
                ))}
            </IonSlides>
        </TableContainer>
    );
};

export default LeaguesTable;

const TableContainer = styled.div`
    background: var(--ion-content-dark-2);
    border-radius: 8px;
    border: 1px solid var(--ion-border);
    padding: 20px 10px 5px 10px;
    margin-top: 10px;
    color: var(--ion-white-text-button);

    div {
        text-align: center;
    }
    div ion-text {
        text-align: center;
        font-size: 16px;
        font-family: 'Montserrat-Bold';
        line-height: 1.5;
    }
    ion-col {
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 9px;
    }
`;

const TableHeader = styled(IonRow)`
    color: var(--ion-text-color);
    padding: 4px 0;
    display: flex;
    flex-wrap: nowrap;
    ion-col {
        padding: 2px;
        display: flex;
        justify-content: center;
    }
    ion-col:nth-of-type(3) {
        justify-content: start !important;
    }
`;

const TableRow = styled(IonRow)`
    height: 25px;
    border-radius: 4px;
    background-color: var(--ion-content-dark);
    margin-bottom: 1px;
    color: var(--ion-white-text-button);
    display: flex;
    flex-wrap: nowrap;
    &:hover {
        background-color: var(--table-row-hover);
    }
    ion-col {
        padding: 2px;
        display: flex;
        justify-content: center;
    }
    ion-col:nth-of-type(3) {
        justify-content: start !important;
    }
    ion-col:first-of-type {
        justify-content: center !important;
    }
    ion-col:nth-of-type(3) {
        color: var(--ion-text-color);
    }
    ion-col:nth-of-type(3):hover {
        color: var(--ion-white-text-button);
    }
    ion-col:last-child {
        border-left: 2px solid black;
    }
`;

const TeamLogo = styled.img`
    border-radius: 2px;
    max-height: 16px;
    object-fit: contain;
`;
