import Auth from '@aws-amplify/auth';
import { IonLoading } from '@ionic/react';
import qs from 'qs';
import React, { Fragment, useContext, useEffect, useState } from 'react';

interface AuthState {
    isAuthenticated: boolean | null;
    isAnonymous: boolean;
}

const defaultAuthState = {
    isAuthenticated: null,
    isAnonymous: false,
};

const AuthContext = React.createContext<AuthState>(defaultAuthState);

export const AuthProvider: React.FC<{ fallback: React.ReactNode }> = ({ children, fallback }) => {
    const [state, setState] = useState<AuthState>(defaultAuthState);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(() => {
                setState({ isAuthenticated: true, isAnonymous: false });
            })
            .catch(() => {
                const { search } = window.location;
                const { nonce } = qs.parse(search.substring(1));

                if (!!nonce) {
                    setState({ isAuthenticated: true, isAnonymous: true });
                } else {
                    setState({ isAuthenticated: false, isAnonymous: false });
                }
            });
    }, []);

    if (state.isAuthenticated === null) {
        return <IonLoading isOpen />;
    }

    if (!state.isAuthenticated) {
        return <Fragment>{fallback}</Fragment>;
    }

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    return context;
};
