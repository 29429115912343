/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
    IonGrid,
    IonRow,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCheckbox,
    IonItem,
    IonInput,
    IonIcon,
    IonCol,
} from '@ionic/react';
import { pencil } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { pickerController } from '@ionic/core';
interface ProgramProp {
    program: Program;
    onTickProgramHandler: (id: string, quantity: number) => void;
    onUpdateQuantity?: (id: string, quantity: number) => void;
}

interface QuantityOption {
    value: number;
    text: string;
}

const ProgramItem: React.FunctionComponent<ProgramProp> = ({ program, onTickProgramHandler, onUpdateQuantity }) => {
    const { _id, name, link, startDate, endDate, startTime, endTime, address, type, fee } = program;
    const [quantity, setQuantity] = useState(1);
    const [isChecked, setIsChecked] = useState(false);
    const onCheckedHandler = (value: boolean) => {
        setIsChecked(value);
        onTickProgramHandler(_id, quantity);
    };
    const quantityOptions: [QuantityOption] = [{ text: '1', value: 1 }];
    for (let index = 2; index <= 30; index++) {
        quantityOptions.push({ text: JSON.stringify(index), value: index });
    }

    useEffect(() => {
        if (isChecked) onUpdateQuantity ? onUpdateQuantity(_id, quantity) : null;
    }, [quantity]);

    const numberPicker = async () => {
        const picker = await pickerController.create({
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Confirm',
                    handler: (selected) => {
                        setQuantity(selected.quantity.value);
                    },
                },
            ],
            columns: [
                {
                    name: 'quantity',
                    options: quantityOptions,
                },
            ],
        });

        picker.columns[0].options.forEach((element) => {
            delete element.selected;
            delete element.duration;
            delete element.transform;
        });

        picker.present();
    };
    return (
        <IonGrid>
            <IonRow>
                <Column lines="none">
                    <ProgramCard>
                        <IonCardHeader>
                            <CardTitle> {name}</CardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <a href={link} target="_">
                                Click here for Program details
                            </a>
                        </IonCardContent>
                        <IonCardContent>
                            <IonRow justify-content-between>
                                <IonCol>Start Date</IonCol>
                                <ColumnRight> {startDate}</ColumnRight>
                            </IonRow>
                            <IonRow justify-content-between>
                                <IonCol>End Date</IonCol>
                                <ColumnRight> {endDate}</ColumnRight>
                            </IonRow>
                            <IonRow justify-content-between>
                                <IonCol>Start Time</IonCol>
                                <ColumnRight> {startTime}</ColumnRight>
                            </IonRow>
                            <IonRow justify-content-between>
                                <IonCol>End Time</IonCol>
                                <ColumnRight> {endTime}</ColumnRight>
                            </IonRow>
                            <IonRow justify-content-between>
                                <IonCol>Venue Address</IonCol>
                                <ColumnRight> {address}</ColumnRight>
                            </IonRow>
                            <IonRow justify-content-between>
                                <IonCol>Program fees</IonCol>
                                <ColumnRight> ${fee}</ColumnRight>
                            </IonRow>
                            {type === 'session' && (
                                <IonRow>
                                    <IonCol style={{ margin: 'auto' }}>
                                        Quantity (
                                        <b
                                            css={css`
                                                color: var(--ion-color-primary);
                                            `}
                                        >
                                            *
                                        </b>
                                        )
                                    </IonCol>

                                    <IonInput readonly value={quantity} />

                                    <IonIcon
                                        css={css`
                                            margin: auto 5px;
                                        `}
                                        size="small"
                                        icon={pencil}
                                        color="primary"
                                        onClick={() => numberPicker()}
                                    />
                                </IonRow>
                            )}
                        </IonCardContent>
                    </ProgramCard>
                    <Checkbox
                        color="danger"
                        slot="end"
                        name="isChecked"
                        onIonChange={(e) => {
                            onCheckedHandler(e.detail.checked);
                        }}
                    />
                </Column>
            </IonRow>
        </IonGrid>
    );
};

export default ProgramItem;

const Column = styled(IonItem)`
    border: solid 1px;
    border-color: var(--ion-toolbar-border-color, var(--ion-border-color, var(--ion-color-step-150, #c1c4cd)));
    border-radius: 5px;
    width: 100%;
`;

const ProgramCard = styled(IonCard)`
    box-shadow: none;
    margin: 0;
    width: 100%;
    ion-card-header,
    ion-card-content {
        padding-left: 0;
        padding-right: 0;
    }
`;
const CardTitle = styled(IonCardTitle)`
    color: var(--ion-color-primary);
`;
const Checkbox = styled(IonCheckbox)`
    top: 0;
    position: absolute;
    right: 18px;

    &::part(container) {
        border-color: var(--ion-color-primary);
    }
`;

const ColumnRight = styled(IonCol)`
    text-align: right;
`;
