/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import React, { useEffect } from 'react';

interface IntroCounterNumberProps {
    introCounter: number;
    setIntroCounter: (counter: number) => void;
}

const IntroCounterNumber: React.FunctionComponent<IntroCounterNumberProps> = ({ introCounter, setIntroCounter }) => {
    const textToSpeech = new SpeechSynthesisUtterance();
    textToSpeech.lang = 'en-US';

    useEffect(() => {
        if (introCounter) {
            try {
                if (introCounter !== 3) {
                    textToSpeech.text = introCounter + '';
                    speechSynthesis.speak(textToSpeech);
                }
                setTimeout(() => {
                    setIntroCounter(introCounter - 1);
                }, 1000);
            } catch (error) {
                alert(error);
            }
        } else {
            return;
        }
    }, [introCounter]);

    return (
        <CounterNumber>
            <h3>{introCounter}</h3>
        </CounterNumber>
    );
};

export default IntroCounterNumber;

const CounterNumber = styled.div`
    width: 100%;
    position: absolute;
    left: 0;
    top: 19%;
    text-align: center;
    h3 {
        color: white;
        font-size: 60px;
    }
`;
