import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';

import { useOnScreen } from '../hooks/useOnScreen';

interface VideoProps {
    rounded?: boolean;
    ytbVideoId: string;
}

const Video: React.FunctionComponent<VideoProps> = ({ rounded, ytbVideoId }) => {
    const ref = useRef<HTMLIFrameElement | null>(null);
    const isVisible = useOnScreen(ref);

    useEffect(() => {
        const iframe: any = document.getElementById(ytbVideoId);
        iframe.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    }, [isVisible]);

    return (
        <VideoCard>
            <VideoCardContent>
                <VideoIframe
                    ref={ref}
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${ytbVideoId}?enablejsapi=1&playsinline=1`}
                    id={ytbVideoId}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    {...{ rounded }}
                ></VideoIframe>
            </VideoCardContent>
        </VideoCard>
    );
};

export default Video;

const VideoCard = styled.div``;

const VideoCardContent = styled.div`
    height: calc(100vw * 0.5);
`;

const VideoIframe = styled.iframe<{ rounded?: boolean }>`
    border-radius: ${({ rounded }) => rounded && `8px`};
    background-color: black;
`;
