/** @jsxImportSource @emotion/react */

import { Toast } from '@capacitor/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IonButton, IonIcon, IonItemDivider, IonItemGroup, IonLabel, IonSpinner } from '@ionic/react';
import { FieldArray } from 'formik';
import { arrowForwardOutline, closeCircleSharp } from 'ionicons/icons';
import React from 'react';

import Field, { FieldProps } from '../../components/Field';
import { FormValues, initialMemberValues } from '../../pages/Setup';
import { SignUpFields } from '../Auth';
import { SubmitButton } from '../Auth/styled';

type Props = {
    isBlocked?: boolean;
    isLoading?: boolean;
    handleSubmit: () => void;
    setValues?: (values: SignUpFields) => void;
    values?: SignUpFields;
};

const SetupMembers: React.FC<Props> = ({ isBlocked, isLoading, handleSubmit, setValues, values }) => {
    const initialMembers = {
        name: `${values?.firstName} ${values?.lastName}`,
        gender: values?.gender || 'Male',
        birthdate: values?.birthdate || '',
        experience: 'No experience',
        jerseySize: 'XXS',
    };

    const handleSkipForm = () => {
        if (values && setValues) {
            setValues({ ...values, members: [initialMembers] });
            handleSubmit();
        }
    };

    const handleDoneForm = () => {
        values?.members.forEach((member, index) => {
            if (member.birthdate && member.experience && member.gender && member.jerseySize && member.name) {
                if (index === values.members.length - 1) handleSubmit();
            } else {
                Toast.show({ text: 'Please fill all fields before submit!' });
            }
        });
    };
    return (
        <>
            <SkipButton onClick={handleSkipForm}>
                Skip <IonIcon icon={arrowForwardOutline} />
            </SkipButton>
            <TitleForm>Step 2: Child Account Creation</TitleForm>
            <small>(Parents may create multiple accounts if registering more than 1 child)</small>

            <FieldArray name="members">
                {({ push, remove, form: { values } }) => (
                    <>
                        {((values as FormValues).members || []).map((_member, index) => (
                            <IonItemGroup
                                css={css`
                                    margin-top: 2rem;
                                `}
                                key={index}
                            >
                                <IonItemDivider>
                                    <IonLabel>Player {(index + 1).toString()}</IonLabel>
                                    {index > 0 && (
                                        <IonButton
                                            css={css(`margin: 0px`)}
                                            slot="end"
                                            fill="clear"
                                            onClick={() => remove(index)}
                                        >
                                            <IonIcon slot="icon-only" icon={closeCircleSharp} />
                                        </IonButton>
                                    )}
                                </IonItemDivider>

                                {fields.map((field) => (
                                    <Field key={field.name} {...field} name={`members.${index}.${field.name}`} />
                                ))}
                            </IonItemGroup>
                        ))}

                        <SubmitButton
                            css={css`
                                margin: 1rem 0;
                            `}
                            type="button"
                            expand="block"
                            fill="outline"
                            onClick={() => push(initialMemberValues)}
                        >
                            Add More Player
                        </SubmitButton>

                        <SubmitButton
                            expand="block"
                            shape="round"
                            type="button"
                            disabled={isBlocked}
                            onClick={handleDoneForm}
                        >
                            {isLoading ? <IonSpinner /> : 'Done'}
                        </SubmitButton>
                    </>
                )}
            </FieldArray>
        </>
    );
};

export default SetupMembers;

const SkipButton = styled.div`
    --background: #000;
    color: var(--ion-white-text-button);
    height: 25px;
    float: right;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    cursor: pointer;
    ion-icon {
        margin-left: 4px;
    }
`;

const TitleForm = styled.h5`
    margin-top: 45px;
`;
export const fields: FieldProps[] = [
    {
        name: 'name',
        label: 'Full Name',
        inputProps: {
            autofocus: true,
            autocomplete: 'name' as 'on',
        },
    },
    {
        name: 'birthdate',
        label: 'Date of Birth',
        inputProps: {
            autocomplete: 'bday' as 'on',
        },
    },
    {
        name: 'gender',
        label: 'Gender',
        inputProps: {
            autocomplete: 'sex' as 'on',
        },
    },
    {
        name: 'experience',
        label: 'Experience',
        inputProps: {
            autocomplete: 'off',
        },
    },
    {
        name: 'jerseySize',
        label: 'Jersey Size',
        inputProps: {
            autocomplete: 'off',
        },
    },
];
