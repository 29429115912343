/** @jsxImportSource @emotion/react */

import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IonIcon, IonText } from '@ionic/react';
import { heart } from 'ionicons/icons';
import ReactPlayer from 'react-player';
import Storage from '@aws-amplify/storage';
import { useHistory } from 'react-router';
import { Toast } from '@capacitor/core';

import shared from '../../assets/share.svg';
import { useUpdateVideoLikedRequest } from '../../hooks/useUpdateVideoLikedMutation';
import { useUser } from '../../hooks/useUser';
import { useOnScreen } from '../../hooks/useOnScreen';

interface TopLikedProps {
    ranked: number;
    video: Video;
    updateVideo: (video: { id: string; liked: string[] }) => void;
}

const TopLiked: React.FunctionComponent<TopLikedProps> = ({ ranked, video, updateVideo }) => {
    const [isPlayingVideo, setIsPlayingVideo] = useState(false);
    const [user, setUser] = useState<User>();
    const [url, setUrl] = useState('');
    const history = useHistory();
    const [useUploadVideoRequest] = useUpdateVideoLikedRequest();
    const ref = useRef<HTMLDivElement | null>(null);
    const isVisible = useOnScreen(ref);

    useUser({
        onCompleted: (data) => {
            setUser(data.user);
        },
    });

    const getVideoUrl = async () => {
        try {
            const url = await Storage.get(video.videoUrl);
            setUrl(url as string);
        } catch (error) {}
    };

    useEffect(() => {
        getVideoUrl();
    }, []);

    const handleLikeVideo = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (!user?._id) {
            history.push({
                pathname: '/login',
                state: {
                    redirect: location.pathname,
                },
            });
        } else {
            const liked = video.liked.includes(user?._id)
                ? video.liked.filter((i) => i !== user?._id)
                : [...video.liked, user?._id];
            updateVideo({ id: video._id, liked });
            useUploadVideoRequest({
                variables: { id: video._id, input: { liked } },
            });
        }
    };

    const handleCopyVideoUrl = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        try {
            await navigator.clipboard.writeText(`${window.location.host}/video/${video._id}`);
            Toast.show({ text: 'Video URL copied to clipboard!' });
        } catch (error) {
            Toast.show({ text: 'Failed to copied!' });
        }
    };

    return (
        <TopLikedCard ref={ref}>
            <div
                css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: calc(100% + 1px);
                    z-index: 10;
                    border-radius: 4px;
                    ${!isPlayingVideo
                        ? 'background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));'
                        : ''}
                `}
                onClick={() => setIsPlayingVideo(!isPlayingVideo)}
            >
                {!isPlayingVideo ? (
                    <IonText color="light">
                        <Top>#{ranked}</Top>
                        <Center>
                            <h4>{video.userId.name}</h4>
                        </Center>
                        <Bottom>
                            <Div onClick={(e) => handleLikeVideo(e)}>
                                <IonIcon
                                    icon={heart}
                                    color={user?._id && video.liked.includes(user?._id) ? 'danger' : 'light'}
                                />
                                <p>{video.liked.length}</p>
                            </Div>
                            <Div
                                css={css`
                                    margin-left: 1.5rem;
                                `}
                                onClick={handleCopyVideoUrl}
                            >
                                <img css={css(`width: 16px; height: 16px; color: white`)} src={shared} />
                            </Div>
                        </Bottom>
                    </IonText>
                ) : null}
            </div>
            <ReactPlayer
                css={css`
                    width: 100% !important;
                    height: calc((100vw - 2rem) / 1920 * 1080) !important;
                    display: flex;
                    align-items: center;
                `}
                url={url}
                playing={isVisible ? isPlayingVideo : false}
                controls={false}
                playsinline
                onPause={() => setIsPlayingVideo(false)}
            />
        </TopLikedCard>
    );
};

export default TopLiked;

const TopLikedCard = styled.div`
    height: calc((100vw - 2rem) / 1920 * 1080);
    position: relative;
    border-radius: 4px;
    background-color: var(--ion-layout-dark);

    video {
        border-radius: 4px;
    }
`;

const Top = styled.div`
    position: absolute;
    top: 8%;
    left: 8%;
    width: 40px;
    height: 40px;
    border: solid 1px white;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: 'Montserrat-Bold';
    font-size: 20px;
`;
const Center = styled.div`
    position: absolute;
    top: 45%;
    left: 8%;
    transform: translate(-0%, -50%);

    h4 {
        margin: 0px;
        font-family: 'Montserrat-Bold';
    }

    small {
        font-size: 12px;
    }
`;
const Bottom = styled.div`
    display: flex;
    position: absolute;
    bottom: 8%;
    left: 8%;
`;

const Div = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    p {
        font-family: 'Montserrat-Bold';
        font-size: 11px;
        margin: 0 4px;
    }
    margin-right: 0.5rem;
`;
