import styled from '@emotion/styled';
import { IonDatetime, IonIcon } from '@ionic/react';
import dayjs from 'dayjs';
import { chevronDown } from 'ionicons/icons';
import React, { useEffect } from 'react';

import { useMember } from '../../features/Member';
import { useCalendar } from '../../hooks/useCalendar';

export const SelectMonth = () => {
    const { date, change } = useCalendar();
    const {
        profile: { progress },
    } = useMember();
    useEffect(() => {
        change(
            progress.length
                ? dayjs(progress[progress.length - 1].year + '-' + (progress[progress.length - 1].month + 1))
                : date,
        );
    }, []);
    return (
        <Container>
            <IonDatetime
                displayFormat="MMM YYYY"
                min="2021"
                value={date.toISOString()}
                onIonChange={(e) => e.detail.value && change(dayjs(e.detail.value))}
            />

            <IonIcon icon={chevronDown} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--ion-white-text-button);
    font-weight: 500;
    text-transform: capitalize;
`;
